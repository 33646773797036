import React from "react";
import FormatDate from "../../Blog/FormatDate";

const BlogBannerSections = (props) => {
  const { item, openBlogDetail1 } = props;

  const metaTag = item.meta_tag.split(",");
  const metaTagColor = item.color_names.split(",");
  const blogPath = process.env.REACT_APP_BLOG_PATH;

  const encryptFunction4 = (blogUniqueUrl, metaDescription, metaTag) => {
    openBlogDetail1(blogUniqueUrl, metaDescription, metaTag);
  };

  return (
    <>
      <section className="BlogCardSection">
        <div className="container">
          <div
            className="row"
            style={{ cursor: "pointer" }}
            onClick={() =>
              encryptFunction4(
                item.blog_unique_url,
                item.meta_description,
                item.meta_tag
              )
            }
          >
            <div className="col-lg-6 pe-0 blogBanner">
              <div
                className="blog-img-2"
                style={{ backgroundImage: "url(" + item.blog_image + ")" }}
              >
                <div className="meta-date text-center p-2">
                  <FormatDate date={item.post_date} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 blog-title-head">
              <p
                className="ApplyNow"
                title="Blog Category"
                style={{
                  marginTop: "26px",
                  width: "fit-content",
                  padding: "5px 10px",
                  cursor: "auto",
                }}
              >
                {item.blog_type}
              </p>
              <h1>{item.title}</h1>
              <p className="banner-para">{item.meta_description} </p>
              <div className="col-lg-12  mb-2">
                <div
                  style={{
                    overflow: "hidden",
                    width: "99%",
                    display: "-webkit-box",
                  }}
                >
                  {metaTag.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="skillDiv me-2"
                        style={{ background: `${metaTagColor[i]}` }}
                      >
                        <p className="skillName">{item}</p>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="row blog-user-profile">
                <div className="col-lg-10 ">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="userImgcol">
                      {
                        <img
                          src={`${blogPath + item.user_image}`}
                          alt={item.author}
                          className={`userProfile-detail ${
                            item.user_image ? "" : "d-none"
                          }`}
                        />
                      }
                      {
                        <h3
                          className={`userProfile ${
                            item.author_initials ? "" : "d-none"
                          }`}
                        >
                          {item.author_initials}
                        </h3>
                      }
                    </div>
                    <p style={{ marginBottom: "0px" }}>{item.author}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogBannerSections;
