import React from 'react';
import styled from 'styled-components';

const StyledHeading = styled.h1`
color: #000;
font-size: 28px;
font-family: Mulish;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top : 20px ;

@media (max-width: 768px) {
    text-align:center;
  }
`;
export const MainHeading = (props) => {
    return (
        <>
            <StyledHeading>{props.name}</StyledHeading>

        </>
    )
}
