import React from 'react'

function JobDescription(props) {
  const { item } = props;
  return (
    <div>
      <h3 className="job-skills-2">Job Description</h3>
      <div dangerouslySetInnerHTML={{ __html: item.job_desc }}></div>
      {/* <p className="job-description"> {item.job_desc.replace(/(<([^>]+)>)/ig, '').replace(/\&nbsp;/g, '')}</p> */}
    </div>
  )
}

export default JobDescription
