import React from 'react'
import Carousel from "react-multi-carousel";
import { styled, keyframes } from 'styled-components';
import "react-multi-carousel/lib/styles.css";
import Bee from "../../../images/image-removebg-preview (33) 1.png";
import TechMahindra from "../../../images/techMahindra.png";
import bhumi from "../../../images/bhumi.png";
import NeoSoft from "../../../images/NeoSoft.png";
import Lti from "../../../images/LTI.png";
import Mastek from "../../../images/Mastek.png";


const silde_image_1 = keyframes`
from {
   transform: translateX(0%);
}
to {
   transform: translateX(-116%);
}
`;

const CarouselDiv = styled.div`
overflow:hidden;
& .imgAnimationCarousel{
display: flex;
flex-wrap: nowrap;
animation: ${silde_image_1}  12s linear infinite;
}
 & img {
    object-fit: contain;
    margin-right:50px;
 }
 `

const MainSection = styled.section`
  margin : 87px 0px 0px 0px;
  @media (max-width: 768px) {
    margin : 40px 0px 0px 0px;
  }
`
const StyledCarousel = styled(Carousel)`
  .react-multiple-carousel__arrow {
    display: none;
  }
  & .react-multi-carousel-item{
    margin: 0px 22px ;
    width: 230px !important;
    }

  .react-multi-carousel-item--active  {
  width : 230px !important;
  margin: 0px 0px ;
  }
`;

function CarouselSection() {
    const images = [TechMahindra, bhumi, Mastek, Bee, Lti, NeoSoft];

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
        },
    };
    return (
        <>
            <MainSection id="CompanyCarousel">
                <div className="container">
                    <div className="row">

                        <CarouselDiv className="col-lg-12">
                            <div className="imgAnimationCarousel" >
                                <img src={TechMahindra} alt={`TechMahindra`} />
                                <img src={bhumi} alt={`Bhumi`} />
                                <img src={Mastek} alt={`Mastek`} />
                                <img src={Bee} alt={`Beetel`} />
                                <img src={Lti} alt={`LTIMindtree`} />
                                <img src={NeoSoft} alt={`NeoSoft`} />
                                <img src={TechMahindra} alt={`TechMahindra`} />
                                <img src={bhumi} alt={`Bhumi`} />
                                <img src={Mastek} alt={`Mastek`} />
                                <img src={Bee} alt={`Beetel`} />
                                <img src={Lti} alt={`LTIMindtree`} />
                                <img src={NeoSoft} alt={`NeoSoft`} />
                            </div>
                            {/* <StyledCarousel
                                autoPlay={true}
                                autoPlaySpeed={2000}
                                infinite={true}
                                responsive={responsive}
                            >
                                {images.map((image, index) => (
                                    <div key={index}>
                                        <img
                                            src={image}
                                            alt={`Slide ${index + 1}`}
                                        // style={{ width: image === Lti ? '260px' : 'auto' }}
                                        />
                                    </div>
                                ))}
                            </StyledCarousel> */}
                        </CarouselDiv>
                    </div>
                </div>
            </MainSection>
        </>
    );
};

export default CarouselSection;
