import React, { useContext } from 'react'

import calender from '../../images/CalendarBlank.png';
// import companyLogo from '../../images/company-logo-img-dummy.png';
import mapPinline from '../../images/MapPinLine.png';
import clock from '../../images/Clock.png';
// import savejob from '../../images/save-job-1.png';

const ProflieMoblie = (props) => {
    const { item, applyOnJobAllValues } = props;
    //const clientLogo = process.env.REACT_APP_CLIENT_LOGO;

    const functionJobTitle = (jobTitle, jmId, jobQuesPresent, item) => {
        applyOnJobAllValues(jmId, jobTitle, jobQuesPresent,item)
    }

    return (
        <>

            <div className="col-lg-8 col-sm-12 col-md-8  hidein_laptop ">
                <div className="d-flex">
                    <div className='logoWidth'>
                        {/* <img src={(clientLogo)+(item.client_logo)} className='logoImg' /> */}
                        {item.client_logo !== null && (<img 
                        //src={(clientLogo) + (item.client_logo)} 
                        src={(item.client_logo)} 
                        className="logoImg" />)}
                        {item.company_initials !== null && (<div className="companyInitials">{item.company_initials}</div>)}
                    </div>
                    <div className="job__profileDiv">
                        <div className="d-flex align-items-baseline mt-1 mb-2">
                            <h3 className="job-profile">{item.job_title}<span className="ms-2 job-id">({item.job_id})</span></h3>
                        </div>
                        <p className="job-title mb-2">
                            {item.client}
                            {/* RARR Technologies in <span className="span-text-1">Development
                                IT</span> */}
                        </p>

                    </div>
                </div>
            </div>
            <div className='col-sm-12 hidein_laptop'>
                <div className="d-flex align-items-baseline  gapbtwn" >
                    <div className="d-flex align-items-baseline  after-css">
                        <div className="position-relative pe-2" style={{ bottom: '2px' }}>
                            <img src={calender} />
                        </div>
                        <p className="job-desc-1 mb-1">{item.job_exp}</p>
                    </div>
                    <div className="d-flex align-items-baseline after-css">
                        <div className="position-relative pe-2" style={{ bottom: '2px' }}>
                            <img src={clock} />
                        </div>
                        <p className="job-desc-1 mb-1">{item.employment_type}</p>
                    </div>
                    <div className="d-flex align-items-baseline" >
                        <div>
                            <p className="job-desc-1 mb-1">
                                Posted  {item.job_createddt}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-baseline ">
                    <div className="position-relative pe-2" style={{ bottom: '2px' }}>
                        <img src={mapPinline} />
                    </div>
                    <p className="job-desc-1 " title={item.job_loc}>{item.job_loc}</p>
                </div>

                {/* <div className='mb-3'>
                    <p style={{ fontSize: '12px' }}>
                        Application ends : <span className="red-color-text"> {item.job_endt_dt}</span>
                    </p>
                </div> */}
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4 hidein_laptop mb-4" >
                <div className='d-flex '>
                    <div className='textAlign'>
                        <button className="btn btn-blue-2" data-bs-toggle="modal" data-bs-target="#userJobDetailModal" onClick={() => functionJobTitle(item.job_title, item.jm_id, item.job_ques_present, item)}>Apply Now</button>
                    </div>
                    {/* <div className="ms-4 textAlign" >
                        <a id="RecommendText" href="#recommend"> <button className="btn btn-blue-outline">Recommend to Friend </button></a>
                    </div> */}
                </div>
            </div>

        </>
    )
}

export default ProflieMoblie

