import React, { useEffect, useState } from 'react';
import CachedIcon from "@mui/icons-material/Cached";
import CheckIcon from "@mui/icons-material/Check";

const Captcha = (props) => {

    const { length = 6, handleSuccess, handleFailure, checkIconOpacity, refreshCaptcha } = props;

    const [userCaptcha, setUserCaptcha] = useState('');
    const [captchaValue, setCaptchaValue] = useState('');

    const generateCaptcha = (n) => {
        // Characters to be included
        const chrs = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let captcha = "";
        for (let i = 0; i < n; i++) {
            captcha += chrs[(Math.floor(Math.random() * chrs.length))];
        }
        setCaptchaValue(captcha);
        handleFailure();
        setUserCaptcha('');
    }

    const onChange = (value) => {
        setUserCaptcha(value)
        if (value === captchaValue) {
            handleSuccess();
        } else {
            handleFailure();
        }
    }

    useEffect(() => {
        generateCaptcha(length);
    }, [refreshCaptcha])


    return (
        <>
            <div className='d-flex'>
                <div className='inputStyledCaptchaBorder me-3'>
                    <input maxLength={length} className='inputStyledCaptcha' type="text" placeholder="Enter Captcha" value={userCaptcha} onChange={(e) => { const value = (e.target.value); onChange(value); }} />
                    <CheckIcon className='me-3' style={{ opacity: `${checkIconOpacity}` }} />
                </div>
                <div className='captchaTextClass' >
                    {captchaValue}
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <CachedIcon  style={{ cursor: 'pointer' }} onClick={() => generateCaptcha(length)} />
                </div>
            </div>
        </>
    )
}

export default Captcha