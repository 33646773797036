import { React , useEffect } from 'react'
import Achievment from "./mainSection/Achievment";
import BridgeSection from "./mainSection/BridgeSection";
import Carousel from "./mainSection/Carousel";
import Contact from "./mainSection/ContactSection";
import Recognition from "./mainSection/Recognition";
import Testimonial from './mainSection/Testimonial';
import BlogMain from './mainSection/Blog/BlogMain';
import HeroSection from './mainSection/HeroSection'

const Home = () => {
    useEffect(() => {
        document.getElementsByTagName("META")[3].content="Application Development A full-cycle application development service confines planning, design, development &amp; testing. Corporate Solutions Delivering groundbreaking web &amp; mobile app solutions to enhance your business to next level. Industries We offer a gamut of technical solutions to all size business from a startup to big size industries. Reliable Apps Since Delivering innovation with our";
      }, []);
    return (
        <>
           <HeroSection/>
           <Carousel/>
           <BridgeSection />
           <Contact/>
           <Achievment/>
           <Recognition/>
            <Testimonial/>
            <BlogMain />
        </>
    )
}

export default Home