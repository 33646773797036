import { React , useEffect } from 'react'
import styled from 'styled-components';
import { MainBanner } from './components/MainBanner';
import { MainHeading } from './components/MainHeading';
import { CoconutServices } from './components/CoconutServices';
import { CoconutContent } from './components/CoconutContent';
import { ContactSection } from './components/ContactSection';
import MainTabSection from '../UI/UXdesign/MainTabSection';
const Container = styled.div``
const Row = styled.div``
const LeftDiv  = styled.div`
order: 2;
@media (min-width: 991px) {
  order: 1;
}`
const RightDiv = styled.div`
  order: 1;

  @media (min-width: 991px) {
    order: 2;
  }
`;
export const Coconuterp = () => {
  useEffect(() => {
    document.getElementsByTagName("META")[3].content="Coconut ERP Affordable Cloud-Based ERP solution available to customers on subscription (per user) or perpetual license model. Multi-Tenancy - Multiple Companies hosted in the same environment. Role-Based Access. Complete Security Ease of Access to Service. Pin Board. Notification Engine. Email / SMS. E-Invoice / E-Way Bill. ERP Modules You are managing a business in modern";
  }, []);
  return (
    <Container className="mb-3">
      <MainBanner name="Coconut ERP" pageName="CoconutERP" />
      <Container className="container">
      <Row className="row">
        <LeftDiv className="col-lg-3">
        <div className='fixedSection'>
            <MainTabSection/>
            <ContactSection/>
        </div>
        </LeftDiv>

        <RightDiv className="col-lg-9">
          <MainHeading name="Coconut ERP"/>
          <CoconutServices/>
          <MainHeading name="ERP Modules"/>
          <CoconutContent/>
        </RightDiv>
      </Row>
      </Container>
    </Container>
  )
}
