import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";

// import all css files
import "./style/Blog.css";
import "./style/Common.css";
import "./style/jobboard.css";
import "./style/JobDetail.css";
import "./style/Captcha.css";
import "./style/multiInput.css";
import "./style/MultiInputDropDownTag.css";
import "./style/SingleInputDropDownTagOther.css";

// import all components
import Header from "./components/header/Header";
import { Coconuterp } from "./components/Subpages/Coconuterp";
import Footer from "./components/footer/Footer";
import Home from "./components/Home/Home";
import Contact from "./components/Contact/contactus/Contact";
import CreatContext from "./context/CreatContext";
import BlogDetailMain from "./components/Home/mainSection/Blog/blogmain/BlogDetailMain";
import BannerDetail from "./components/Home/mainSection/Blog/detailwithBanner/BannerDetails";
import AppDevelopment from "./components/appDevelopment/AppDevelopment";
import UImain from "./components/UI/UXdesign/UImain";
import Recruitment from "./components/recruitment/Recruitment";
import JobBoard from "./components/jobBoard/JobBoard";
import JobDetail from "./components/jobDetail/JobDetail";
import DataBaseDevelopment from "./components/dbDevelopment/DataBaseDevelopment";
import { Atsmantra } from "./components/Subpages/atsmantra/components/AtsMantra";
import Microsoft from "./components/microsoft/Microsoft";

function App() {
  const context = useContext(CreatContext);
  const {
    authTokenApi,
    authToken,
    getLocationDetails,
    getJobDetail,
    jobDetailPathName,
    callJobDetail,
    getBlogPageCard,
    callByUrlBlog,
    jmIdInfo,
    postUrl,
    getNoticePeriodData,
    blogpagedata,
    getSkillsDropDown,
  } = context;

  ReactGA.initialize("G-K365R3KGZN", {
    titleCase: false,
  });

  useEffect(() => {
    if (authToken.length > 0) {
      getNoticePeriodData();
      getLocationDetails("");
      getJobDetail("All time", "", "", 0, 0, "");
      getBlogPageCard();
      callByUrlBlog();
      callJobDetail();
      getSkillsDropDown();
    }
  }, [authToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
    authTokenApi();
  }, []);

  return (
    <>
      <BrowserRouter>
        {window.location.pathname === "/" ||
        window.location.pathname === "/database-design-and-development/" ||
        window.location.pathname ===
          "/services/database-design-and-development/" ||
        window.location.pathname === "/coconut-erp/" ||
        window.location.pathname === "/atsmantra/" ||
        window.location.pathname === "/application-development/" ||
        window.location.pathname === "/recruitment-services/" ||
        window.location.pathname === "/current-job-opening" ||
        window.location.pathname === "/job-detail" ||
        window.location.pathname === "/jobDetail" ||
        window.location.pathname === "/jobDetail" + jobDetailPathName ||
        window.location.pathname === "/job-detail" + jobDetailPathName ||
        window.location.pathname === "/contact" ||
        window.location.pathname === "/contact/" ||
        // window.location.pathname === "/blogDetailMain" ||
        window.location.pathname === postUrl ||
        // window.location.pathname === "/bannerDetail" ||
        window.location.pathname === "/ui-ux-designing/" ||
        window.location.pathname === "/blog/" ||
        window.location.pathname === "/services/microsoft-solutions/" ||
        window.location.pathname === "/microsoft-solutions/" ||
        window.location.pathname === "/coconutERP" ||
        window.location.pathname === "/services/web-development/" ||
        window.location.pathname === "/services/application-development/" ||
        window.location.pathname ===
          "/services/mobile-application-development/" ||
        window.location.pathname ===
          "/services/website-design-and-development/" ? (
          <Header />
        ) : null}

        <Routes>
          <Route
            exact
            path="/services/database-design-and-development/"
            element={<DataBaseDevelopment />}
          />
          <Route
            exact
            path="/database-design-and-development/"
            element={<DataBaseDevelopment />}
          />
          <Route exact path="/coconut-erp/" element={<Coconuterp />} />
          <Route exact path="/atsmantra/" element={<Atsmantra />} />
          <Route
            exact
            path="/application-development/"
            element={<AppDevelopment />}
          />
          <Route
            exact
            path="/services/website-design-and-development/"
            element={<AppDevelopment />}
          />
          <Route
            exact
            path="/services/mobile-application-development/"
            element={<AppDevelopment />}
          />
          <Route
            exact
            path="/services/web-development/"
            element={<AppDevelopment />}
          />
          <Route
            exact
            path="/services/application-development/"
            element={<AppDevelopment />}
          />
          <Route
            exact
            path="/recruitment-services/"
            element={<Recruitment />}
          />
          <Route exact path="/current-job-opening" element={<JobBoard />} />
          <Route
            exact
            path={`/job-detail${jobDetailPathName}`}
            element={<JobDetail />}
          />
          <Route exact path={`/job-detail`} element={<JobDetail />} />
          <Route exact path="/jobDetail" element={<JobDetail />} />
          <Route
            exact
            path={`/jobDetail${jobDetailPathName}`}
            element={<JobDetail />}
          />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route
            exact
            path="/services/microsoft-solutions/"
            element={<Microsoft />}
          />
          <Route exact path="/microsoft-solutions/" element={<Microsoft />} />
          {/* <Route exact path={`/blogDetailMain`} element={<BlogDetailMain />} /> */}
          <Route exact path={`${postUrl}`} element={<BlogDetailMain />} />
          <Route exact path={`/blog/`} element={<BannerDetail />} />
          {/* <Route exact path={`/bannerDetail`} element={<BannerDetail />} /> */}
          <Route exact path={`/ui-ux-designing/`} element={<UImain />} />
        </Routes>
        {window.location.pathname === "/" ||
        window.location.pathname === "/database-design-and-development/" ||
        window.location.pathname ===
          "/services/database-design-and-development/" ||
        window.location.pathname === "/coconut-erp/" ||
        window.location.pathname === "/atsmantra/" ||
        window.location.pathname === "/application-development/" ||
        window.location.pathname === "/recruitment-services/" ||
        window.location.pathname === "/current-job-opening" ||
        window.location.pathname === "/contact" ||
        window.location.pathname === "/contact/" ||
        window.location.pathname === "/microsoft-solutions/" ||
        // window.location.pathname === "/blogDetailMain" ||
        //window.location.pathname === postUrl ||
        // window.location.pathname === "/bannerDetail" ||
        window.location.pathname === "/ui-ux-designing/" ||
        window.location.pathname === "/blog/" ||
        window.location.pathname === "/services/microsoft-solutions/" ||
        window.location.pathname === "/coconut-erp/" ||
        window.location.pathname === "/services/web-development/" ||
        window.location.pathname === "/services/application-development/" ||
        window.location.pathname ===
          "/services/mobile-application-development/" ||
        window.location.pathname ===
          "/services/website-design-and-development/" ? (
          <Footer />
        ) : null}
        {window.location.pathname === "/job-detail" + jobDetailPathName
          ? jmIdInfo.length > 0 && <Footer />
          : null}
        {window.location.pathname === "/jobDetail" + jobDetailPathName
          ? jmIdInfo.length > 0 && <Footer />
          : null}
        {window.location.pathname === "/job-detail"
          ? jmIdInfo.length > 0 && <Footer />
          : null}
        {window.location.pathname === postUrl
          ? blogpagedata.length > 0 && <Footer />
          : null}
        {window.location.pathname === "/jobDetail"
          ? jmIdInfo.length > 0 && <Footer />
          : null}
      </BrowserRouter>
    </>
  );
}

export default App;
