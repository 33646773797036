import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import styled from "styled-components";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
const OutSideStyle = styled.div`
  // display:flex;
  // margin-top: 20px;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  // padding: 5px 8px 5px 8px !important;
  // border-radius:8px 0px 0px 8px;

  @media (max-width: 991px) {
    display: flex;
    justify-content: inherit;
    margin-top: 0px;
    // justify-content: space-between;
  }
`;

const FacebookStyle = styled(FacebookShareButton)`
  cursor: pointer;
  background: #1177f0 !important;
  padding: 5px 8px 5px 8px !important;
  // border-radius:8px 0px 0px 8px;
  display: flex;
  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }

  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;

    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }
  & .mobileViewStyledShare {
    color: #fff;
    margin-left: 5px;
    font-size: 14px;
  }

  @media (max-width: 991px) {
    color: #1177f0 !important;
    background: transparent !important;
    padding: 0px 10px !important;
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
      color: #1177f0;
    }
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
      color: #1177f0;
    }
  }
`;
const TwitterStyle = styled(TwitterShareButton)`
  cursor: pointer;
  background: #2c67b3 !important;
  padding: 5px 8px 5px 8px !important;
  // border-radius:8px 0px 0px 8px;
  display: flex;
  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }
  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }
  & .mobileViewStyledShare {
    color: #fff;
    margin-left: 5px;
    font-size: 14px;
  }

  @media (max-width: 991px) {
    color: #2c67b3 !important;
    background: transparent !important;
    padding: 0px 10px !important;
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
      color: #2c67b3;
    }
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
      color: #2c67b3;
    }
  }
`;
const LinkedinStyle = styled(LinkedinShareButton)`
  cursor: pointer;
  background: #229af0 !important;
  padding: 5px 8px 5px 8px !important;
  // border-radius:8px 0px 0px 8px;
  display: flex;
  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }
  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }
  & .mobileViewStyledShare {
    color: #fff;
    margin-left: 5px;
    font-size: 14px;
  }

  @media (max-width: 991px) {
    color: #229af0 !important;
    background: transparent !important;
    padding: 0px 10px !important;
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
      color: #229af0;
    }
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
      color: #229af0;
    }
  }
`;
const WhatsappStyle = styled.div`
  cursor: pointer;
  background: #29cf47 !important;
  padding: 5px 8px 5px 8px !important;
  // border-radius:8px 0px 0px 8px;
  display: flex;
  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }
  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }

  & .mobileViewStyledShare {
    color: #fff;
    margin-left: 5px;
    font-size: 14px;
  }

  @media (max-width: 991px) {
    padding: 0px 10px 0px 0px !important;
    color: #29cf47 !important;
    background: transparent !important;
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
      color: #29cf47;
    }
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
      color: #29cf47;
    }
  }
`;
const CopyStyle = styled.div`
  cursor: pointer;
  background: #0052cc !important;
  padding: 5px 8px 5px 8px !important;
  // border-radius:8px 0px 0px 8px;
  display: flex;
  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }
  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }

  & .mobileViewStyledShare {
    color: #fff;
    margin-left: 5px;
    font-size: 14px;
  }

  @media (max-width: 991px) {
    padding: 0px 10px 0px 0px !important;
    color: #0052cc !important;
    background: transparent !important;
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
      color: #0052cc;
    }
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
      color: #0052cc;
    }
  }
`;
const TelegramStyle = styled.div`
  cursor: pointer;
  background: steelblue !important;
  padding: 5px 8px 5px 8px !important;
  // border-radius:8px 0px 0px 8px;
  display: flex;
  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }
  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    margin-left: 0px;
    font-size: 30px;
    color: #fff;
  }
  & .mobileViewStyledShare {
    color: #fff;
    margin-left: 5px;
    font-size: 14px;
  }

  @media (max-width: 991px) {
    color: steelblue !important;
    background: transparent !important;
    padding: 0px 10px !important;
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
      color: steelblue;
    }
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
      color: steelblue;
    }
  }
`;

const SocialShare = ({ URIMessage, URIMessageTele, url, title, message }) => {
  const shareMessage = ``;
  const [openWhatsApp, setOpenWhatsApp] = useState(false);
  const [openLinkedIn, setOpenLinkedIn] = useState(false);
  const [openFacebook, setOpenFacebook] = useState(false);
  const [openTwitter, setopenTwitter] = useState(false);
  const [openTelegram, setOpenTelegram] = useState(false);
  const [copyLink, setCopyLink] = useState("Copy Job Details");
  const [openCopy, setOpenCopy] = useState(false);

  const handleTooltipWhatsAppClose = () => {
    setOpenWhatsApp(false);
  };
  const handleTooltipClose = () => {
    setOpenCopy(false);
    setCopyLink("Copy Job Details");
  };
  const copiedLink = () => {
    setCopyLink("Copied");
  };
  const handleTooltipOpen = () => {
    setOpenCopy(true);
  };
  const handleTooltipWhatsAppOpen = () => {
    setOpenWhatsApp(true);
  };

  const handleTooltipLinkedInClose = () => {
    setOpenLinkedIn(false);
  };

  const handleTooltipLinkedInOpen = () => {
    setOpenLinkedIn(true);
  };

  const handleTooltipFacebookClose = () => {
    setOpenFacebook(false);
  };

  const handleTooltipFacebookOpen = () => {
    setOpenFacebook(true);
  };

  const handleTooltipTwitterClose = () => {
    setopenTwitter(false);
  };

  const handleTooltipTwitterOpen = () => {
    setopenTwitter(true);
  };

  const handleTooltipTelegramClose = () => {
    setOpenTelegram(false);
  };

  const handleTooltipTelegramOpen = () => {
    setOpenTelegram(true);
  };

  const tooltipWhatsAppContent = (
    <span style={{ whiteSpace: "nowrap" }}>Share on WhatsApp</span>
  );
  // const copyLink = (
  //     <span style={{ whiteSpace: 'nowrap' }}>Copy Job Details</span>
  // );
  const tooltipLinkedInContent = (
    <span style={{ whiteSpace: "nowrap" }}>Share on LinkedIn</span>
  );

  const tooltipFacebookContent = (
    <span style={{ whiteSpace: "nowrap" }}>Share on Facebook</span>
  );

  const tooltipTwitterContent = (
    <span style={{ whiteSpace: "nowrap" }}>Share on Twitter</span>
  );

  const tooltipTelegramContent = (
    <span style={{ whiteSpace: "nowrap" }}>Share on Telegram</span>
  );

  const openWhatsAppApiCall = () => {
    window.open(`https://api.whatsapp.com/send?text=${URIMessage}`);
  };

  const openTelegramApiCall = () => {
    window.open(`https://t.me/share/url?url=${URIMessageTele}`);
  };

  return (
    <OutSideStyle>
      <CopyStyle>
        <CopyToClipboard
          text={message}
          onMouseLeave={handleTooltipClose}
          onMouseEnter={handleTooltipOpen}
        >
          <span>
            <Tooltip
              arrow
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={openCopy}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={`${copyLink}`}
              placement="left"
            >
              <span onClick={copiedLink}>
                <ContentCopyIcon />
              </span>
            </Tooltip>
          </span>
        </CopyToClipboard>
      </CopyStyle>
      <WhatsappStyle
        onClick={openWhatsAppApiCall}
        onMouseEnter={handleTooltipWhatsAppOpen}
        onMouseLeave={handleTooltipWhatsAppClose}
      >
        <Tooltip
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipWhatsAppClose}
          open={openWhatsApp}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={tooltipWhatsAppContent}
          placement="left"
        >
          <WhatsAppIcon />
          {/* <div className='mobileViewStyledShare'>
                    Share on WhatsApp
                </div> */}
        </Tooltip>
      </WhatsappStyle>
      <LinkedinStyle
        url={url}
        title={title}
        onMouseEnter={handleTooltipLinkedInOpen}
        onMouseLeave={handleTooltipLinkedInClose}
      >
        <Tooltip
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipLinkedInClose}
          open={openLinkedIn}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={tooltipLinkedInContent}
          placement="left"
        >
          <LinkedInIcon />
          {/* <div className='mobileViewStyledShare'>
                    Share on LinkedIn
                </div> */}
        </Tooltip>
      </LinkedinStyle>
      <FacebookStyle
        url={url}
        quote={title}
        onMouseEnter={handleTooltipFacebookOpen}
        onMouseLeave={handleTooltipFacebookClose}
      >
        <Tooltip
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipFacebookClose}
          open={openFacebook}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={tooltipFacebookContent}
          placement="left"
        >
          <FacebookOutlinedIcon />
          {/* <div className='mobileViewStyledShare'>
                    Share on Facebook
                </div> */}
        </Tooltip>
      </FacebookStyle>
      {/* <TelegramStyle onClick={openTelegramApiCall} onMouseEnter={handleTooltipTelegramOpen} onMouseLeave={handleTooltipTelegramClose}>
                <Tooltip arrow
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipTelegramClose}
                    open={openTelegram}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={tooltipTelegramContent}
                    placement="left"
                >
                    <TelegramIcon />
                    {/* <div className='mobileViewStyledShare'>
                    Share on Telegram
                </div> */}
      {/*</Tooltip>*/}
      {/* </TelegramStyle> */}
      <TwitterStyle
        url={url}
        title={title}
        onMouseEnter={handleTooltipTwitterOpen}
        onMouseLeave={handleTooltipTwitterClose}
      >
        <Tooltip
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipTwitterClose}
          open={openTwitter}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={tooltipTwitterContent}
          placement="left"
        >
          <TwitterIcon />
          {/* <div className='mobileViewStyledShare'>
                    Share on Twitter
                </div> */}
        </Tooltip>
      </TwitterStyle>
    </OutSideStyle>
  );
};

export default SocialShare;
