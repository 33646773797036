import { React, useEffect , useContext } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CreatContext from "../../../../context/CreatContext";
import MainTabSection from '../../../UI/UXdesign/MainTabSection';
import { MainBanner } from '../../components/MainBanner';
import { ContactSection } from '../../components/ContactSection';
import { MainHeading } from '../../components/MainHeading';

import atsMicrosoftTeamsimage1 from "../../../../images/MicrosoftTeams-image1.png";
import atsJobBoardimg from "../../../../images/MicrosoftTeam-JobBoard.png";
import atsAppMatchingimg from "../../../../images/MicrosoftTeams-Appmatching.png";
import atsJobmatchingimg from "../../../../images/MicrosoftTeams-Jobmatching.png";
import atsSharingjobimg from "../../../../images/MicrosoftTeams-Sharingjob-Des.png";
import Tick from "../../../../images/Group 14247.png";
import atsSocialMediaimg from "../../../../images/MicrosoftTeams-SocialMedia.png";
import atsAutomatedimg from "../../../../images/MicrosoftTeams-Automated.png";
import atsSelfapplyimg from "../../../../images/MicrosoftTeams-Selfapply.png";
import atsoutsourceimg from "../../../../images/MicrosoftTeams-outsource.png";
import atsMarketplaceimg from "../../../../images/MicrosoftTeams-Marketplace.png";
import atsAlertsimg from "../../../../images/MicrosoftTeams-Alerts.png";

const Container = styled.div``
const Row = styled.div``
const StyledText = styled.p`
color: #000;
    font-size: 16px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
`
const Maindiv = styled.div`
display : flex ;
`
const Column = styled.div`
margin-bottom : 13px ;
`
const Column1 = styled.div`
margin-top : 20px ;
&:last-child {
   margin-bottom : 30px ;
}
`
const ButtonColumn = styled.div`
margin : 20px 0px ;
text-align : center ;
`

const Heading = styled.h2`
font-size : 18px ;
color : black ;
font-weight : 700 ;
font-family : 'Mulish'
`
const Para = styled.h4`
font-size : 14px ;
margin-bottom : 16px ;
color : black ;
`
const Image = styled.img`
height: 64px;
width : 64px ;
margin-right : 15px ;
`
const Imagefirst = styled.img`
height: 64px;
width : 64px ;
margin-right : 15px ;
margin-top:-6px;
`
const ServicesHeading = styled.h4`
color: #000;
font-size: 24px;
font-family: Mulish;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top : 30px;
`
const LeftDiv = styled.div`
order: 2;
@media (min-width: 991px) {
  order: 1;
}`
const RightDiv = styled.div`
  order: 1;

  @media (min-width: 991px) {
    order: 2;
  }
`;
const ExploreMore = styled(Link)`
background:#00337C;
color:#fff;
font-size:18px;
border-radius:10px;
text-decoration : none ;
padding: 10px 60px;
border:1px solid #00337C;
`;
const Centerdiv = styled.div`
text-align:center;
margin:27px 0px 33px 0px;
`

const Tickimg = styled.img`
margin-right:15px;
`
const StyledFlex = styled.div`
display:flex;
align-items: flex-start;
`;

export const Atsmantra = () => {

    const context = useContext(CreatContext);
    const { activeLink, setActiveLink } = context;

    useEffect(() => {
        document.getElementsByTagName("META")[3].content = "atsMantra is a recruitment ATS (Applicant Tracking System). atsMantra helps you automates the recruitment/hiring process by managing job postings, resumes, and numerous recruitment-related tasks.";
    }, []);
    return (
        <Container className="mb-3">
            <MainBanner name="atsMantra" pageName="atsMantra" />
            <Container className="container">
                <Row className="row">
                    <LeftDiv className="col-lg-3">
                        <div className='fixedSection'>
                            <MainTabSection />
                            <ContactSection />
                        </div>
                    </LeftDiv>

                    <RightDiv className="col-lg-8">
                        <MainHeading name="What is atsMantra" />
                        <StyledText>atsMantra is a recruitment ATS (Applicant Tracking System). atsMantra helps you automates the recruitment/hiring process by managing job postings, resumes, and numerous recruitment-related tasks.</StyledText>
                        <MainHeading name="Features" />

                        <Column1 className="col-lg-12">
                            <Maindiv>
                                {<Imagefirst src={atsMicrosoftTeamsimage1}  alt="Resume"></Imagefirst>}

                                <div>
                                    <Heading>Centralized Resume Database</Heading>
                                    <Para>Allowing convenient reuse of resumes</Para>
                                </div>
                            </Maindiv>
                        </Column1>
                        <Column1 className="col-lg-12">
                            <Maindiv>
                                { <Image src={atsJobBoardimg}  alt="Job Board"></Image>}
                                <div>
                                    <Heading>Job Board </Heading>
                                    <Para>Dedicated, free Job Board for each onboarded company</Para>
                                </div>
                            </Maindiv>
                        </Column1>
                        <Column1 className="col-lg-12">
                            <Maindiv>
                                <Image src={atsAppMatchingimg} alt="applicant Matching"></Image>
                                <div>
                                    <Heading>Applicant Matching </Heading>
                                    <Para>Rapid Applicant Matching for new job openings</Para>
                                </div>
                            </Maindiv>
                        </Column1>
                        <Column1 className="col-lg-12">
                            <Maindiv>
                                <Image src={atsJobmatchingimg} alt="Job Matching"></Image>
                                <div>
                                    <Heading>Job Matching </Heading>
                                    <Para>Swift Job Matching for newly loaded applicants.</Para>
                                </div>
                            </Maindiv>
                        </Column1>
                        <Column1 className="col-lg-12">
                            <Maindiv>
                                 <Image src={atsSharingjobimg} alt=" Sharing Description"></Image>
                                <div>
                                    <Heading>Sharing of Job Descriptions </Heading>
                                    <Para>Allows for quick Sharing of Job Descriptions (JDs) with candidates</Para>
                                </div>
                            </Maindiv>
                        </Column1>
                        <Column1 className="col-lg-12">
                            <Maindiv>
                                 <Image src={atsSelfapplyimg}  alt="SelfApply"></Image>
                                <div>
                                    <Heading>Self-apply Page for Applicants</Heading>
                                    <Para>Offers a Self-apply Page for Applicants, reducing the time required by recruiters.</Para>
                                </div>
                            </Maindiv>
                        </Column1>
                        <Column1 className="col-lg-12">
                            <Maindiv>
                                 <Image src={atsSocialMediaimg} alt="socialMedia"></Image>
                                <div>
                                    <Heading>Social Media Postings</Heading>
                                    <Para>Enables real-time job posting on Social Media Platforms.</Para>
                                </div>
                            </Maindiv>
                        </Column1>
                        <Column1 className="col-lg-12">
                            <Maindiv>
                                 <Image src={atsAutomatedimg} alt="trackers"></Image>
                                <div>
                                    <Heading>Automated Trackers  </Heading>
                                    <Para>Automated Trackers for every client's Single Point of Contact (SPOC), job, and client, facilitating the Rapid Sharing of data with clients</Para>
                                </div>
                            </Maindiv>
                        </Column1>
                        <Column1 className="col-lg-12">
                            <Maindiv>
                                <Image src={atsoutsourceimg} alt="atsoutsourceimg"></Image>
                                <div>
                                    <Heading>Easily outsource your Jobs   </Heading>
                                    <Para>Allows you to Easily outsource your Jobs to vendors or other agencies with just a click.</Para>
                                </div>
                            </Maindiv>
                        </Column1>
                        <Column1 className="col-lg-12">
                            <Maindiv>
                                <Image src={atsMarketplaceimg} alt="atsMarketplaceimg"></Image>
                                <div>
                                    <Heading>atsMantra's Marketplace </Heading>
                                    <Para>Showcase your Candidate Pool to potential customers and social media platforms through the atsMantra's Marketplace</Para>
                                </div>
                            </Maindiv>
                        </Column1>
                        <Column1 className="col-lg-12">
                            <Maindiv>
                                <Image src={atsAlertsimg}  alt="atsAlertsimg"></Image>
                                <div>
                                    <Heading>Alerts and Reminders</Heading>
                                    <Para>Alerts and Reminders to ensure prompt updates and follow-ups.</Para>
                                </div>
                            </Maindiv>
                        </Column1>
                        <Centerdiv>
                        <ExploreMore target="_blank" to="https://atsmantra.com">Explore More on</ExploreMore>
                        </Centerdiv>

                        
                        <MainHeading name="Why Chose atsMantra" className="mb-3"/>
                       <StyledFlex>
                        <Tickimg src={Tick} alt="Tick"></Tickimg>
                        <Para>Stay ahead with our cutting-edge solutions, leveraging the latest technologies for greater efficiency, productivity, and success.</Para>
                       </StyledFlex>

                       <StyledFlex>
                        <Tickimg src={Tick}  alt="Tick"></Tickimg>
                        <Para>Exceptional value, competitive pricing. Maximize your investment with our quality services.</Para>
                       </StyledFlex>

                       <p>Interested to know more?  <Link to='/contact' onClick={() => {
                        setActiveLink("contact");
                    window.scrollTo(0, 0);
                  }}>Contact us here.</Link></p>


                    </RightDiv>
                </Row>
            </Container>
        </Container>
    )
}
