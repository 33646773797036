import React, { useRef, useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";

const MultiInputDropDownTag = (props) => {
  const {
    options,
    label,
    label_2,
    onTag,
    setOnTag,
    preSelectedTag = [],
    placeholder,
    onSearch,
    limit = 0,
    otherField,
    setOtherField,
    setOtherFieldValueShow,
    otherFieldValueShow,
    valueToShowOption,
    setValueToShowOption,
    otherOptionShowOrNot = true,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [isOpenData, setIsOpenData] = useState(false);
  // const [valueToShowOption, setValueToShowOption] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [searchedOptions, setSearchedOptions] = useState([]);

  const boxRef = useRef();
  const dropDownListRef = useRef();

  const removeTags = (e, indexToRemove) => {
    setOnTag([
      ...onTag.filter((item, index) => {
        return index !== indexToRemove;
      }),
    ]);
    setValueToShowOption([
      ...valueToShowOption.filter((item, index) => {
        return index !== indexToRemove;
      }),
    ]);
  };

  const removeOtherTags = (e, indexToRemove) => {
    setOtherField(false);
    setOtherFieldValueShow([]);
  };

  const addTags = (event, valueShow, valueStore) => {
    setSelectedIndex(null);
    if (limit === 0) {
      setOnTag((prevArray) => {
        const newArray = [
          ...prevArray.filter((item) => {
            return item.lmId !== valueStore;
          }),
        ];
        const index = newArray.length;
        newArray[index] = {
          lmId: valueStore,
          isActive: "Y",
        };
        return newArray;
      });
      setValueToShowOption([
        ...valueToShowOption.filter((item, index) => {
          return item !== valueShow;
        }),
        valueShow,
      ]);
      setSearchValue("");
    } else if (valueToShowOption.length < limit) {
      // console.log(onTag);
      setOnTag((prevArray) => {
        const newArray = [
          ...prevArray.filter((item) => {
            return item.lmId !== valueStore;
          }),
        ];
        const index = newArray.length;
        newArray[index] = {
          lmId: valueStore,
          isActive: "Y",
        };
        return newArray;
      });
      setValueToShowOption([
        ...valueToShowOption.filter((item, index) => {
          return item !== valueShow;
        }),
        valueShow,
      ]);
      setSearchValue("");
    } else if (valueToShowOption.length === limit) {
      Swal.fire({
        title: `Only ${limit} selection allowed.`,
        icon: "warning",
      });
      return false;
    }
  };

  const onSearchCahnge = (e) => {
    setSelectedIndex(null);
    setSearchValue(e.target.value);
    onSearch(e.target.value);
    scrollToTopList();
  };

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setIsOpenData(false);
      setSearchValue("");
      setSelectedIndex(null);
    }
  };

  const firstSpaceValidation = (event) => {
    const len = event.target.value.length;
    if (len === 0 && event.charCode === 32) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const scrollToTopList = () => {
    const dropDownList = document.querySelector(".dropDownList");
    if (dropDownList) {
      dropDownList.scrollTo(0, 0);
    }
  };

  const handleToFilter = async () => {
    if (searchValue === "") {
      const data = await options.filter((item) => {
        if (valueToShowOption.some((value) => value === item[label]))
          return false;
        else return true;
      });
      setSearchedOptions(data);
    } else {
      const data = await options.filter((item) => {
        const itemLabel = item[label]?.toString().toLowerCase() ?? "";
        const searchValueLower = searchValue?.toString().toLowerCase() ?? "";
        if (valueToShowOption.some((value) => value === item[label]))
          return false;
        return itemLabel.includes(searchValueLower);
      });
      setSearchedOptions(data);
    }
  };

  useEffect(() => {
    handleToFilter();
  }, [options, searchValue]);

  // Function to handle keyboard navigation
  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex === null ? 0 : Math.min(prevIndex + 1, searchedOptions.length)
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex === null ? 0 : Math.max(prevIndex - 1, 0)
      );
    } else if (event.key === "Enter" && selectedIndex !== null) {
      event.preventDefault();
      if (selectedIndex === searchedOptions.length) {
        setOtherFieldValueShow(["OTHER"]);
        setOtherField(true);
        setIsOpenData(false);
      } else {
        const selectedOption = searchedOptions[selectedIndex];
        addTags(event, selectedOption[label], selectedOption[label_2]);
      }
    }
  };
  useEffect(() => {
    if (isOpenData) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, isOpenData]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Scroll to selected index if it's not visible
    const dropDownList = dropDownListRef.current;
    const selectedItem =
      dropDownList && dropDownList.querySelector(".dropDownListItemSelected");
    if (selectedIndex !== null && selectedItem) {
      selectedItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [selectedIndex]);

  return (
    <>
      <div className="multiSelectInputTag" ref={boxRef}>
        <div className="multitags-inputOther">
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <ul
              id="multitags"
              onClick={() => {
                scrollToTopList();
              }}
            >
              {preSelectedTag.map((tag, index) => {
                return (
                  <li key={index} className="multitag">
                    <span
                      className="multitag-title"
                      title={tag}
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {tag}
                    </span>
                    <span onClick={(e) => removeTags(e, index)}>
                      <CancelIcon
                        style={{
                          color: "white",
                          fontSize: "16px",
                          marginRight: "0px",
                          marginLeft: "5px",
                        }}
                        className="multitag-close-icon"
                      />
                    </span>
                  </li>
                );
              })}

              {valueToShowOption.map((tag, index) => {
                return (
                  <li key={index} className="multitag">
                    <span
                      className="multitag-title"
                      title={tag}
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {tag}
                    </span>
                    <span onClick={(e) => removeTags(e, index)}>
                      <CancelIcon
                        style={{
                          color: "white",
                          fontSize: "16px",
                          marginRight: "0px",
                          marginLeft: "5px",
                        }}
                        className="multitag-close-icon"
                      />
                    </span>
                  </li>
                );
              })}

              {otherFieldValueShow.map((tag, index) => {
                return (
                  <li key={index} className="multitag">
                    <span className="multitag-title" title={tag}>
                      {tag}
                    </span>
                    <span onClick={(e) => removeOtherTags(e, index)}>
                      <CancelIcon
                        style={{
                          color: "white",
                          fontSize: "16px",
                          marginRight: "0px",
                          marginLeft: "5px",
                        }}
                        className="multitag-close-icon"
                      />
                    </span>
                  </li>
                );
              })}
            </ul>
            <input
              type="text"
              id="fltLocation"
              autoComplete="off"
              onKeyPress={firstSpaceValidation}
              value={searchValue}
              onChange={onSearchCahnge}
              placeholder={valueToShowOption.length === 0 ? placeholder : ""}
              className={`multiInput multiInputSelect ${
                otherField ? "d-none" : ""
              }`}
              onFocus={() => setIsOpenData(true)}
              disabled={otherField}
              onClick={() => {
                setSelectedIndex(null);
                setIsOpenData(true);
                scrollToTopList();
              }}
              style={{ padding: "8px 13px", borderRadius: "8px" }}
            />
          </div>
        </div>

        <div htmlFor="fltLocation">
          <div
            className={`dropDownList ${
              isOpenData === true ? "" : "displayNone"
            }`}
            ref={dropDownListRef}
          >
            {searchedOptions
              // .filter((option) => {
              //   if (searchValue === "") {
              //     return option;
              //   } else if (
              //     option[label]
              //       .toLowerCase()
              //       .includes(searchValue.toLowerCase())
              //   ) {
              //     return option;
              //   }
              // })
              // .filter((option, i) => {
              //   const value1 = option[label];
              //   let isTrue = false;
              //   valueToShowOption.map((item) => {
              //     if (value1 === item) {
              //       isTrue = true;
              //       return false;
              //     }
              //   });
              //   if (isTrue !== true) {
              //     return option;
              //   }
              // })
              .map((option, i) => {
                const valueShow = option[label];
                const valueStore = option[label_2];
                return (
                  <div
                    key={i}
                    onClick={(e) => addTags(e, valueShow, valueStore)}
                    // className="dropDownListItem"
                    onMouseMove={(e) => setSelectedIndex(i)}
                    className={`dropDownListItemSingleSelected ${
                      selectedIndex === i
                        ? "dropDownListItemSelected"
                        : "dropDownListItemNotSelected"
                    }`}
                  >
                    {valueShow}
                  </div>
                );
              })}
            {otherOptionShowOrNot && (
              <div
                onClick={(e) => {
                  setOtherFieldValueShow(["OTHER"]);
                  setOtherField(true);
                  setIsOpenData(false);
                }}
                // className="dropDownListItem"
                onMouseMove={(e) => setSelectedIndex(searchedOptions.length)}
                className={`dropDownListItemSingleSelected ${
                  selectedIndex === searchedOptions.length
                    ? "dropDownListItemSelected"
                    : "dropDownListItemNotSelected"
                }`}
              >
                OTHER
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiInputDropDownTag;
