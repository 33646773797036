import React, { useContext } from 'react'
import styled from 'styled-components';
import CreatContext from "../../../context/CreatContext";
import { MainHeading } from '../../Subpages/components/MainHeading';
import { Link } from 'react-router-dom';
import SkillSection from "./SkillSection";
// images_import
import KnowledgeImg from '../../../images/Knowledge.png';
import FlexibleImg from '../../../images/Flexible.png';
import ProactiveImg from '../../../images/Proactive.png';
import SwiftImg from '../../../images/Swift.png';
import skillimg from "../../../images/skillimg.png"

// css_components
const Container = styled.div``
const Stylepara = styled.p`
font-size:16px;
font-weight:400;
line-height:normal;
margin-bottom:40px;
`
const Img = styled.img`
margin-bottom : 11px ;
`
const Row = styled.div`
margin-top:27px;
margin-bottom:27px;
`

const Heading = styled.h2`
font-size : 18px ;
color : black ;
font-weight : 700 ;
font-family : 'Mulish'
`
const COl = styled.div``
const COlButton = styled.div`
text-align: center;
margin-bottom : 70px ;
`
const Startbttn = styled(Link)`
background:#00337C;
color:#fff;
font-size:18px;
border-radius:10px;
text-decoration : none ;
padding: 10px 60px;
border:1px solid #00337C;
`;




const ContentRecruitment = () => {
    const context = useContext(CreatContext);
    const { activeLink, setActiveLink } = context;
    return (
        <Container>
            <Stylepara>Looking to hire dedicated and experienced professionals to bring your business idea to life? We can help you assemble a talented team that matches your requirements. Our experts will work closely with you to convert your vision into reality. Contact us now to discuss your hiring needs.</Stylepara>

            <MainHeading name='Benefits of Hiring with us' />

            <Row className='row'>
                <COl className='col-lg-6'>
                    <Img src={KnowledgeImg} alt="Knowledge"></Img>
                    <Heading>Knowledge of Multiple Skillset</Heading>
                    <Stylepara>Embrace the Power of Diverse Talent. We specialize in connecting businesses with professionals who possess a wide range of skills and expertise. From various domains to specialized areas, we have the talent you need. Find the perfect team members to elevate your projects. </Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={FlexibleImg} alt="Flexible"></Img>
                    <Heading>Flexible Hiring</Heading>
                    <Stylepara>We specialize in connecting businesses with professionals who possess a wide range of skills and expertise. Whether you need short-term support or long-term engagements, we can find the right talent to meet your flexible hiring needs. Find the perfect team members to elevate your projects. Contact us now.</Stylepara>
                </COl>
                <COl className='col-lg-6' >
                    <Img src={SwiftImg} alt="Swift"></Img>
                    <Heading>Swift Turnaround Time</Heading>
                    <Stylepara>Accelerate Your Projects with Quick TAT. We understands the importance of time-sensitive projects. We prioritize fast and efficient hiring processes to ensure quick turnaround times. Benefit from our streamlined approach and connect with top talent swiftly. Experience rapid progress on your projects.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={ProactiveImg} alt="Proactive"></Img>
                    <Heading>Proactive Support</Heading>
                    <Stylepara>We go the extra mile to provide proactive support throughout the hiring process. From initial consultations to candidate onboarding, we are here to assist you at every step. Our dedicated team is responsive, accessible, and committed to ensuring a smooth and positive experience.</Stylepara>
                </COl>
            </Row>

            <Row>
                <COlButton className='col-lg-12'>
                    <Startbttn to="/contact" onClick={() => { setActiveLink("contact"); window.scrollTo(0, 0) }} >Start a Conversation</Startbttn>
                </COlButton>
            </Row>
            <SkillSection />
        </Container>
    )
}

export default ContentRecruitment