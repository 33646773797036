import React from 'react';
import styled from 'styled-components';
import CRM from "../../../images/image 50.png"
import Billing from "../../../images/image 51.png"
import payroll from "../../../../src/images/image 52.png";
import production from "../../../../src/images/image 54.png";
const Container = styled.div`

`;
const StyledHeading = styled.p`
color: #000;
font-size: 16px;
font-family: Mulish;
font-style: normal;
font-weight: 400;
line-height: normal;
`;
const StyledImage = styled.img`
width:100%;
`;
const StyledSubHeading = styled.h1`
color: #000;
font-size: 22px;
font-family: Mulish;
font-style: normal;
font-weight: 700;
margin-bottom : 16px ;
line-height: normal;
`;
const HighlightedText = styled.span`
font-weight: 600;
`;
export const CoconutContent = () => {
    return (
        <Container>
            <StyledHeading>
                You are managing a business in modern times and these times require you to stay updated with newer technologies. Managing your data on spreadsheets is time-consuming and the technology of older days.<br /><br />

                That's why we bring you the ERP software that promotes productivity and efficiency. Our experts take on the responsibility of handling your every need from manufacturing to implementing ERP software.<br /><br />

                Our incredible team of developers ensures that you get the best ERP software development services. The innumerable benefits we provide you ensure a great working experience for you.
            </StyledHeading>
            <StyledImage src={CRM} alt= "CRM"/>
            <StyledSubHeading>CRM Billing</StyledSubHeading>
            <StyledImage src={Billing} alt="Billing" />
            <StyledSubHeading>Inventory Management</StyledSubHeading>
            <StyledHeading>
                Inventory management is crucial for every business. We at Coconut provide you with an ERP Inventory Management service that makes it easy for you to track your orders, plan inventory replenishment, manage inventory costs, and other tasks.
            </StyledHeading>
            <StyledSubHeading>HR\Payroll</StyledSubHeading>
            <StyledHeading>
                There are hundreds or even more employees working in your business and all their information is dispersed. Well, no worries because with our custom ERP software development in Faridabad you can find all that dispersed information in one place. Providing you with error-free details of every employee working for you in no time.
            </StyledHeading>
           <StyledImage src={payroll}  alt="payroll"/>
            <StyledSubHeading>Material Management</StyledSubHeading>
            <StyledHeading>
                Coconut RARR provides you with custom ERP software development for your material management needs. Even a small wastage of inventory is still a loss for your company and this can be avoided with the assistance of an ERP system custom made specifically for your needs.
            </StyledHeading>
            <StyledSubHeading>Job Work</StyledSubHeading>
            <StyledHeading>
                Why do redundant tasks like data entry when you can do more thoughtful and important tasks at the same time? But, how? The answer is simply getting a custom ERP development in Faridabad, i.e., through Coconut ERP, and improving your working efficiency.
            </StyledHeading>
            
            <StyledSubHeading>Production Planning</StyledSubHeading>
            <StyledHeading>
                Effective production planning is important for many reasons like saving time, reducing cost, etc. Our ERP development company in India will help you in keeping a track of your materials and inventory, automatically placing an order when needed, and help you in creating and managing your production plan.
            </StyledHeading>
            
            
            <StyledSubHeading>Masters</StyledSubHeading>
            <StyledHeading>
                <HighlightedText>CRM Building:</HighlightedText> A business cannot reach great heights without developing a good customer relationship. And for that, you need a robust CRM system with automatic billing capabilities.
            </StyledHeading>
            <StyledHeading>
                <HighlightedText>Customer:</HighlightedText> Create an effective customer relationship and generate leads and record purchase orders for your business. Improve your customers' satisfaction through follow-up questions and other services.
                Billing: Create all kinds of invoices and record payments received. When all your information is in one place you save your time, reduce costs and improve your working experience.
                Reports: Generate all the reports you need with all the information you possess. Provides you reports of any lost sales and sales key performance indicators to measure your effectiveness and efficiency.
            </StyledHeading>
            <StyledHeading>
                <HighlightedText>Billing:</HighlightedText> Create all kinds of invoices and record payments received. When all your information is in one place you save your time, reduce costs and improve your working experience.
            </StyledHeading>
            <StyledHeading>
                <HighlightedText>Reports:</HighlightedText> Generate all the reports you need with all the information you possess. Provides you reports of any lost sales and sales key performance indicators to measure your effectiveness and efficiency.
            </StyledHeading>
            <StyledHeading>
                <HighlightedText>HR\Payroll:</HighlightedText> <br />
                Employees of a business are its greatest assets and all their details thus need to be collected and recorded in one place. When you get a custom ERP software made from us you get assured of finding your employee's various details managed according to your needs.<br></br>
                From preparing various reports to calculating salaries to tracking leaves and recording attendance, it reduces your dependency on human resources for repetitive and unimportant tasks.
                
                            </StyledHeading>
            <StyledHeading>
                    <HighlightedText>Production Planning:</HighlightedText> <br />

                    We customize an ERP software development for all your needs. Production planning is an important step in improving the efficiency and productivity of your company.<br></br>
                    Get an ERP software that provides you the reports of daily output production, production schedule summary, and detail. Also helps you to derive a detailed production schedule and machine maintenance schedule. All in all, it offers you a quick solution for every production-related task.

                </StyledHeading>
                <StyledImage src={production} alt="production"/>
                </Container>
    )
}