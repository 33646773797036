import React, { useState } from 'react'
import styled from 'styled-components';

const StyledP = styled.h4`
    font-size: 16px;
    font-weight: 400;
    margin-bottom : 16px ;
    line-height: normal;
    @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
  }
`
const StyledTableImg = styled.img`
height: 18px;
margin-right: 10px;
`

const Service = () => {

   return(
    <>
   <StyledP><StyledTableImg src={require('../../images/customer-service.svg').default} alt="service" />Dynamics 365 Customer Service</StyledP>
   <StyledP><StyledTableImg src={require('../../images/field-service.svg').default} alt="fieldservice" />Dynamics 365 Field Service</StyledP>
   <StyledP><StyledTableImg src={require('../../images/remote-assist.svg').default} alt="remote-assist" />Dynamics 365 Remote Assist</StyledP>
   <StyledP><StyledTableImg src={require('../../images/customer-insights.svg').default} alt="insights" />Dynamics 365 Customer Insights</StyledP>
   <StyledP><StyledTableImg src={require('../../images/customer-voice.svg').default} alt="voice" />Dynamics 365 Customer Voice</StyledP>
    </>
   )
}

export default Service 