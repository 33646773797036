import React from 'react'
import styled from 'styled-components';
import { MainBanner } from '../Subpages/components/MainBanner';
import { ContactSection } from '../Subpages/components/ContactSection';
import { MainHeading } from '../Subpages/components/MainHeading';
import  ContentDevelopment  from './components/ContentDevelopment';
import MainTabSection from '../UI/UXdesign/MainTabSection';
// css_components
const Container = styled.div`
`
const LeftDiv  = styled.div`
order: 2;
@media (min-width: 991px) {
  order: 1;
}
`
const RightDiv = styled.div`
  order: 1;

  @media (min-width: 991px) {
    order: 2;
  }
`;

const AppDevelopment = () => {
  return (
    <>
      <Container className='mb-3'>
        <MainBanner name="Application Development" pageName="Application Development"/>
        <Container className='container'>
          <div className='row'>
            <LeftDiv className='col-lg-3'>
              <div className='fixedSection'>
                  <div>
            <MainTabSection/>
                    <ContactSection/>
              </div>
              </div>
            </LeftDiv>
            <RightDiv className='col-lg-9'>
              <MainHeading name='Application Development'/>
              <ContentDevelopment />
            </RightDiv>
          </div>
        </Container>

      </Container>
    </>
  )

}

export default AppDevelopment