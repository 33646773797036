import React, { useEffect, useRef, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import CreatContext from "../../../context/CreatContext";

// import css 
import { BGbanner, StyleDiv, StyleH5, StylePara, ColDiv, Stylespan, Discuss_btn, Animationdiv1, Animation1, Animation2, Animation3 } from './css/HeroSectionCss';

// import images
import HomeBannerImg from "../../../images/Frame 1000005892.png";
import LogoMicrosoftImg from "../../../images/logos_microsoft.png"
import CoconutERP from '../../../images/coconutERP.png';
import AtsMantraImg from '../../../images/atsMantraimg.png';
import Recruitment from '../../../images/Recruitment.png';
import MicrosoftRecr from '../../../images/MicrosoftRecr.png';
import RarrTech123Q from '../../../images/RarrTech123Q.png';

const HeroSection = () => {
  const context = useContext(CreatContext);
  const { activeLink, setActiveLink } = context;
  const [isActive, setIsActive] = useState(1);
  const [marginLeftSide, setMarginLeftSide] = useState('');

  const handleToActiveLinkHeader = () => {
    setActiveLink("contact");
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    let i = 2;
    setInterval(() => {
      setIsActive(i);
      i++;
      if (i === 5) {
        i = 1;
      }
    }, 6000);
  }, [])



  return (
    <>
      <BGbanner>
        <div className='container-fluid'>

          <Animationdiv1 className={`${isActive === 1 ? '' : 'd-none'}`}>
            <Animation1>
              <img src={LogoMicrosoftImg} alt="Microsoft" />
            </Animation1>
            <Animation2 className="ms-4">
              <div className="content">
                <h1 className="head2" >Microsoft Solutions
                  <Stylespan>Dynamics CRM . Azure . Office 365</Stylespan>
                </h1>
              </div>
            </Animation2>
            <Animation3>

            </Animation3>
          </Animationdiv1>

          <Animationdiv1 className={`${isActive === 2 ? '' : 'd-none'}`}>
            <Animation1>
              <img src={CoconutERP} alt="CoconutERP" />
            </Animation1>
            <Animation2 className="ms-4">
              <div className="content">
                <h1 className="head2" >Coconut ERP
                  <Stylespan>Empowering Your Business</Stylespan>
                </h1>
              </div>
            </Animation2>
          </Animationdiv1>

          <Animationdiv1 className={`${isActive === 3 ? '' : 'd-none'}`}>
            <Animation1>
              <img src={AtsMantraImg} alt="AtsMantra" />
            </Animation1>
            <Animation2 className="ms-4">
              <div className="content">
                <h1 className="head2" >atsMantra
                  <Stylespan>Empowering Recruitment</Stylespan>
                </h1>
              </div>
            </Animation2>
          </Animationdiv1>

          <Animationdiv1 className={`${isActive === 4 ? '' : 'd-none'}`}>
            <Animation1>
              <img src={RarrTech123Q} alt="RarrTech" />
            </Animation1>
            <Animation2 className="ms-4">
              <div className="content">
                <h1 className="head2" >Recruitment
                  <Stylespan>Unleashing Talent Through Tech</Stylespan>
                </h1>
              </div>
            </Animation2>
          </Animationdiv1>

          <div className="row mt-5" id="Revolution">
            <ColDiv className="col-lg-6 ">
              <StyleH5>Drive Digital Revolution With Us !</StyleH5>
              {/* <StylePara>Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit.Aliqu diam amet diam et eos labore</StylePara>
              <StylePara>Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit.Aliqu diam amet diam et eos labore</StylePara>
              <StylePara>Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore. clita erat ipsum et lorem et sit</StylePara> */}
              <StylePara>
                At RARR Technologies Pvt. Ltd., we believe in the power of collaboration and open communication. Our collaborative approach enables us to deeply understand our clients' requirements, allowing us to craft software products that are not only technically superior but also align perfectly with their business goals. By combining our expertise with a meticulous attention to detail, we ensure that every line of code we write contributes to the creation of robust, scalable, and future-proof digital solutions that stand the test of time.
              </StylePara>

              <Link to="/contact" onClick={handleToActiveLinkHeader}><Discuss_btn>Discuss With us</Discuss_btn></Link>

            </ColDiv>
            <div className="col-lg-6 imgClassCss">

              <img src={HomeBannerImg} width="100%" alt="HomeBanner"></img>
            </div>
          </div>
        </div>

      </BGbanner >
    </>
  )
}

export default HeroSection