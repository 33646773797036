import React, { useEffect } from "react";
import SocialShare from "./SocialShare";

const BlogDetailbanner = (props) => {
  const { item, formatDate } = props;

  const metaTag = item.meta_tag.split(",");
  const metaTagColor = item.color_names.split(",");
  const blogPath = process.env.REACT_APP_BLOG_PATH;

  const message = window.location.href;

  const messageWhatsAppShare = encodeURIComponent(message);

  useEffect(() => {
    document.getElementsByTagName(
      "META"
    )[3].content = `${item.meta_description}`;
    document.getElementsByTagName("META")[4].content = `${item.meta_tag}`;
  }, []);

  return (
    <div className="container BlogDetailBanner" style={{ marginTop: "20px" }}>
      <h1 className="blogDetail-titlehead">{item.title}</h1>

      <div className="row align-items-end">
        <div className="col-lg-5 col-7">
          <div className="row">
            <div>
              <div style={{ display: "flex" }}>
                <div className="userImgcol">
                  {
                    <img
                      src={`${blogPath + item.user_image}`}
                      alt={item.author}
                      className={`userProfile-detail ${
                        item.user_image ? "" : "d-none"
                      }`}
                    />
                  }
                  {
                    <h3
                      className={`userProfile ${
                        item.author_initials ? "" : "d-none"
                      }`}
                    >
                      {item.author_initials}
                    </h3>
                  }
                </div>

                <p className="userInformation">
                  {item.author}
                  <span title="Posted Date" style={{ marginLeft: "10px" }}>
                    {formatDate(item.post_date)}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-5">
          <fieldset className="d-flex">
            <legend></legend>
            <SocialShare url={messageWhatsAppShare} title="" />
          </fieldset>
        </div>
      </div>
      <div className="row">
        <div
          className="col-lg-9"
          style={{ marginTop: " 20px", borderRadius: "10px" }}
        >
          <div
            className="blog-banner-1"
            style={{ backgroundImage: "url(" + item.blog_image + ")" }}
          ></div>
        </div>
        <div className="col-lg-3" style={{ marginTop: " 20px" }}>
          <div className="blog-cat-box">
            {metaTag.map((item, i) => {
              return (
                <div
                  key={i}
                  className="skillDiv-2 me-2"
                  style={{ background: `${metaTagColor[i]}` }}
                >
                  <p className="skillName-2">{item}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        style={{ fontFamily: "Mulish", marginTop: "20px" }}
        dangerouslySetInnerHTML={{ __html: item.description }}
      ></div>
    </div>
  );
};

export default BlogDetailbanner;
