import React, { useState } from "react";
import CreatContext from "./CreatContext";
import axios from "axios";
import Swal from "sweetalert2";

const ProvidContext = (props) => {
  const host = process.env.REACT_APP_HOST_SERVER;

  // we are using these to store fetch data
  const [jobInfo, setJobInfo] = useState([]);
  // const [applyNowJobInfo, setApplyNowJobInfo] = useState([]);
  const [locInfo, setLocInfo] = useState([]);
  const [jmIdLocInfo, setJmIdLocInfo] = useState([]);
  const [jmIdInfo, setJmIdInfo] = useState([]);
  const [companyProfileSiteID, setCompanyProfileSiteID] = useState([]);
  const [matchingJOb, setMatchingJob] = useState([]);
  const [blogpagedata, setBlogpagedata] = useState([]);
  const [getQuestionData, setGetQuestionData] = useState([]);
  const [questionSectionDisplay, setQuestionSectionDisplay] = useState(false);
  const [questionFlag, setQuestionFlag] = useState("N");
  const [loader1, setLoader1] = useState(false);
  const [showSorryDetail, setShowSorryDetail] = useState(1);
  const [jobDetailPathName, setJobDetailPathName] = useState("");
  const [noticePeriodData, setNoticePeriodData] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [userUnId, setUserUnId] = useState("");
  const [storeSkills, setStoreSkills] = useState([]);
  const [sourceIdUrl, setSourceIdUrl] = useState("");
  const [completeJobDetail, setCompleteJobDetail] = useState([]);

  const [activeLink, setActiveLink] = useState("");
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(false);
  const [statusCheck, setStatusCheck] = useState("ok");
  const [showSorry, setShowSorry] = useState(1);
  const [jmIdValueStore, setJmIdValueStore] = useState("");

  // we are using these to store keywords like skills, location, max experience in localstorage
  const [enterSkillDesignation, setEnterSkillDesignation] = useState([]);
  const [enterLocation, setEnterLocation] = useState([]);
  const [enterMaxExperience, setEnterMaxExperience] = useState("");
  const [employmentType, setEmploymentType] = useState([]);
  const [jobPostTime, setJobPostTime] = useState("All time");

  // ----Start-------Functions without api----------------

  const [postUrl, setPostUrl] = useState();
  const [blogUniqueUrlId, setBlogUniqueUrlId] = useState();

  const callByUrlBlog = async () => {
    const str = window.location.href;
    const searchTearm = "/blog/";
    const searchStr = await str.indexOf(searchTearm);
    const strLength = searchStr + searchTearm.length;
    const afterSearchStr = await str.substring(strLength);
    const afterSearchStr_2 = await str.substring(searchStr);
    setPostUrl(afterSearchStr_2);
    setBlogUniqueUrlId(afterSearchStr);
    window.scrollTo(0, 0);
  };

  // function to save and find urlId's in url
  const stringAfterJobUniqueIdJobDetail = async (str) => {
    // console.log(str);

    const searchTearm_u = "&u=";
    const searchTearm_source = "&source=";

    const searchIndex_u = await str.search(searchTearm_u);
    const searchIndex_source = await str.search(searchTearm_source);

    if (searchIndex_u === -1) {
      if (searchIndex_source === -1) {
        return;
      } else {
        const len = searchIndex_source + searchTearm_source.length;
        const afterSearchStr = await str.substring(len);
        setSourceIdUrl(afterSearchStr);
        // console.log("1 src", afterSearchStr);
      }
    } else if (searchIndex_source === -1) {
      const len = searchIndex_u + searchTearm_u.length;
      const afterSearchStr = await str.substring(len);
      setUserUnId(afterSearchStr);
      // console.log("2 u", afterSearchStr);
    } else {
      if (searchIndex_u < searchIndex_source) {
        // for u=
        const len_u = searchIndex_u + searchTearm_u.length;
        const afterSearchStr_u = await str.substring(len_u, searchIndex_source);
        setUserUnId(afterSearchStr_u);
        // console.log("3 u", afterSearchStr_u);
        // for source=
        const len_source = searchIndex_source + searchTearm_source.length;
        const afterSearchStr_source = await str.substring(len_source);
        setSourceIdUrl(afterSearchStr_source);
        // console.log("4 src", afterSearchStr_source);
      } else {
        // for source=
        const len_source = searchIndex_source + searchTearm_source.length;
        const afterSearchStr_source = await str.substring(
          len_source,
          searchIndex_u
        );
        setSourceIdUrl(afterSearchStr_source);
        // console.log("5 src", afterSearchStr_source);
        // for u=
        const len_u = searchIndex_u + searchTearm_u.length;
        const afterSearchStr_u = await str.substring(len_u);
        setUserUnId(afterSearchStr_u);
        // console.log("6 u", afterSearchStr_u);
      }
    }
  };

  const callJobDetail = async () => {
    window.scrollTo(0, 0);
    setLoader1(true);
    const str = window.location.href;

    const searchTearm = "job-detail";
    // const searchTearm_2 = "&u=";
    const searchTearm_2 = "&";
    const searchStr = await str.search(searchTearm);
    const searchStr_2 = await str.search(searchTearm_2);
    const strLength = searchStr + searchTearm.length;
    let afterSearchStr = "";
    let subStringAfterUniqueId = "";

    if (searchStr === -1) {
      // console.log(afterSearchStr);
      const searchTearm_1 = "jobDetail";
      const searchStr_1 = await str.search(searchTearm_1);
      const strLength_1 = searchStr_1 + searchTearm_1.length;
      if (searchTearm_1 === -1) {
        return false;
      } else if (searchStr_2 === -1) {
        afterSearchStr = await str.substring(strLength_1);
        setJobDetailPathName(afterSearchStr);
      } else {
        afterSearchStr = await str.substring(strLength_1);
        setJobDetailPathName(afterSearchStr);
        afterSearchStr = await str.substring(strLength_1, searchStr_2);
        subStringAfterUniqueId = await str.substring(searchStr_2);
      }
    } else if (searchStr_2 === -1) {
      afterSearchStr = await str.substring(strLength);
      setJobDetailPathName(afterSearchStr);
      // console.log(afterSearchStr);
    } else {
      const jobdetailpath = await str.substring(strLength);
      setJobDetailPathName(jobdetailpath);
      afterSearchStr = await str.substring(strLength, searchStr_2);
      subStringAfterUniqueId = await str.substring(searchStr_2);
    }
    // function call to get data of job-detail page
    getJobDetailJmid(afterSearchStr);

    // function call to save and find urlId's in url
    stringAfterJobUniqueIdJobDetail(subStringAfterUniqueId);
  };

  // ----End-------Functions without api----------------

  // get auth-token
  const authTokenApi = async () => {
    const url = `/authenticate`;
    //API Call
    const response = await fetch(`${host}${url}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: "systemadmin@gmail.com",
        password: "test",
      }),
    });

    const info = await response.json();
    const authtoken2 = await info.token;
    setAuthToken(authtoken2);
    return true;
  };

  // get location details
  const getLocationDetails = async (value) => {
    const url = `/userInterface/job/getAllCommonCityView?value=${value}`;
    //API Call
    const response = await fetch(`${host}${url}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      // body: JSON.stringify({ value: value })
    });

    const info = await response.json();
    const data = await info.data;
    setLocInfo(data);
    // console.log(info);
  };

  const getNoticePeriodData = async () => {
    const url = `/userInterface/NoticePeriodDropdownListVw`;
    //API Call
    const response = await fetch(`${host}${url}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      // body: JSON.stringify({ value: value })
    });

    const info = await response.json();
    const data = await info.data;
    setNoticePeriodData(data);
  };

  // get location details using jm_id
  const getLocationDetailsByJmId = async (jmId) => {
    const url = "/userInterface/getApiConfigListParamWithoutLogin";
    const params = ["164", "0", jmId];

    //API Call
    const response = await fetch(`${host}${url}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ apiId: 233, params: params }),
    });

    const info = await response.json();
    const data = await info.data;
    setJmIdLocInfo(data);
    // console.log(info);
  };

  // get Job details
  const getJobDetail = async (
    dtPosting,
    skillsSearch = "",
    location = "",
    minExp,
    maxExp,
    EmpType
  ) => {
    window.scrollTo(0, 0);
    setJobInfo([]);
    setTotal(0);
    setLoader(true);
    setShowSorry(1);
    //  const url = '/getApiConfigListParamWithoutLogin';
    const url = "/userInterface/getApiConfigListParamWithoutLogin";

    // const params = ['164', '0', 'All time', '', '', 0, 0, 'Full Time, Temporary, Part Time', '', 0, ""];

    const params = [
      "164",
      "0",
      dtPosting,
      skillsSearch,
      location,
      minExp,
      maxExp,
      EmpType,
      "",
      0,
      "rarrtech",
    ];

    //API Call
    const response = await fetch(`${host}${url}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ apiId: 214, params: params }),
    });

    const info = await response.json();
    const data = await info.data;
    setJobInfo(data);
    // setJobInfo(info.rows);
    // const totalLength = await info.rows.length;
    const totalLength = await data.length;
    setTotal(totalLength);
    setLoader(false);

    if (totalLength === 0 || totalLength === undefined) {
      setShowSorry(0);
    }
  };

  // save info when user apply on job
  const applyNowForJobTicket = async (
    applyNowJmId,
    fname,
    lname,
    mobile,
    email,
    currentLoc,
    preferred_locString,
    currentCTC,
    expectedCTC,
    skillString,
    remarks,
    candidateExperience,
    relevantExperience,
    applyJobResumeFile,
    preferredLocation,
    date,
    noticePeriod,
    servingNoticePeriod,
    prefferedLocLmID,
  ) => {
    // const url = '/job/saveApplicantMasterWeb';
    const url = "/job/saveApplicantMasterRest";

    setStatusCheck("not ok");

    let applicantMasterWeb1 = "";

    if (userUnId === "" || userUnId === undefined) {
      applicantMasterWeb1 = JSON.stringify({
        firstName: fname,
        lastName: lname,
        jmId: applyNowJmId,
        isActive: "Y",
        remarks: remarks,
        email: email,
        mobile: mobile,
        expCtc: expectedCTC,
        currentCtc: currentCTC,
        currentLoc: currentLoc,
        totalexp: candidateExperience,
        relExp: relevantExperience,
        preferredLoc: prefferedLocLmID,
        otherPreffLoc: preferred_locString,
        skills: skillString,
        lastWorkingDate: date,
        noticePeriod: noticePeriod,
        inNoticePeriod: servingNoticePeriod,
        applicantResume: applyJobResumeFile.name,
        srcUniqueId: "3d2d927915",
        applicantPrefLocation: preferredLocation,
        siteid: completeJobDetail.siteid,
      });
    } else {
      applicantMasterWeb1 = JSON.stringify({
        firstName: fname,
        lastName: lname,
        jmId: applyNowJmId,
        isActive: "Y",
        remarks: remarks,
        email: email,
        mobile: mobile,
        expCtc: expectedCTC,
        currentCtc: currentCTC,
        currentLoc: currentLoc,
        totalexp: candidateExperience,
        relExp: relevantExperience,
        preferredLoc: prefferedLocLmID,
        otherPreffLoc: preferred_locString,
        skills: skillString,
        lastWorkingDate: date,
        noticePeriod: noticePeriod,
        inNoticePeriod: servingNoticePeriod,
        applicantResume: applyJobResumeFile.name,
        srcUniqueId: "3d2d927915",
        applicantPrefLocation: preferredLocation,
        userUniqueId: userUnId,
        siteid: completeJobDetail.siteid,
      });
    }
    // console.log("applicantMasterWeb1", applicantMasterWeb1);

    const blob = new Blob([applicantMasterWeb1], {
      type: "application/json",
    });

    let formData = new FormData();
    formData.append("resumeName", applyJobResumeFile);
    formData.append("applicantMasterWeb", blob);

    // console.log(Object.fromEntries(formData));

    // API Call
    const response = await axios({
      method: "POST",
      url: `${host}${url}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
    });

    const info = await response.data;
    // console.log(info)
    const jaIdValue = await info.jaId;

    if (info.amId !== null) {
      if (questionFlag === "Y") {
        getQestionsDetails(jaIdValue, applyNowJmId);
        return false;
      }
      setStatusCheck("ok");
      Swal.fire({
        title: "Successfully Submitted",
        icon: "success",
      });
      return false;
    }

    if (info.amId === null) {
      setStatusCheck("ok");
      Swal.fire({
        title: "Something went wrong",
        icon: "warning",
      });
      return false;
    }
  };

  // save info when user register(post resume) not apply on job
  const applyNowForJobTicket1 = async (
    fname,
    lname,
    mobile,
    email,
    currentLoc,
    preferred_locString,
    currentCTC,
    expectedCTC,
    skillString,
    remarks,
    applyJobResumeFile,
    totalexp,
    preferredLoca,
    date,
    noticePeriod,
    servingNoticePeriod,

  ) => {
    // const url = '/job/saveApplicantMasterWeb';
    const url = "/job/saveApplicantMasterRest";

    setStatusCheck("not ok");
    const applicantMasterWeb1 = JSON.stringify({
      firstName: fname,
      lastName: lname,
      isActive: "Y",
      remarks: remarks,
      email: email,
      mobile: mobile,
      expCtc: expectedCTC,
      currentCtc: currentCTC,
      currentLoc: currentLoc,
      preferredLoc: preferred_locString,
      skills: skillString,
      applicantResume: applyJobResumeFile.name,
      totalexp: totalexp,
      applicantPrefLocation: preferredLoca,
      lastWorkingDate: date,
      noticePeriod: noticePeriod,
      inNoticePeriod: servingNoticePeriod,
      srcUniqueId: "3d2d927915",
      siteid: "7",
    });

    const blob = new Blob([applicantMasterWeb1], {
      type: "application/json",
    });

    let formData = new FormData();
    formData.append("resumeName", applyJobResumeFile);
    formData.append("applicantMasterWeb", blob);

    // console.log(Object.fromEntries(formData));

    // API Call
    const response = await axios({
      method: "POST",
      url: `${host}${url}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
    });

    const info = await response.data;
    // console.log(info)

    if (info.am_id !== null) {
      setStatusCheck("ok");
      Swal.fire({
        title: "Successfully Submitted",
        icon: "success",
      });
      return false;
    } else {
      setStatusCheck("ok");
      Swal.fire({
        title: "Something went wrong",
        icon: "warning",
      });
      return false;
    }
  };

  // get questionAnswerSave details
  const questionAnswerSave = async (queAnswer) => {
    const url = "/userInterface/saveQuestionResponse";
    setStatusCheck("not ok");
    // console.log(queAnswer);

    try {
      //API Call
      const response = await fetch(`${host}${url}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(queAnswer),
      });

      if (response.status === 200) {
        setStatusCheck("ok");
        Swal.fire({
          title: "Successfully Submitted",
          icon: "success",
        });
      } else if (response.status !== 200) {
        setStatusCheck("ok");
        Swal.fire({
          title: "Something went wrong",
          icon: "warning",
        });
      }
      setStatusCheck("ok");
      setGetQuestionData([]);
    } catch (error) {
      console.log(error);
      setStatusCheck("ok");
      Swal.fire({
        title: "Something went wrong",
        icon: "warning",
      });
    }
  };

  // save recommend to friend job details
  const recommendToFriendSave = async (
    applyNowJmIdRtoF,
    name,
    email,
    frName,
    frEmail
  ) => {
    const url = "/userInterface/saveAtsReferToFriend";
    setStatusCheck("not ok");
    try {
      //API Call
      const response = await fetch(`${host}${url}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          jmId: applyNowJmIdRtoF,
          applName: name,
          applEmail: email,
          applFriendName: frName,
          applFriendEmail: frEmail,
          isactive: "Y",
        }),
      });

      if (response.status === 200) {
        setStatusCheck("ok");
        Swal.fire({
          title: "Successfully Submitted",
          icon: "success",
        });
        return false;
      } else if (response.status !== 200) {
        setStatusCheck("ok");
        Swal.fire({
          title: "Something went wrong",
          icon: "warning",
        });
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get Questions details
  const getQestionsDetails = async (jaId, jmId) => {
    try {
      const url = "/userInterface/getApiConfigListParamWithoutLogin";
      const params = ["164", "0", jaId, jmId];
      //API Call
      const response = await fetch(`${host}${url}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ apiId: 228, params: params }),
      });

      const info = await response.json();
      const data = await info.data;
      setGetQuestionData(data);
      setQuestionSectionDisplay(true);
      setStatusCheck("ok");
      // console.log(info);
    } catch (error) {
      console.log(error);
      setStatusCheck("ok");
    }
  };

  // get job detail using job_unique_id *************************jm_id
  const getJobDetailJmid = async (jobUniqueId) => {
    try {
      setLoader1(true);
      const url = "/userInterface/getApiConfigListParamWithoutLogin";

      const params = ["164", "0", jobUniqueId, "rarrtech"];

      //API Call
      const response = await fetch(`${host}${url}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ apiId: 216, params: params }),
      });

      const info = await response.json();
      const data = await info.data;
      if (data.length === 0 || data.length === undefined) {
        setLoader1(false);
        setShowSorryDetail(0);
        return;
      }
      const jmId = await data[0].jm_id;
      setJmIdValueStore(jmId);
      setJmIdInfo(data);
      const siteidValue = await data[0].siteid;
      const skillName = await data[0].job_skills;
      document.getElementsByTagName("META")[4].content = `${skillName}`;
      getMatchingJob(jmId);
      getJobCompanyProfileSiteId(siteidValue);
      setLoader1(false);
    } catch (error) {
      console.log(error);
      setLoader1(false);
    }
  };

  // get job detail using jm_id
  const getJobCompanyProfileSiteId = async (siteid) => {
    const url = "/userInterface/getApiConfigListParamWithoutLogin";
    const params = ["0", siteid, "rarrtech"];
    try {
      //API Call
      const response = await fetch(`${host}${url}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ apiId: 219, params: params }),
      });

      const info = await response.json();
      const data = await info.data;
      setCompanyProfileSiteID(data);

      // console.log(info);
    } catch (error) {
      console.log(error);
    }
  };

  const getMatchingJob = async (jmId) => {
    const url = "/userInterface/getApiConfigListParamWithoutLogin";

    const params = ["164", "0", jmId, "rarrtech"];
    try {
      //API Call
      const response = await fetch(`${host}${url}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ apiId: 220, params: params }),
      });

      const info = await response.json();
      const data = await info.data;
      setMatchingJob(data);
      // console.log(info);
    } catch (error) {
      console.log(error);
    }
  };

  const getBlogPageCard = async () => {
    window.scrollTo(0, 0);
    const url = "/userInterface/getApiConfigListParamWithoutLogin";

    const params = ["164", "0"];
    try {
      //API Call
      const response = await fetch(`${host}${url}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ apiId: 222, params: params }),
      });

      const info = await response.json();
      const data = await info.data;
      setBlogpagedata(data);
    } catch (error) {
      console.log(error);
    }
    // console.log(info)
  };

  const getSkillsDropDown = async () => {
    const url = "/userInterface/getCacheSkills";
    try {
      //API Call
      const response = await fetch(`${host}${url}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      const info = await response.json();
      const data = await info.data;
      setStoreSkills(data);
    } catch (error) {
      console.log(error);
    }
  };

  // Contact us
  const contactUsFormSaveData = async (fname, pEmail, mobile, remarks) => {
    const url = "/userInterface/createContactUsInfo";
    // console.log(fname, lname, cName, pEmail, mobile);

    // API Call
    const response = await fetch(`${host}${url}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        firstName: fname,
        email: pEmail,
        mobile: mobile,
        remarks: remarks,
      }),
    });

    const info = await response.json();
    //console.log(info);

    if (info === 1) {
      Swal.fire({
        title: "Request sent Successfully ",
        icon: "success",
      });
      return false;
    } else if (info === 0) {
      Swal.fire({
        title: "Request Failed",
        icon: "warning",
      });
      return false;
    } else if (response.status !== 200) {
      Swal.fire({
        title: "Something went wrong",
        icon: "warning",
      });
      return false;
    }
  };
  return (
    <CreatContext.Provider
      value={{
        authTokenApi,
        authToken,
        getLocationDetails,
        setGetQuestionData,
        blogUniqueUrlId,
        getNoticePeriodData,
        noticePeriodData,
        setNoticePeriodData,
        callByUrlBlog,
        postUrl,
        jobDetailPathName,
        callJobDetail,
        applyNowForJobTicket,
        getJobDetail,
        getJobDetailJmid,
        getJobCompanyProfileSiteId,
        showSorryDetail,
        getMatchingJob,
        getBlogPageCard,
        recommendToFriendSave,
        enterMaxExperience,
        setEnterMaxExperience,
        enterLocation,
        setEnterLocation,
        enterSkillDesignation,
        setEnterSkillDesignation,
        locInfo,
        jobInfo,
        total,
        loader,
        jmIdInfo,
        companyProfileSiteID,
        matchingJOb,
        blogpagedata,
        loader1,
        jmIdValueStore,
        setJmIdValueStore,
        statusCheck,
        setShowSorry,
        showSorry,
        applyNowForJobTicket1,
        getQestionsDetails,
        getQuestionData,
        questionAnswerSave,
        jmIdLocInfo,
        getLocationDetailsByJmId,
        questionSectionDisplay,
        setQuestionSectionDisplay,
        contactUsFormSaveData,
        activeLink,
        setActiveLink,
        questionFlag,
        setQuestionFlag,
        setEmploymentType,
        employmentType,
        jobPostTime,
        setJobPostTime,
        storeSkills,
        getSkillsDropDown,
        setCompleteJobDetail
      }}
    >
      {props.children}
    </CreatContext.Provider>
  );
};

export default ProvidContext;
