import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const MultiInputTags = (props) => {
  const { onTag, selectedTags, placeholder, limit = 0 } = props;

  const removeTags = (indexToRemove) => {
    selectedTags([
      ...onTag.filter((item, index) => {
        return index !== indexToRemove;
      }),
    ]);
  };

  const addTags = (event) => {
    event.preventDefault();
    // if (event.target.value !== "") {
    //     selectedTags([...onTag, event.target.value]);
    //     event.target.value = "";
    // }
    if (limit === 0) {
      if (event.target.value !== "") {
        selectedTags([
          ...onTag.filter((item, index) => {
            return item !== event.target.value;
          }),
          event.target.value,
        ]);
        event.target.value = "";
      }
    } else if (onTag.length < limit) {
      if (event.target.value !== "") {
        selectedTags([
          ...onTag.filter((item, index) => {
            return item !== event.target.value;
          }),
          event.target.value,
        ]);
        event.target.value = "";
      }
    }
  };
  const firstSpaceValidation = (event) => {
    const len = event.target.value.length;
    if (len === 0 && event.charCode === 32) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  return (
    <>
      <div className="tags-input">
        <input
          type="text"
          onKeyPress={firstSpaceValidation}
          className="form-control multiInputMobileView"
          onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
          placeholder={placeholder}
          onKeyDown={(event) => (event.key === "Tab" ? addTags(event) : null)}
          onBlur={(event) =>
            event.target.value.length > 0 ? addTags(event) : null
          }
        />
        <ul id="tags">
          {onTag.map((tag, index) => {
            return (
              <li key={index} className="tag">
                <span
                  className="tag-title"
                  title={tag}
                  style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {tag}
                </span>
                <span onClick={() => removeTags(index)}>
                  <CancelIcon
                    style={{ color: "white", fontSize: "16px" }}
                    className="tag-close-icon"
                  />
                </span>
              </li>
            );
          })}
        </ul>
        <input
          type="text"
          onKeyPress={firstSpaceValidation}
          className="form-control multiInputPcView"
          onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
          placeholder={placeholder}
          onKeyDown={(event) => (event.key === "Tab" ? addTags(event) : null)}
          onBlur={(event) =>
            event.target.value.length > 0 ? addTags(event) : null
          }
        />
      </div>
    </>
  );
};

export default MultiInputTags;
