import React, { useContext } from "react";
import companyLogoRight from "../../images/company-logo-dummy.png";
import globe from "../../images/globe.png";

function CompanyProfile(props) {
  const { item } = props;
  const clientLogo = process.env.REACT_APP_CLIENT_LOGO;
  return (
    <div>
      <h3 className="company-name">Company Profile</h3>
      <div className="detail-box">
        <div className="text-center border-bottom-1 companylogo">
          {/* <img src={(clientLogo) + (item.cmp_logo)} /> */}
          {item.cmp_logo !== null && (<img
           src={(clientLogo) + (item.cmp_logo)} 
           //src={(item.cmp_logo)}
           />)}
          {/* {item.company_initials !== null && (<div className="">{item.company_initials}</div>)} */}
          <h3 className="company-name-1 mt-2">{item.display_name}</h3>
          <p className="job-position">{item.company_type}</p>
        </div>

        <div className="col-lg-12 responsive-width mt-2">
          <div className="row">
            <div className="col-lg-5 col-md-4">
              {" "}
              <h3 className="job-detail-1">Founded Year:</h3>
            </div>
            <div className="col-lg-6 col-md-8">
              {" "}
              <h3 className="job-detail-2">{item.founded_year}</h3>
            </div>{" "}
          </div>
        </div>
        <div className="col-lg-12 responsive-width">
          <div className="row">
            <div className="col-lg-5 col-md-4">
              {" "}
              <h3 className="job-detail-1">Company Size:</h3>
            </div>
            <div className="col-lg-6 col-md-8">
              {" "}
              <h3 className="job-detail-2">{item.cmp_size}</h3>
            </div>{" "}
          </div>
        </div>
        <div className="col-lg-12 responsive-width hidein_mobile">
          <div className="row">
            <div className="col-lg-5  col-sm-4 col-md-4">
              {" "}
              <h3 className="job-detail-1">Head Quarters:</h3>
            </div>
            <div className="col-lg-7 col-sm-8 col-md-8">
              <h3 className="job-detail-2">{item.city_name}</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-12 responsive-width hidein_laptop">
          <div className="row">
            <div className="col-lg-5  col-sm-4 col-md-4">
              {" "}
              <h3 className="job-detail-1">Head Quarters:</h3>
            </div>
            <div className="col-lg-6 col-sm-8 col-md-8">
              <h3 className="job-detail-2">{item.city_name}</h3>
            </div>
          </div>
        </div>

        <h3 className="company-overview">Company Overview</h3>
        <p className="company-detail-description">{item.about_cmp}</p>
        <div className="text-center">
          <button className="btn btn-light-blue">
            <img
              src={globe}
              style={{ height: "16px", width: "16px" }}
              className="me-2"
            />
            <a
              target="_blank"
              href={
                item.website_url
                  ? item.website_url
                  : "https://www.rarrtech.com/"
              }
              style={{ fontSize: "14px" }}>
              {item.website_url
                ? item.website_url
                : "https://www.rarrtech.com/"}
            </a>
          </button>
        </div>
        <div />
      </div>
    </div>
  );
}

export default CompanyProfile;
