import React , {useContext} from 'react'
import { styled } from 'styled-components'
import { Link } from "react-router-dom";
import CreatContext from "../../context/CreatContext";
import { NavLink } from 'react-router-dom';
import RarrLogoWebsite from "../../images/RarrLogoWebsite.png"
import Location from "../../images/LocationIconwebsite.png"
import message from "../../images/msgicon.png";
import phone from "../../images/phoneIcon.png";
import backgroundImg from "../../images/backgroundimg.png"
import RarrLogo1q from '../../images/RarrLogo1q.png';
import RarrLog1qw from '../../images/RarrLog1qw.png';
import facebook from '../../images/facebook (1).png';
import Phone from '../../images/phone-call.png';
import Mail from '../../images/mail.png';
import Clock from '../../images/clock (1).png';
import linkedin from '../../images/linkedin.png';
import instagram from '../../images/instagram.png';
import check from '../../images/checklist.png';
const FooterComp = styled.footer`
 font-family : "Mulish" ;
 background-image: url(${backgroundImg});
 position : relative ;
 margin-top: 75px;
`
const Footerdiv = styled.div`
  margin-left: 0px !important;
 

& .rarrtextqw{
  margin-top: 30px;
  text-align:justify;
}

@media (max-width: 767px) {
    display: grid;
    justify-content: center;
    margin-bottom: 10px;
}
`
const Row = styled.div``
const IMG = styled.img`
height : 22px ; 
weight : 22px ;
margin-right : 15px;
`

const ImageDiv = styled.div`
  display: flex;
  margin-top: -2px;
`
const ImageDiv2 = styled.div`
  display: flex;
  margin-top: 24px;
`
const ImageDiv1 = styled.div`
  display: flex;
`
const CopyRight = styled.div`
 color : #63657E;
 font-size : 15px ;
 padding : 15px 15px 15px 0px
`
const Services = styled(NavLink)`
 font-size : 16px;
 display : block ;
 text-decoration : none ;
`
const Heading = styled.h6`
  font-size : 20px ;
  font-weight : 700 ;
`
const Locationdiv = styled.div`
@media (max-width: 767px) {
  display: grid;
    justify-content: center;
}
`


const Footer = () => {
  const context = useContext(CreatContext);
  const { activeLink, setActiveLink } = context;
  return (
    <>

      <FooterComp className="text-center text-lg-start text-white" >

        <div className="container pt-4 pb-0">
          <section >
            <Row className="row">
              <Footerdiv className="col-md-3 col-lg-3 col-xl-3 mx-auto ">
                <img src={RarrLog1qw} alt="logo"></img>
                {/* <ImageDiv className='col-lg-12 '>
                  <IMG src={message}></IMG>
                  <p> info@rarrtech.com </p>
                </ImageDiv>
                <ImageDiv1>
                  <IMG src={phone}></IMG>
                  <p>
                    7042170421
                  </p>
                </ImageDiv1> */}
                <div className='rarrtextqw'>
                  RARR Technologies Pvt. Ltd. is an industry leading software development company building digital products that lasts. By being reasonable product craftsmen, we're able to avoid surprises and focus on the quality of the software and deliver.
                </div>
                <div className="d-flex mt-2">
                <div>
                  <Link target="_blank" to="https://www.facebook.com/rarrtech/"><IMG src={facebook} alt="facebook"/></Link>
                </div>
                <div>
                <Link target="_blank" to="https://www.linkedin.com/company/rarrtech"><IMG src={linkedin} alt="linkedin"/></Link>
                </div>
                <div>
                <Link target="_blank" to="https://www.instagram.com/rarr_technologies/"><IMG src={instagram} alt="instagram"/></Link>
                </div>
                </div>
              </Footerdiv>
              <hr className="w-100 clearfix d-md-none" />
              <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mt-4">
               <Heading className=" mb-4">Solutions</Heading>
                <div className="row">
                <div className='col-lg-6'>
                    
                    <Services className="text-white mb-3" to="/microsoft-solutions/" onClick={() => {
                        setActiveLink("#BridgeSection");
                        window.scrollTo(0, 0);
                      }}>
                        
                      Microsoft Solutions
                    </Services> 
                    <Services className="text-white mb-3" to="/coconut-erp/" onClick={() => {
                        setActiveLink("#BridgeSection");
                        window.scrollTo(0, 0);
                      }}>
                      Coconut ERP
                    </Services>
                    <Services className="text-white mb-3" to="/atsmantra/" onClick={() => {
                        setActiveLink("#BridgeSection");
                        window.scrollTo(0, 0);
                      }}>
                     atsMantra
                    </Services>

                   <Services className="text-white mb-3" to="/ui-ux-designing/" onClick={() => {
                    setActiveLink("#BridgeSection");
                    window.scrollTo(0, 0);
                  }}>
                  UI/UX Designing
                </Services>
                  </div>
                <div className="col-lg-6">
                {/* <Heading className=" mb-4 ">Services</Heading> */}
                <Services className="text-white mb-3" to="/recruitment-services/" onClick={() => {
                    setActiveLink("#BridgeSection");
                    window.scrollTo(0, 0);
                  }}>
                  Recruitment Services
                </Services>
                <Services className="text-white mb-3" to="/database-design-and-development/" onClick={() => {
                    setActiveLink("#BridgeSection");
                    window.scrollTo(0, 0);
                  }}>
                  Data Base Design and Development
                </Services>
                
                <Services className="text-white mb-3" to="/application-development/" onClick={() => {
                    setActiveLink("#BridgeSection");
                    window.scrollTo(0, 0);
                  }}>
                  Application Development
                </Services>
              
                
                </div>
                </div>
              </div>
              <hr className="w-100 clearfix d-md-none" />
              <Locationdiv className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-4">
                <Heading >Our Address</Heading>
                <ImageDiv2>
                  <IMG src={Location} alt="Location"></IMG>
                  <p className="text-white">
                  119, 1st Floor Vardhmaan Plaza, Sector-19, Faridabad, Haryana-121002
                  </p>
                </ImageDiv2>
                <ImageDiv>
                  <IMG src={Phone} alt="phone"></IMG>
                  <p className="text-white">
                  +91-129-488 2321
                  </p>
                </ImageDiv>
                <ImageDiv>
                  <IMG src={Mail} alt="email"></IMG>
                  <p className="text-white">
                  info@rarrtech.com
                  </p>
                </ImageDiv>
                <ImageDiv>
                  <IMG src={Clock} alt="timings"></IMG>
                  <p className="text-white">
                  9:00 AM to 6:00 PM
                  </p>
                </ImageDiv>
              </Locationdiv>
            </Row>
          </section>
          <section className="text-white pb-3 pt-0">
            <div className="row d-flex align-items-center">
              <div className="col-md-12 col-lg-12 text-center text-md-start">
                <CopyRight className="" style={{textAlign : "center", color:"#d9d9d9"}}>
                 © 2021 All Rights Reserved. Design & Developed By RARR Technologies Pvt. Ltd.
                </CopyRight>
              </div>
            </div>
          </section>
        </div>
      </FooterComp>

    </>
  )
}

export default Footer
