import React from 'react'
import { styled } from 'styled-components'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Star from "../../../images/star.png";
import Cardimg from "../../../images/cardimg.png";
import ClientImg1 from "../../../images/clientimg1.png";
import clientimg2 from "../../../images/clientimg2.png";
import Curveimg1 from "../../../images/curveimg1.png";
import Curveimg2 from "../../../images/curveimg2.png";
import Curveimg3 from "../../../images/curveimg3.png";

const Container = styled.div`
`
const MainSection = styled.section`
margin-top : 119px;
font-family : "Mulish";

@media (max-width: 768px) {
    margin-top : 10px;
}
`
const Heading = styled.h1`
 color: #090F4E;
 font-size : 40px ;
 font-weight : 800 ;
 @media (max-width: 768px) {
    padding-left: 23px;
    padding-right: 20px;
    font-size:32px;
 }
 `;

const Para = styled.p`
 color: #63657E;
 @media (max-width: 768px) {
    padding-left: 23px;
    padding-right: 20px;
 }
`;
const Company = styled.h6`
 color: #63657E;
 font-size : 14px;
`
const Heading1 = styled.h2`
 color: #202B57;
 font-size : 20px;
 margin-top : 30px;
 font-weight: 700;
`
const Cardbody = styled.div`
padding: 20px 5px 10px 5px ;
`
const Column = styled.div`
margin-top : 20px ;
`
const Pinkcurve = styled.img`
position: absolute;
 right: 30%;
 top : 8% ;
`
const Pinkcurve1 = styled.img`
position: absolute;
 right: 15%;
 top : 8% ;
`
const Curve2 = styled.img`
position: absolute;
 right: 15%;
 top : 8% ;
`
const Curve3 = styled.img`
position: absolute;
 right: 30%;
 top : 8% ;
`
const Curve4 = styled.img`
position: absolute;
 right: 15%;
 top : 8% ;
`
const Curve5 = styled.img`
position: absolute;
 right: 30%;
 top : 8% ;
`
const Content = styled.p`
font-size : 13px ;
border-left : 2px solid #FFC06B ;
padding-left : 10px ;
`
const Content2 = styled.p`
font-size : 13px ;
border-left : 2px solid #FE78AF ;
padding-left : 10px ;
`
const Content1 = styled.p`
font-size : 13px ;
border-left: 2px solid #C1C7C8;
padding-left : 10px ;
`
const Stardiv = styled.div`
 & img {
    margin-right : 6px ;
    height : 20px ;
 }
`
const ClientImg = styled.img`
    height : 80px ;
    position : absolute ;
    top : -45px ;
    left: 25px;
`
const Card = styled.div`
    height: 340px;
    border-radius: 12px;
    margin: 60px 10px 20px 10px;
    padding: 15px 15px 10px 20px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-color: azure;
`

const Testimonial = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 768},
            items: 2,
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
        },
    };
    return (
        <>
            <MainSection>
                <Container className='container'>
                    <div className="row">
                        <Heading>What our Clients say?</Heading>
                        <Para>We build a good rapport with our clients by delivering the best IT solutions.</Para>
                      <Column  className="col-lg-12">
                        <Carousel
                            responsive={responsive}
                            itemClass="carousel-item-padding-40-px"
                            containerClass="carousel-container"
                            showDots={false}
                            arrows={false}   >
                           
                                <Card className="card">
                                  <ClientImg src={Cardimg} alt={"Aditya Singh"}></ClientImg>
                                  <Pinkcurve alt={"quotes"} src={Curveimg1}></Pinkcurve>
                                  <Pinkcurve1 alt={"quotes"} src={Curveimg1}></Pinkcurve1>
                                   <Heading1>Aditya Singh</Heading1>
                                   <Company>ANZ Pre Sales Head</Company>
                                   <Stardiv>
                                   <img alt={"rating"} src={Star}></img>
                                   <img  alt={"rating"}src={Star}></img>
                                   <img alt={"rating"} src={Star}></img>
                                   <img alt={"rating"} src={Star}></img>
                                   <img alt={"rating"}src={Star}></img>
                                   </Stardiv>
                                        <Cardbody className="card-body">
                                           <Content>With one of the complex projects coming to an end, I would like to take this as an opportunity to convey our gratitude and feedback to the RARR team. RARR Technology as an integral part of Telstra's from last 2 years roped in the required and desired database level skills along with complex algorithm development and delivery of remediation application. </Content>
                                        </Cardbody>
                                </Card>
                                <Card className="card">
                                  <ClientImg src={ClientImg1} alt={"Narender Kumar"}></ClientImg>
                                  <Curve2 src={Curveimg2} alt={"quotes"}></Curve2>
                                  <Curve3 src={Curveimg2} alt={"quotes"}></Curve3>
                                   <Heading1>Narender Kumar</Heading1>
                                   <Company>CEO, Venture</Company>
                                   <Stardiv>
                                   <img alt={"rating"} src={Star}></img>
                                   <img alt={"rating"} src={Star}></img>
                                   <img alt={"rating"} src={Star}></img>
                                   <img alt={"rating"} src={Star}></img>
                                   <img alt={"rating"} src={Star}></img>
                                   </Stardiv>
                                        <Cardbody className="card-body">
                                           <Content1>We have been a happy customer of RARR for quite some years. Irrespective of projects assigned to them, RARR teams always demonstrated an excellent technical approach in addressing complex business scenarios. They are hard-working, committed, and passionate about technology. We are definitely looking for more opportunities to work with them.</Content1>
                                        </Cardbody>
                                </Card>
                                <Card className="card">
                                  <ClientImg alt={"Nitin Khedkar"} src={clientimg2}></ClientImg>
                                  <Curve4 alt={"rating"} src={Curveimg3}></Curve4>
                                  <Curve5 alt={"rating"} src={Curveimg3}></Curve5>
                                   <Heading1>Nitin Khedkar</Heading1>
                                   <Company>CEO Vikat Teleservices</Company>
                                   <Stardiv>
                                   <img alt={"rating"} src={Star}></img>
                                   <img alt={"rating"} src={Star}></img>
                                   <img alt={"rating"} src={Star}></img>
                                   <img alt={"rating"} src={Star}></img>
                                   <img alt={"rating"} src={Star}></img>
                                   </Stardiv>
                                        <Cardbody className="card-body">
                                           <Content2 style={{lineHeight : "22px"}}>Our experiences working with RARR Technologies and particularly with Monika have been the best experiences we've ever had with a comparison with other consultants. I can confidently recommend their high quality and professional work. If you're working with them, I can say for sure that you are in great safe hands. </Content2>
                                        </Cardbody>
                                </Card>
                           
                           
                           

                             
                        </Carousel>
                        </Column>
                    </div>
                </Container>
            </MainSection>
        </>
    )
}

export default Testimonial
