import React from 'react'
import styled from 'styled-components';
import { MainBanner } from '../Subpages/components/MainBanner';
import { ContactSection } from '../Subpages/components/ContactSection';
import { MainHeading } from '../Subpages/components/MainHeading';
import  ContentRecruitment  from './components/ContentRecruitment';
import MainTabSection from '../UI/UXdesign/MainTabSection';
// css_components
const Container = styled.div``
const LeftDiv  = styled.div`
order: 2;
@media (min-width: 991px) {
  order: 1;
}
`;
const RightDiv = styled.div`
  order: 1;

  @media (min-width: 991px) {
    order: 2;
  }
`;

const Recruitment = () => {
  return (
    <Container>
        <MainBanner name="Recruitment" pageName="Recruitment"/>
        <Container className='container mb-3'>
            <div className='row'>
                <LeftDiv className="col-lg-3">
                  <div className='fixedSection'>
                    <MainTabSection/>
                    <ContactSection />
                 </div>   
                </LeftDiv>
                <RightDiv className='col-lg-9'>
                <MainHeading name='Recruitment Services'/>
                <ContentRecruitment />
                </RightDiv>
            </div>
        </Container>
    </Container>
  )
}

export default Recruitment
