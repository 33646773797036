import React, { useState } from 'react'
import styled from 'styled-components';

const StyledP = styled.h4`
    font-size: 16px;
    font-weight: 400;
    margin-bottom : 16px ;
    line-height: normal;

    @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
  }
`
const StyledTableImg = styled.img`
height: 18px;
margin-right: 10px;


`

const SalesMarketing = () => {

   return(
    <>
   <StyledP><StyledTableImg  src={require('../../images/sales.svg').default} alt="sales"   style={{ marginRight: '5px' }}/> Dynamics 365 Sales</StyledP>
   <StyledP> <StyledTableImg src={require('../../images/marketing.svg').default} alt="marketing" />Dynamics 365 Marketing</StyledP>
   <StyledP> <StyledTableImg src={require('../../images/customer-insights.svg').default} alt="insights" />Dynamics 365 Customer Insights</StyledP>
    </>
   )
}

export default SalesMarketing 