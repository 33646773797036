import React, { useContext } from 'react'
import { styled } from 'styled-components'
import Contact from "../../../images/contactBackground.png";
import { Link } from 'react-router-dom';
import CreatContext from "../../../context/CreatContext";


const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family : "Mulish";
    background: url(${Contact});
  object-fit : contain ;
  height : 220px;
 border-radius : 10px;
 margin : 20px;
 margin-top : 70px ;
 @media (max-width: 768px) {
  margin-top : 0px ;
 }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftContainer = styled.div``;

const Start = styled.h6`
    color: white;
    padding-left: 20px;
    font-size: 14px;
 `
const Experts = styled.h1`
color : white ;
font-size : 30px ; 
font-weight : 700;
padding-left : 20px;
@media (min-width: 320px) and (max-width: 767px){
  font-size: 21px;
}
 `


const Button = styled(Link)`
    color: white;
    padding: 10px 22px;
    border-radius: 50px;
    font-weight: 500;
    background: linear-gradient(118deg, #1b47fd 20%, #4e9fed 80%);
    float: right;
    font-size: 15px;
    border-color: #1b47fd;

  &:hover {
    color : white ;
  }
  &:active {
    color : white !important ;
    border-color : #1b47fd ;
  }
  @media (max-width: 768px) {
    float: left;
    padding: 6px 15px;
    font-size: 13px;
  }

`;

const ContactSection = () => {
  const context = useContext(CreatContext);
  const { activeLink, setActiveLink } = context;
  const handleToActiveLinkHeader = () => {
    setActiveLink("contact");
    window.scrollTo(0, 0);
  }
  return (
    <>
      <SectionContainer>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <ContentContainer>
                <LeftContainer>
                  <Start className="mb-3">PLAN TO START A PROJECT</Start>
                  <Experts>Our Experts Ready to Help You</Experts>

                </LeftContainer>
              </ContentContainer>
            </div>
            <div className="col-lg-4">
              <Button className="btn mt-3" to="/contact" onClick={handleToActiveLinkHeader}>Contact Us</Button>
            </div>
          </div>
        </div>
      </SectionContainer>

    </>
  )
}

export default ContactSection
