import React, { useRef, useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";

const MultiInputDropDownTag = (props) => {
  const {
    options,
    label,
    onTag,
    setOnTag,
    preSelectedTag = [],
    placeholder,
    onSearch,
    limit = 0,
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const [isOpenData, setIsOpenData] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [searchedOptions, setSearchedOptions] = useState([]);

  const boxRef = useRef();
  const dropDownListRef = useRef();

  const removeTags = (e, indexToRemove) => {
    setOnTag([
      ...onTag.filter((item, index) => {
        return index !== indexToRemove;
      }),
    ]);
  };

  const addTags = (event, option) => {
    setSelectedIndex(null);
    if (limit === 0) {
      setOnTag([
        ...onTag.filter((item, index) => {
          return item !== option;
        }),
        option,
      ]);
    } else if (onTag.length < limit) {
      setOnTag([
        ...onTag.filter((item, index) => {
          return item !== option;
        }),
        option,
      ]);
    }
    setSearchValue("");
    onSearch("");
  };

  const onSearchCahnge = (e) => {
    setSelectedIndex(null);
    setSearchValue(e.target.value);
    onSearch(e.target.value);
    scrollToTopList();
  };

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setIsOpenData(false);
      setSearchValue("");
      setSelectedIndex(null);
    }
  };

  const firstSpaceValidation = (event) => {
    const len = event.target.value.length;
    if (len === 0 && event.charCode === 32) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const scrollToTopList = () => {
    const modalbody = document.querySelector(".dropDownList");
    if (modalbody) {
      modalbody.scrollTo(0, 0);
    }
  };

  const handleToFilter = async () => {
    if (searchValue === "") {
      const data = await options.filter((item) => {
        if (onTag.some((value) => value === item[label])) return false;
        else return true;
      });
      setSearchedOptions(data);
    } else {
      const data = await options.filter((item) => {
        const itemLabel = item[label]?.toString().toLowerCase() ?? "";
        const searchValueLower = searchValue?.toString().toLowerCase() ?? "";
        if (onTag.some((value) => value === item[label])) return false;
        return itemLabel.includes(searchValueLower);
      });
      setSearchedOptions(data);
    }
  };

  useEffect(() => {
    handleToFilter();
  }, [options, searchValue]);

  // Function to handle keyboard navigation
  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex === null
          ? 0
          : Math.min(prevIndex + 1, searchedOptions.length - 1)
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex === null ? 0 : Math.max(prevIndex - 1, 0)
      );
    } else if (event.key === "Enter" && selectedIndex !== null) {
      event.preventDefault();
      const selectedOption = searchedOptions[selectedIndex];
      addTags(event, selectedOption[label]);
    }
  };
  useEffect(() => {
    if (isOpenData) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, isOpenData]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Scroll to selected index if it's not visible
    const dropDownList = dropDownListRef.current;
    const selectedItem =
      dropDownList && dropDownList.querySelector(".dropDownListItemSelected");
    if (selectedIndex !== null && selectedItem) {
      selectedItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [selectedIndex]);

  return (
    <>
      <div className="multiSelectInputTag" ref={boxRef}>
        <div className="multitags-input">
          <ul id="multitags">
            {preSelectedTag.map((tag, index) => {
              return (
                <li key={index} className="multitag">
                  <span
                    className="multitag-title"
                    title={tag}
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {tag}
                  </span>
                  <span onClick={(e) => removeTags(e, index)}>
                    <CancelIcon
                      style={{
                        color: "white",
                        fontSize: "16px",
                        marginRight: "0px",
                        marginLeft: "5px",
                      }}
                      className="multitag-close-icon"
                    />
                  </span>
                </li>
              );
            })}

            {onTag.map((tag, index) => {
              return (
                <li key={index} className="multitag">
                  <span
                    className="multitag-title"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    title={tag}
                  >
                    {tag}
                  </span>
                  <span onClick={(e) => removeTags(e, index)}>
                    <CancelIcon
                      style={{
                        color: "white",
                        fontSize: "16px",
                        marginRight: "0px",
                        marginLeft: "5px",
                      }}
                      className="multitag-close-icon"
                    />
                  </span>
                </li>
              );
            })}
          </ul>
          <input
            type="text"
            id="fltLocation"
            onKeyPress={firstSpaceValidation}
            value={searchValue}
            onChange={onSearchCahnge}
            onFocus={() => setIsOpenData(true)}
            placeholder={onTag.length === 0 ? placeholder : ""}
            className="multiInput multiInputSelect"
            onClick={() => {
              setSelectedIndex(null);
              setIsOpenData(true);
            }}
            style={{ padding: "8px 8px 8px .8rem", borderRadius: "8px" }}
          />
        </div>

        <div htmlFor="fltLocation">
          <div
            className={`dropDownList ${
              isOpenData === true ? "" : "displayNone"
            }`}
            ref={dropDownListRef}
          >
            {searchedOptions
              // .filter((option) => {
              //   if (searchValue === "") {
              //     return option;
              //   } else if (
              //     option[label]
              //       .toLowerCase()
              //       .includes(searchValue.toLowerCase())
              //   ) {
              //     return option;
              //   }
              // })
              // .filter((option, i) => {
              //   const value1 = option[label];
              //   let isTrue = false;
              //   onTag.map((item) => {
              //     if (value1 === item) {
              //       isTrue = true;
              //       return;
              //     }
              //   });
              //   if (isTrue === true) {
              //   } else {
              //     return option;
              //   }
              // })
              .map((option, i) => {
                const value = option[label];
                return (
                  <div
                    key={i}
                    onClick={(e) => addTags(e, value)}
                    // className="dropDownListItem"
                    onMouseMove={(e) => setSelectedIndex(i)}
                    className={`dropDownListItemSingleSelected ${
                      selectedIndex === i
                        ? "dropDownListItemSelected"
                        : "dropDownListItemNotSelected"
                    }`}
                  >
                    {value}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiInputDropDownTag;
