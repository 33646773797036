import React from "react";
import locationJobBoard from "../../images/location-job-board.png";
import brief from "../../images/brief.png";
import imageRemove from "../../images/image-removebg-preview (5) 2.png";
import timePeriod from "../../images/time-period.png";

function MatchingJob(props) {
  const { item, openJobDetailMatch, applyOnJobAllValues } = props;

  //const clientLogo = process.env.REACT_APP_CLIENT_LOGO;

  const functionJobTitle = (jobTitle, jmId, jobQuesPresent,item) => {
    applyOnJobAllValues(jmId, jobTitle, jobQuesPresent,item);
  };

  const encryptFunction2 = (jmIdValue, jobUniqueId) => {
    openJobDetailMatch(jobUniqueId);
  };

  return (
    <div>
      <div className="card-box">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => encryptFunction2(item.jm_id, item.job_unique_id)}
        >
          <div className="d-flex padding-overall-1">
            <div>
              {item.client_logo !== null && (
                <img
                  //src={clientLogo + item.client_logo}
                  src={item.client_logo}
                  className="imgResolution me-1 cursor-pointer"
                  alt=""
                />
              )}
              {item.company_initials !== null && (
                <div className="companyInitials">{item.company_initials}</div>
              )}
            </div>
            <div className="ms-2">
              <div className="d-flex align-items-baseline">
                <h3
                  className="ats-card-heading-text mb-1 text__ellipse cursor-pointer"
                  title={item.job_title}
                >
                  {item.job_title}
                </h3>
                <p className="ats-card-position-text mb-0">
                  ({item.no_of_position} Positions)
                </p>
              </div>
              <p
                className="ats-company-name cursor-pointer"
                title={item.company_name}
              >
                {item.company_name}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-baseline padding-overall-left-right">
            <div>
              <img src={locationJobBoard} alt="" />
            </div>
            <p
              className="ats-text-info text__ellipse"
              style={{ width: "200px" }}
              title={item.preferred_location}
            >
              {item.preferred_location}
            </p>
          </div>
          <div className="d-flex align-items-baseline padding-overall-left-right">
            <div>
              <img src={brief} alt="" />
            </div>
            <p className="ats-text-info">
              {item.min_total_exp} - {item.max_total_exp} Years ({" "}
              {item.employment_type} )
            </p>
          </div>
          <div className="d-flex align-items-baseline padding-overall-left-right">
            <div>
              <img src={imageRemove} alt="" />
            </div>
            <p
              className="ats-text-info text__ellipse"
              style={{ width: "200px" }}
              title={item.skill_name}
            >
              {item.skill_name}
            </p>
          </div>
          <div
            className="d-flex justify-content-between padding-overall-left-right"
            style={{ paddingBottom: "10px" }}
          >
            <div className="d-flex align-items-baseline">
              <div>
                <img src={timePeriod} alt="" />
              </div>
              <p className="ats-text-info" title={"Notice Period"}>
                {item.joining_period}
              </p>
            </div>
            <div title="Posted">
              <button className="days-tag btn pe-none">
                {item.job_createddt}
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-baseline justify-content-between border-top-jobboard-1">
          <button
            className="btn ApplyNow"
            data-bs-toggle="modal"
            data-bs-target="#userJobDetailModal"
            onClick={() =>
              functionJobTitle(
                item.job_title,
                item.jm_id,
                item.job_ques_present,item
              )
            }
          >
            Apply Now{" "}
          </button>
          {/* <div
            className="recommend-text cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#userRecommendFriend1"
            onClick={() =>
              functionJobTitle(
                item.job_title,
                item.jm_id,
                item.job_ques_present,item
              )
            }
          >
            Recommend to Friend
          </div> */}
          {/* <div className="d-flex">
                        <div className="me-3">
                            <img src={jobDetail} />
                        </div>
                        <div>
                            <img src={saveJobDetail} />
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
}

export default MatchingJob;
