import React, { useState } from 'react';
import ForYouContent from "./ForYouContent";
import ForyourBusiness from "./ForyourBusiness";

const MicrosoftThree = () => {

    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };
    return (
        <>
            <div className="microsoftThree-tabs">
                <div className="Microsoftthreevertical-tabs">
                    <button
                        className={activeTab === 1 ? 'active' : ''}
                        onClick={() => handleTabClick(1)}>
                        For You
                    </button>
                    <button
                        className={activeTab === 2 ? 'active' : ''}
                        onClick={() => handleTabClick(2)} title="For small and medium businesses (maximum 300 users). For enterprise products/solutions, contact separately.">
                        For Your Business <sup>#</sup>
                    </button>


                </div>

                <div className="tab-content">
                    {activeTab === 1 && <div><ForYouContent /></div>}
                    {activeTab === 2 && <ForyourBusiness />}
                </div>
            </div>

        </>
    )
}

export default MicrosoftThree;