import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import BlogCard from "./BlogCard";
import CreatContext from "../../../../context/CreatContext";


const BlogMain = () => {
  const context = useContext(CreatContext);
  const { blogpagedata, setActiveLink } = context;

  const openBlogDetail = (blogUniqueUrl) => {
    window.open(`/blog/${blogUniqueUrl}`, `/blog/${blogUniqueUrl}`);
    window.scrollTo(0, 0);
  };



  function formatDate(cetatedDate) {
    const dateObj = new Date(cetatedDate);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    return `${padZero(day)}/${padZero(month)}/${year}`;
  }

  function padZero(number) {
    return number.toString().padStart(2, "0");
  }


  return (
    <>
      <section id="BlogMainSection">
        <div className="blogSection pt-4 ">
          <div className="container">
            <h1 className="BlogMainHeading">Blogs & Articles</h1>

            <div className="blogCard-section row">
              {
                blogpagedata.slice(0, 3).map((item, i) => {
                  return (
                    <BlogCard
                      key={i}
                      item={item}
                      formatDate={formatDate}
                      openBlogDetail={openBlogDetail}
                    />
                  );
                })
              }
            </div>
            <div style={{ textAlign: 'center' }}>
              <Link style={{ padding: '10px' }} className='blogViewAll-btn ' to="/blog/" onClick={() => { setActiveLink("#BlogMainSection"); window.scrollTo(0, 0) }} >View All</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogMain;
