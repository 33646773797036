import React from 'react'
import { styled, keyframes } from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';


const changeColor = keyframes`
  0% {
    background: linear-gradient(to right, #08D9FF, #08D9FF 1%, #06296B 2% , #06296B 99%);
    border-color: aliceblue;
    width: 0%;
  }
 
  100% {
    background: linear-gradient(to right, #08D9FF 0%, #08D9FF 1%, #06296B 1%);
    border-color: aliceblue;
    width: 100%;
  }
`;

const moveText = keyframes`
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(10%);
    opacity: 1;
  } 
`;

const StyledButton = styled.button`
  width: 100%;
  text-align: initial;
  padding: 10px;
  background-color: ${props => (props.active === 'true' ? '#06296B' : '#E6EBF4')};
  margin: 8px 0px;
  color: #011A41;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Mulish';
  overflow: hidden;
  z-index: 1;
  
 
  &:hover {
    background : #E6EBF4;
  }
  &:active {
    background : #E6EBF4;
  }
`;

const StyledLink = styled(NavLink)`
    text-align: initial;
    background: #E6EBF4;
    color: #011A41;
  &:hover {
    color: #011A41;
  }

  &.active .btn {
    animation: ${changeColor} 0s  forwards;
    animation-delay: 0s;
    color : white ;
    font-weight : 500 ; 
   
  }

  &.active .Application {
    padding: 10px 20px 10px 0px;
  }
  
  &.active .Database {
    padding: 10px 20px 10px 0px;
  }

  &.active  span {
    display : inline-block ;
    animation: ${moveText}  1s  forwards;
    opacity: 0;
    white-space: nowrap;

  }
`;

const FirstButton = styled.button`
    width: 100%;
    text-align: initial;
    padding: 10px;
    background: #E6EBF4;
    margin : 28px 0px 8px 0px ;
    color: #011A41;
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Mulish';
    &:hover {
        background: #E6EBF4;
    }
`
const LastButton = styled.button`
    width: 100%;
    text-align: initial;
    padding: 10px;
    background: #E6EBF4;
    margin : 8px 0px 20px 0px ;
    color: #011A41;
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Mulish';
    &:hover {
        background: #E6EBF4;
    }
`


const MainTabSection = () => {
  const location = useLocation();
  return (
    <>

    <StyledLink to="/microsoft-solutions/" activeclassname="active-microsoft" >
        <FirstButton className='btn' active={location.pathname === '/microsoft-solutions/' ? 'true' : undefined} onClick={() => window.scrollTo(0, 0)}>
        {location.pathname === '/microsoft-solutions/' ? (  <span>-  Microsoft Solutions</span> ) : ( <span> Microsoft Solutions</span> )}
        
        </FirstButton>
      </StyledLink>

  
      <StyledLink to="/coconut-erp/" activeclassname="active-coconutERP" >
        <StyledButton className='btn' active={location.pathname === '/coconut-erp/ ' ? 'true' : undefined} onClick={() => window.scrollTo(0, 0)}>
        {location.pathname === '/coconut-erp/' ? (  <span>- Coconut ERP</span> ) : ( <span>Coconut ERP</span> )}
        
        </StyledButton>
      </StyledLink>

      <StyledLink to="/atsmantra/" activeclassname="active-atsMantra">
        <StyledButton  className="btn" active={location.pathname === '/atsmantra/' ? 'true' : undefined}  onClick={() => window.scrollTo(0, 0)}  >
          {location.pathname === '/atsmantra/' ? (  <span>- atsMantra</span> ) : ( <span>atsMantra</span> )}
        </StyledButton>
      </StyledLink>


   
      <StyledLink to="/recruitment-services/" activeclassname="active-recruitment">
        <StyledButton className='btn' active={location.pathname === '/recruitment-services/' ? 'true' : undefined} onClick={() => window.scrollTo(0, 0)}>
        {location.pathname === '/recruitment-services/' ? (  <span>-  Recruitment Services</span> ) : ( <span>  Recruitment Services</span> )}
     
        </StyledButton>
      </StyledLink>

     
      <StyledLink to="/database-design-and-development/" activeclassname="active-dbDevelopment">
        <StyledButton className='btn Database' active={location.pathname === '/database-design-and-development/' ? 'true' : undefined}
          onClick={() => window.scrollTo(0, 0)}  >
         {location.pathname === '/database-design-and-development/' ? (  <span>- Database Development</span> ) : ( <span>  Database Development</span> )}
        
        </StyledButton>
      </StyledLink>


      <StyledLink to="/application-development/" activeclassname="active-appDevelopment">
        <StyledButton className='btn Application' active={location.pathname === '/application-development/' ? 'true' : undefined} onClick={() => window.scrollTo(0, 0)}>
        {location.pathname === '/application-development/' ? (  <span>-  Application Development</span> ) : ( <span>   Application Development</span> )}
        
        </StyledButton>
      </StyledLink>

      <StyledLink to="/ui-ux-designing/" activeclassname="active-uimain">
        <LastButton className='btn' active={location.pathname === '/ui-ux-designing/' ? 'true' : undefined} onClick={() => window.scrollTo(0, 0)}>
        {location.pathname === '/ui-ux-designing/' ? (  <span>-  UI/UX Designing</span> ) : ( <span>  UI/UX Designing</span> )}
      
        </LastButton>
      </StyledLink>

    </>

  )
}

export default MainTabSection
