import React from 'react'
import styled from 'styled-components'

const Styleh2 = styled.h2`
font-family: Mulish;
font-weight: 400;
font-size: 30px;
color: #13005A;
`
const TextPara = styled.p`
color:#000000;
font-size:16px;
font-weight:400;
`

const ContactPara = () => {
  return (
    <>
      <Styleh2>Contact us</Styleh2>
      <TextPara>Have a question or comment? Let us know by filling out the form below and we'll be in touch shortly.</TextPara>

    </>
  )
}

export default ContactPara