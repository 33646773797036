import React, { useContext } from 'react'

import calender from '../../images/CalendarBlank.png';
// import companyLogo from '../../images/company-logo-img-dummy.png';
import mapPinline from '../../images/MapPinLine.png';
import clock from '../../images/Clock.png';
// import savejob from '../../images/save-job-1.png';



export default function JobProfile(props) {

    const { item, applyOnJobAllValues } = props;

    //const clientLogo = process.env.REACT_APP_CLIENT_LOGO;

    const functionJobTitle = (jobTitle, jmId, jobQuesPresent, item) => {
        applyOnJobAllValues(jmId, jobTitle, jobQuesPresent, item)
    }

    function scrollToRecommend(event) {
        event.preventDefault();
        const section = document.querySelector('#recommend');
        const navbarHeight = 80; // Replace with the actual height of your navbar
        const top = section.getBoundingClientRect().top + window.scrollY - navbarHeight;

        window.scrollTo({
            top: top,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <>
            <div className="col-lg-8 col-sm-12 col-md-8 hidein_mobile">
                <div className="d-flex">
                    <div className='logoWidth'>
                        {/* <img src={(clientLogo)+(item.client_logo)} className='logoImg' /> */}
                        {item.client_logo !== null && (<img
                         //src={(clientLogo) + (item.client_logo)}
                         src={(item.client_logo)}
                          className="logoImg" />)}
                        {item.company_initials !== null && (<div className="companyInitialsDetail">{item.company_initials}</div>)}
                    </div>
                    <div className="job__profileDiv">
                        <div className="d-flex align-items-baseline mt-1 mb-1">
                            <h3 className="job-profile">{item.job_title}<span className="ms-2 job-id">({item.job_id})</span></h3>
                        </div>
                        <p className="job-title mb-1">
                            {item.client}
                            {/* RARR Technologies in <span className="span-text-1">Development
                                IT</span> */}
                        </p>
                        <div className="d-flex  mb-1 gapbtwn" >
                            <div className="d-flex align-items-baseline  after-css">
                                <div className="position-relative pe-2" style={{ bottom: '2px' }}>
                                    <img src={calender} />
                                </div>
                                <p className="job-desc-1">{item.job_exp}</p>
                            </div>
                            <div className="d-flex align-items-baseline ">
                                <div className="position-relative pe-2" style={{ bottom: '2px' }}>
                                    <img src={clock} />
                                </div>
                                <p className="job-desc-1">{item.employment_type}</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-baseline mb-2">
                            <div className="position-relative pe-2" style={{ bottom: '2px' }}>
                                <img src={mapPinline} />
                            </div>
                            <p className="job-desc-1 " title={item.job_loc}>{item.job_loc}</p>
                        </div>
                        {/* <div>
                            <p>
                                Application ends : <span className="red-color-text"> {item.job_endt_dt}</span>
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4 hidein_mobile" >
                <div className='textAlign'>
                    <button className="btn btn-blue-2" data-bs-toggle="modal" data-bs-target="#userJobDetailModal" onClick={() => functionJobTitle(item.job_title, item.jm_id, item.job_ques_present, item)}>Apply Now</button>
                </div>
                {/* <div className="mt-3 textAlign" >
                    <a id="RecommendText" href="#recommend"> <button className="btn btn-blue-outline" onClick={scrollToRecommend} >Recommend to Friend </button></a>
                </div> */}
                <div className="mt-3 textAlign" >
                    <div>
                        <button className="btn save-job pe-none">
                            Posted  {item.job_createddt}
                        </button>
                    </div>
                </div>
            </div>


        </>
    )
}
