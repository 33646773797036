import React from "react";

// import images
import Vector from "../../images/Vector.png";
import brief from "../../images/brief.png";
import location_job_board from "../../images/location-job-board.png";
import image_removebg_preview from "../../images/image-removebg-preview (5) 2.png";
import share_img from "../../images/share-in-job-board.png";

const JobCardMobileView = (props) => {
  const { item, jobBoardApplyNowTicketJmID, openJobDetail } = props;
  //const clientLogo = process.env.REACT_APP_CLIENT_LOGO;
  const hostUI = process.env.REACT_APP_HOST_UI;

  const jobBoardJobTicketApplyOnJob = (
    jmIdValue,
    jobTitleStr,
    jobQuesPresent,
    item
  ) => {
    jobBoardApplyNowTicketJmID(jmIdValue, jobTitleStr, jobQuesPresent, item);
  };

  const handleShare = (jobId) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Example",
          text: "Check out this amazing content!",
          url: `${hostUI}/job-detail${jobId}`,
        })
        .then(() => console.log("Shared successfully."))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      console.log("Web Share API not supported.");
    }
  };

  return (
    <>
      <div className="col-sm-12 mb-2 pr-0">
        <div className="card-box" id="jobBoardjobTicket">
          <div
            onClick={() => {
              openJobDetail(item.job_unique_id);
            }}
          >
            <div className="row">
              <div
                className="col-sm-2 clientLogoMobile"
                style={{ marginRight: "10px", cursor: "pointer" }}
              >
                {item.client_logo !== null && (
                  <img
                    // src={clientLogo + item.client_logo}
                    src={item.client_logo}
                    className="ticketImg"
                    alt=""
                  />
                )}
                {item.company_initials !== null && (
                  <div className="companyInitials">{item.company_initials}</div>
                )}
              </div>
              <div className="col-sm-10 jobTitleSiteName">
                <div
                  className="col-sm-12 pr-0"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="row pl-3" style={{ cursor: "pointer" }}>
                    <div className="col-sm-12 d-flex align-items-baseline pl-0">
                      <span style={{ display: "flex", alignItems: "baseline" }}>
                        <h4
                          className="ats-card-heading-text  mb-0"
                          title={item.job_title}
                        >
                          {item.job_title}
                        </h4>

                        <p className="ats-card-position-text mb-1" style={{}}>
                          {item.no_of_position} Position(s)
                        </p>
                      </span>
                    </div>

                    <p
                      className="ats-company-name jobBoard-companynameWidth cursorPointer"
                      title={item.site_name}
                    >
                      {item.site_name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-sm-12 mb-0"
                style={{
                  paddingLeft: "3px",
                  paddingRight: "30px",
                  marginTop: "-5px",
                }}
              >
                <div>
                  <div
                    className="d-flex align-items-baseline padding-overall-left-right"
                    style={{ width: "220px" }}
                  >
                    <div>
                      <img
                        src={location_job_board}
                        className="imgMobileView"
                        alt=""
                      />
                    </div>
                    <p
                      className="ats-text-info jobBoard-elliplis"
                      title="`+val.preferred_location+`"
                    >
                      {item.preferred_location}
                    </p>
                  </div>
                  <div className="d-flex align-items-baseline padding-overall-left-right">
                    <div>
                      <img src={brief} className="imgMobileView" alt="" />
                    </div>
                    <p className="ats-text-info">
                      {item.job_exp} ( {item.employment_type} )
                    </p>
                  </div>
                  <div
                    className="d-flex align-items-baseline padding-overall-left-right"
                    style={{ width: "220px" }}
                  >
                    <div>
                      <img
                        src={image_removebg_preview}
                        className="imgMobileView"
                        alt=""
                      />
                    </div>
                    <p className="ats-text-info jobBoard-elliplis" title="">
                      {item.skill_name}
                    </p>
                  </div>
                  <span className="d-flex justify-content-between">
                    <div className="d-flex align-items-baseline padding-overall-left-right">
                      <div>
                        <img src={Vector} className="imgMobileView" alt="" />
                      </div>
                      <p className="ats-text-info jobBoard-elliplis">
                        0 - {item.joining_period} Joiners
                      </p>
                    </div>

                    <button className="float-end jobCreateDateMobileView">
                      {item.job_createddt}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button
                type="button"
                className="btn applyNow btn-primary"
                style={{ width: "90px" }}
                onClick={() =>
                  jobBoardJobTicketApplyOnJob(
                    item.jm_id,
                    item.job_title,
                    item.job_ques_present,
                    item
                  )
                }
                data-bs-toggle="modal"
                data-bs-target="#userJobDetailModal"
              >
                Apply Now
              </button>
            </div>
            {/* <div
              className="recommendToFrMobileView"
              data-bs-toggle="modal"
              data-bs-target="#userRecommendFriend1"
              onClick={() =>
                jobBoardJobTicketApplyOnJob(
                  item.jm_id,
                  item.job_title,
                  item.job_ques_present,
                  item
                )
              }
            >
              Recommend to Friend
            </div> */}

            <div onClick={() => handleShare(item.job_unique_id)}>
              <img src={share_img} className="imgMobileView" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobCardMobileView;
