import React from 'react'
import styled from 'styled-components'

// import images
import Bglocation from '../../../../images/locationBlack.png'
import BgPhone from '../../../../images/PhoneBlack.png'
import Bgenvelope from '../../../../images/EnvelopeBlack.png'

const Styleh2 = styled.h2`
font-family: Mulish;
font-weight: 400;
font-size: 30px;
color: #13005A;

`
const StyledImageOne = styled.img`
width: 15px;
    height: 20px;

`
const StyledImageTwo = styled.img`
width: 20px;
    height: 20px;

`
const StyledImageThree = styled.img`
width: 26px;
    height: 20px;

`
const StyledFlex = styled.div`
display:flex;
border-bottom: 1px solid #00337C;
padding-bottom: 20px;
    padding-top: 15px;
`
const StyledText = styled.p`
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px;
margin-bottom:0px;

`
const OurContactInfo = () => {
  return (
    <>
      <Styleh2>Our contact info</Styleh2>
      <StyledFlex>
        <StyledImageOne src={Bglocation} alt="Location"/>
        <StyledText className="ms-2">119, 1st Floor Vardhmaan Plaza, Sector-19, Faridabad, Haryana-121002</StyledText>
      </StyledFlex>
      <StyledFlex>
        <StyledImageTwo src={BgPhone} alt="Phone" />
        <StyledText className="ms-1">+91-129-488 2321</StyledText>
      </StyledFlex>
      <StyledFlex>
        <StyledImageThree src={Bgenvelope} alt="Email" />
        <StyledText className="ms-1">info@rarrtech.com</StyledText>
      </StyledFlex>



    </>
  );
};

export default OurContactInfo