import React from "react";
import FormatDate from "../FormatDate";

const BlogDetailCards = (props) => {
  const { item, openBlogDetail1 } = props;
  const metaTag = item.meta_tag.split(",");
  const metaTagColor = item.color_names.split(",");
  const blogPath = process.env.REACT_APP_BLOG_PATH;

  const encryptFunction5 = (blogUniqueUrl, metaDescription, metaTag) => {
    openBlogDetail1(blogUniqueUrl, metaDescription, metaTag);
  };

  return (
    <>
      <div className="col-lg-4">
        <div className="userCarddetails">
          <div
            className="blog-img-1"
            onClick={() =>
              encryptFunction5(
                item.blog_unique_url,
                item.meta_description,
                item.meta_tag
              )
            }
            style={{ backgroundImage: "url(" + item.blog_image + ")" }}
          >
            <div className="meta-date text-center p-2">
              <FormatDate date={item.post_date} />
            </div>
          </div>
          <h6
            className="blogcard-name"
            title="Blog Category"
            style={{ cursor: "auto" }}
          >
            {item.blog_type}
          </h6>
          <h1
            className="blogcard-title"
            onClick={() =>
              encryptFunction5(
                item.blog_unique_url,
                item.meta_description,
                item.meta_tag
              )
            }
            title={item.title}
          >
            {item.title}
          </h1>
          <div className="col-lg-12  mb-2">
            <div className="SkillOverflow">
              {metaTag.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="skillDiv me-2"
                    style={{ background: `${metaTagColor[i]}` }}
                  >
                    <p className="skillName">{item}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row blog-user-profile mb-0">
            <div className="col-lg-10">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="userImgcol">
                  {
                    <img
                      src={`${blogPath + item.user_image}`}
                      alt={item.author}
                      className={`userProfile-detail ${
                        item.user_image ? "" : "d-none"
                      }`}
                    />
                  }
                  {
                    <h3
                      className={`userProfile ${
                        item.author_initials ? "" : "d-none"
                      }`}
                    >
                      {item.author_initials}
                    </h3>
                  }
                </div>
                <p className="mb-0 ms-1">{item.author}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailCards;
