import React from 'react';
import styled from 'styled-components';
import Tick from "../../../images/Group 14247.png";
const StyledHeading = styled.h2`
color: #000;
font-size: 28px;
font-family: Mulish;
font-style: normal;
font-weight: 700;
line-height: normal;
`;
const StyledImage = styled.img`
width: 20px;
    height: 20px;
`;
const StyledImageWrapper = styled.div`

`;
const StyledText = styled.h2`
color: #000;
font-size: 16px;
font-family: Mulish;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 10px;
margin-bottom : 18px ;
`;
const StyledFlex = styled.div`
display:flex;
align-items: flex-start;

`;
const Row = styled.div``;
const Column = styled.div``;
export const CoconutServices = () => {
    const data = [
        {

            text: 'Role-Based Access.',
        },
        {

            text: 'Complete Security',
        },
        {

            text: 'Ease of Access to Service.',
        },
        {

            text: 'Multi-Tenancy - Multiple Companies hosted in the same environment.',
        },
        {

            text: 'Affordable Cloud-Based ERP solution available to customers on subscription (per user) or perpetual license model.',
        },
    ];
    const dataOne = [
        {

            text: 'Pin Board.',
        },
        {

            text: 'Notification Engine.',
        },
        {

            text: 'Email / SMS.',
        },
        {

            text: 'E-Invoice / E-Way Bill.',
        },
        
    ];
    return (
        <>
            <StyledImageWrapper>
                <Row className="row">
                    <Column className="col-lg-7">
                    {data.map((item, index) => (
                        <StyledFlex>
                            <StyledImage src={Tick} alt="Tick" />
                            <StyledText>{item.text}</StyledText>
                        </StyledFlex>
                    ))}
                    </Column>
                    <Column className="col-lg-5">
                    {dataOne.map((item, index) => (
                        <StyledFlex>
                            <StyledImage src={Tick} alt="Tick" />
                            <StyledText>{item.text}</StyledText>
                        </StyledFlex>
                    ))}
                    </Column>
                </Row>
            </StyledImageWrapper>
        </>
    )
}
