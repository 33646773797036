import React, { useContext, useState } from "react";
import JobProflie from "./JobProfile";
import JobSkill from "./JobSkill";
import JobDescription from "./JobDescription";
// import Recommend from "./Recommend";
import CompanyProfile from "./CompanyProfile";
import MatchingJob from "./MatchingJob";
import LoaderGif from "../jobBoard/LoaderGif";
import CreatContext from "../../context/CreatContext";
import ProflieMoblie from "./ProflieMoblie";
import UserRecommendFriend from "../jobBoard/UserRecommendFriend";
import UserJobDetailModal from "../jobBoard/UserJobDetailModal";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import NotFound from "../../images/NotFound.png";

import "../../style/JobDetail.css";
import { Link } from "react-router-dom";

export default function JobDetail() {
  const context = useContext(CreatContext);
  const {
    jmIdInfo,
    companyProfileSiteID,
    matchingJOb,
    applyNowForJobTicket,
    getLocationDetailsByJmId,
    showSorryDetail,
    setQuestionFlag,
    statusCheck,
    setCompleteJobDetail,
  } = context;

  const [jobTitle, setJobTitle] = useState("");
  const [matchingJmid, setMatchingJmID] = useState("");
  const [slidePage, setSlidePage] = useState(1);

  // const messageWhatsAppShare = jmIdInfo.map((item, i) => {

  //   const message = `\*Job Title:\* ${item.job_title}\n\*Experience:\* ${item.job_exp}\n\*Location(s):\* ${item.job_loc}\n\*Skills:\* ${item.job_skills}\n\n\*Apply here\* \n${window.location.href}`;

  //   const encodedString = encodeURIComponent(message);
  //   return (
  //     encodedString
  //   )
  // });

  const applyOnJobAllValues = (jmId, jobTitle, jobQuesPresent, item) => {
    setMatchingJmID(jmId);
    setJobTitle(jobTitle);
    setQuestionFlag(jobQuesPresent);
    getLocationDetailsByJmId(jmId);
    setCompleteJobDetail(item);
  };

  const openJobDetailMatch = (jobEncrypt) => {
    window.open(`/job-detail${jobEncrypt}`, `/job-detail${jobEncrypt}`);
  };

  const handleChangeMatchingJob = (changeBtn) => {
    if (changeBtn === "pre") {
      const getSlidePage = slidePage - 1;
      if (getSlidePage > 0) {
        setSlidePage(getSlidePage);
      } else {
        setSlidePage(1);
      }
    } else if (changeBtn === "next") {
      const getSlidePage = slidePage + 1;
      if (getSlidePage < matchingJOb.length) {
        setSlidePage(getSlidePage);
      } else {
        setSlidePage(matchingJOb.length);
      }
    }
  };

  const jobBoardApplyJobTicket = (
    fname,
    lname,
    mobile,
    email,
    currentLoc,
    preferred_locString,
    currentCTC,
    expectedCTC,
    skillString,
    remarks,
    candidateExperience,
    relevantExperience,
    applyJobResumeFile,
    preferredLocation,
    date,
    noticePeriod,
    servingNoticePeriod,
    prefferedLocLmID
  ) => {
    applyNowForJobTicket(
      matchingJmid,
      fname,
      lname,
      mobile,
      email,
      currentLoc,
      preferred_locString,
      currentCTC,
      expectedCTC,
      skillString,
      remarks,
      candidateExperience,
      relevantExperience,
      applyJobResumeFile,
      preferredLocation,
      date,
      noticePeriod,
      servingNoticePeriod,
      prefferedLocLmID
    );
  };

  return (
    <>
      <div
        style={{
          marginTop: "80px",
        }}
      >
        {/* {loader && <LoaderGif />} */}
        {statusCheck === "not ok" && <LoaderGif />}

        {showSorryDetail === 0 ? (
          <div>
            <div>
              <div className="d-flex justify-content-center align-item-center">
                <div>
                  <img src={NotFound} style={{ width: "90%" }} alt="" />
                  <div>
                    <div className="d-flex justify-content-center align-item-center">
                      <h4 style={{ fontSize: "25px", fontWeight: "600" }}>
                        Oops! Job not found on the provided URL.
                      </h4>
                    </div>
                  </div>
                  <h4 className="text-center">
                    <Link to="/current-job-opening">
                      <button className="btn btn-explore">
                        Explore more jobs
                      </button>
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ) : (
          jmIdInfo.length > 0 && (
            <div className="container mt-5 jobdetailContainer">
              <UserJobDetailModal
                jobBoardApplyJobTicket={jobBoardApplyJobTicket}
                jobTitle={jobTitle}
                applyNowJmId={matchingJmid}
              />
              <div className="row">
                <div className="col-lg-8 mt-2">
                  <div className="row">
                    {jmIdInfo.map((item, i) => {
                      return (
                        <JobProflie
                          key={i}
                          item={item}
                          applyOnJobAllValues={applyOnJobAllValues}
                        />
                      );
                    })}
                    {jmIdInfo.map((item, i) => {
                      return (
                        <ProflieMoblie
                          key={i}
                          item={item}
                          applyOnJobAllValues={applyOnJobAllValues}
                        />
                      );
                    })}
                  </div>
                  {/* <div>
                  <div className="webViewSocialShare" style={{ position: 'fixed', right: '0%', top: '50%', zIndex: '10' }}>
                    <SocialShare url={`${messageWhatsAppShare}`} title="" />
                  </div>
                  <div className="mobileViewSocialShareD tag-box">
                    <SocialShare url={`${messageWhatsAppShare}`} title="" />
                  </div>
                </div> */}
                  <section id="job-skills">
                    {jmIdInfo.map((item, i) => {
                      return <JobSkill key={i} item={item} />;
                    })}
                  </section>
                  <section
                    id="job-description"
                    className="mt-5 border-bottom-1"
                  >
                    {jmIdInfo.map((item, i) => {
                      return <JobDescription key={i} item={item} />;
                    })}
                  </section>
                  {/* <section id="recommend" className="mt-4 border-bottom-1">
                  <Recommend />
                </section> */}
                </div>
                <div className="col-lg-4">
                  <section>
                    {companyProfileSiteID.map((item, i) => {
                      return <CompanyProfile key={i} item={item} />;
                    })}
                  </section>
                  <section className="mt-4 mb-4">
                    <UserRecommendFriend
                      jobTitle={jobTitle}
                      applyNowJmId={matchingJmid}
                    />
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h4 className="similar-matching-heading">
                        Similar Matching Jobs
                      </h4>
                      <div style={{ fontSize: "13px", color: "black" }}>
                        <ArrowLeftIcon
                          style={{
                            color: `${
                              slidePage === 1 || matchingJOb.length === 0
                                ? "#ccc"
                                : "black"
                            }`,
                            cursor: `${
                              slidePage === 1 || matchingJOb.length === 0
                                ? ""
                                : "pointer"
                            }`,
                            marginTop: "-2px",
                          }}
                          onClick={() => handleChangeMatchingJob("pre")}
                        />
                        Showing {matchingJOb.length > 0 ? slidePage : 0} of{" "}
                        {matchingJOb.length}
                        <ArrowRightIcon
                          style={{
                            color: `${
                              slidePage === matchingJOb.length ||
                              matchingJOb.length === 0
                                ? "#ccc"
                                : "black"
                            }`,
                            cursor: `${
                              slidePage === matchingJOb.length ||
                              matchingJOb.length === 0
                                ? ""
                                : "pointer"
                            }`,
                            marginTop: "-2px",
                          }}
                          onClick={() => handleChangeMatchingJob("next")}
                        />
                      </div>
                    </div>

                    {matchingJOb.length > 0 ? (
                      <div id="similar-matching">
                        {matchingJOb
                          .filter((item, i) => {
                            if (i === `${slidePage}` - 1) {
                              return true;
                            } else return false;
                          })
                          .map((item, i) => {
                            return (
                              <MatchingJob
                                key={i}
                                item={item}
                                openJobDetailMatch={openJobDetailMatch}
                                applyOnJobAllValues={applyOnJobAllValues}
                              />
                            );
                          })}
                      </div>
                    ) : (
                      <>
                        <div className="card cardShadow">
                          <div className="card-body ">
                            <h3 className="matchJob text-center mb-0">
                              {" "}
                              No Similar Matching Job found
                            </h3>
                          </div>
                        </div>
                      </>
                    )}
                  </section>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}
