import React , {useState} from 'react'
import styled from 'styled-components';
import Server from "../../images/azure11.jpg";
import Microsoft from "../../images/dynamic1.png";
import Microsoft365 from "../../images/mic365.jpg";
import Azure from './Azure';
import Dynamic from "./Dynamic";
import MicrosoftThree from "./MicrosoftThree";

const RadioTabs = styled.div``
const Tabs = styled.div``
const Panels = styled.div``
const PanelsChild = styled.div``
const StyledInput = styled.input``
const StyledLabel = styled.label``
const TabLabel = styled.h3`
margin-bottom: 0px;
`
const StyledSpan = styled.span`
    display: flex;
    align-items: center;
`
const StyledImg = styled.img`
    height: 22px;
    margin-right: 2px;
    mix-blend-mode: darken;
`


const MicrosoftContent = () => {

    
  const [radioButtonCheckMicrosoft, setRadioButtonCheckMicrosoft] = useState('Azure');
  const [displayRadioButtonMicrosoft, setDisplayRadioButtonMicrosoft] = useState('Azure');


  const onChangeRadioButton = (e) => {
    setRadioButtonCheckMicrosoft(e.target.value);
  }

  const onCickDisplayRadio = (data) => {
    setDisplayRadioButtonMicrosoft(data);
  }

    return (
        <>
             <RadioTabs className="radio-tabs">
        <div className='col-lg-10'>
          <StyledInput value="Azure" checked={radioButtonCheckMicrosoft === 'Azure'} onChange={(e) => onChangeRadioButton(e)} hidden={true}
            className="state"
            type="radio"
            title="Input1"
            name="input-state"
            id="radio1"
           
          />
          <StyledInput value="Dynamic" checked={radioButtonCheckMicrosoft === 'Dynamic'} onChange={(e) => onChangeRadioButton(e)}
            className="state" hidden={true}
            type="radio"
            title="Input2"
            name="input-state"
            id="radio2"
          />
          <StyledInput value="Microsoft" checked={radioButtonCheckMicrosoft === 'Microsoft'} onChange={(e) => onChangeRadioButton(e)}
            className="state" hidden={true}
            type="radio"
            title="Input3"
            name="input-state"
            id="radio3"
          />
         
          <Tabs className="tabs">
            <StyledLabel htmlFor="radio1" id="first-tab" className="tab" onClick={() => onCickDisplayRadio('Azure')}>

              <StyledSpan >
              <StyledImg src={Server}   alt="Server"/>
                <TabLabel className="tab-label">Microsoft Azure Cloud Platform</TabLabel>
              </StyledSpan>

            </StyledLabel>
            <StyledLabel htmlFor="radio2" id="second-tab" className="tab" onClick={() => onCickDisplayRadio('Dynamic')}>

              <StyledSpan  >
              <StyledImg  src={Microsoft365} alt="Microsoft365" ></StyledImg>
                <TabLabel className="tab-label">Microsoft Dynamics 365 </TabLabel>
              </StyledSpan>

            </StyledLabel>
            <StyledLabel htmlFor="radio3" id="third-tab" className="tab" onClick={() => onCickDisplayRadio('Microsoft')}>

              <StyledSpan >
              <StyledImg src={Microsoft}  alt="Microsoft" ></StyledImg>
                <TabLabel className="tab-label">Microsoft 365</TabLabel>
              </StyledSpan>

            </StyledLabel>
           
          </Tabs>
        </div>
        <div className='col-lg-12'>
          <Panels className="panels">
            <PanelsChild id="first-panel" className={`${displayRadioButtonMicrosoft === 'Azure' ? '' : 'd-none'} panel active animated slideInRight`}>
              <Azure />
          
            </PanelsChild>
            <PanelsChild id="second-panel" className={`${displayRadioButtonMicrosoft === 'Dynamic' ? '' : 'd-none'} panel active animated slideInRight`}>
              <Dynamic />
            </PanelsChild>
            <PanelsChild id="third-panel" className={`${displayRadioButtonMicrosoft === 'Microsoft' ? '' : 'd-none'} panel active animated slideInRight`}>
              {/* <WorkExp /> */}
             <MicrosoftThree />
            </PanelsChild>
           
          </Panels>
        </div>
      </RadioTabs>

 
       
        </>
    )
}

export default MicrosoftContent
