import React from 'react'
import { styled } from 'styled-components';
import { MainBanner } from '../Subpages/components/MainBanner';
import MainTabSection from '../UI/UXdesign/MainTabSection';
import { MainHeading } from '../Subpages/components/MainHeading';
import MicrosoftContent from './MicrosoftContent';
import {ContactSection } from "../Subpages/components/ContactSection" ;

const Section = styled.section`
font-family : "Mulish"
`;
const Container = styled.div``

const Row = styled.div``

const SecondDiv = styled.div`
display : flex ;
justify-content : center ;
color : white ;
font-size : 16px ;
font-weight : 700 ;
`

const Heading = styled.h1`
color: white;
font-size: 40px;
font-weight: 700;`

const HeadingH6 = styled.h6 ``

const LeftDiv  = styled.div`
order: 2;
@media (min-width: 991px) {
  order: 1;
}
`
const RightDiv = styled.div`
  order: 1;

  @media (min-width: 991px) {
    order: 2;
  }
`;


const Microsoft = () => {
  return (
    <>
    <section>
    <MainBanner name="Microsoft Solutions" pageName="Microsoft Solutions" />
    <Container className='container mb-3'>
               <Row className="row">
                  <LeftDiv className='col-lg-3'> 
                  <div className='fixedSection'>
                     <MainTabSection/>
                     <ContactSection />
                   </div>
                  </LeftDiv>
                  <RightDiv className='col-lg-9'>
                  
                   <MainHeading name="Microsoft Solutions"/>
                    <MicrosoftContent/>
                   </RightDiv>
               </Row>
            </Container>
    </section>
    </>
  )
}

export default Microsoft;
