import React, { useContext } from 'react'
import styled from 'styled-components';
import CreatContext from "../../../context/CreatContext";
import { MainHeading } from '../../Subpages/components/MainHeading';
import { Link } from 'react-router-dom';

// images_import
import SaasDevelopmentImg from '../../../images/SaasDevelopment.png';
import FrontEndImg from '../../../images/Front-end.png';
import BackEndImg from '../../../images/Back-end.png';
import ProgressiveImg from '../../../images/Progressive.png';
import DigitalImg from '../../../images/Digital.png';

// css_components
const Container = styled.div``
const Stylepara = styled.p`
font-size:16px;
font-weight:400;
line-height:normal;
margin-bottom:40px;
`
const Img = styled.img`
margin-bottom : 11px ;
`
const Row = styled.div`
margin-top:27px;
margin-bottom:27px;
`

const Heading = styled.h2`
font-size : 18px ;
color : black ;
font-weight : 700 ;
font-family : 'Mulish'
`
const COl = styled.div``
const COlButton = styled.div`
text-align: center;
margin-bottom : 70px ;
`
const Startbttn = styled(Link)`
background:#00337C;
color:#fff;
font-size:18px;
border-radius:10px;
text-decoration : none ;
padding: 10px 60px;
border:1px solid #00337C;
`;

const ContentDBdevelopment = () => {
    const context = useContext(CreatContext);
    const { activeLink, setActiveLink } = context;
    return (
        <Container>
            <Stylepara>You can easily trust the expert team at RARR Technologies Pvt Ltd for all your Database Design and Development. Our dedicated team is here to empower your business with efficient and scalable database solutions tailored to your unique needs. With our comprehensive range of services, we ensure that your data management processes are optimized for maximum productivity and effectiveness.</Stylepara>

            <MainHeading name='Our Database Design and Development Services' />

            <Row className='row'>
                <COl className='col-lg-6'>
                    <Img src={SaasDevelopmentImg}  alt="SaasDevelopment"  style={{ paddingTop: '17px' }}></Img>
                    <Heading>Database Design:</Heading>
                    <Stylepara>we believe that a well-designed database is the foundation of a successful business. Our experienced team of database architects excels in creating robust and flexible database structures that align perfectly with your business requirements. We take into account factors such as data volume, complexity, and future growth to design a scalable database that can evolve with your organization. </Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={FrontEndImg} alt="FrontEnd"></Img>
                    <Heading>Database Development:</Heading>
                    <Stylepara>Our skilled developers possess deep expertise in database development across various platforms, including Oracle, SQL Server, MySQL, and MongoDB, among others. Leveraging the latest industry standards and best practices, we build high-performance databases that efficiently store, retrieve, and manage your valuable data. From schema design to query optimization, we ensure that your database operates seamlessly, delivering superior performance.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={BackEndImg} alt="BackEnd"></Img>
                    <Heading>Data Modeling:</Heading>
                    <Stylepara>Efficient data modeling is essential for businesses aiming to unlock the full potential of their data. Our data modeling experts employ cutting-edge techniques and tools to analyze and structure your data, ensuring logical organization and smooth data flow within your database system. By leveraging our expertise, you can gain insights from your data quickly, make informed decisions, and gain a competitive edge in your industry.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={ProgressiveImg} alt="Performance"></Img>
                    <Heading>Performance Optimization:</Heading>
                    <Stylepara>A slow or inefficient database can hinder your business operations and impact user experience. Our team specializes in performance optimization, fine-tuning your database to deliver optimal speed and responsiveness. Through various techniques such as index optimization, query optimization, and caching mechanisms, we enhance your database's performance, enabling you to process data faster and improve overall system efficiency.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={DigitalImg} style={{ paddingTop: '16px' }} alt="Migration"></Img>
                    <Heading>Migration and Integration:</Heading>
                    <Stylepara>Efficient data modeling is essential for businesses aiming to unlock the full potential of their data. Our data modeling experts employ cutting-edge techniques and tools to analyze and structure your data, ensuring logical organization and smooth data flow within your database system. By leveraging our expertise, you can gain insights from your data quickly, make informed decisions, and gain a competitive edge in your industry.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={ProgressiveImg} alt="DatabaseSecurity"></Img>
                    <Heading>Database Security:</Heading>
                    <Stylepara>A slow or inefficient database can hinder your business operations and impact user experience. Our team specializes in performance optimization, fine-tuning your database to deliver optimal speed and responsiveness. Through various techniques such as index optimization, query optimization, and caching mechanisms, we enhance your database's performance, enabling you to process data faster and improve overall system efficiency.</Stylepara>
                </COl>
            </Row>

            <Row>
                <COlButton className='col-lg-12'>
                    <Startbttn to="/contact" onClick={() => { setActiveLink("contact"); window.scrollTo(0, 0) }} >Start a Conversation</Startbttn>
                </COlButton>
            </Row>
        </Container>
    )
};

export default ContentDBdevelopment