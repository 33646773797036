import React, { useState, useRef, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";

const MultiTagSuggestionDropDown = (props) => {
  const {
    options,
    labelToShow,
    labelToStore,
    onTag,
    setOnTag,
    placeholder,
    onSearch,
    clearSkillsValue,
    limit = 0,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [isOpenData, setIsOpenData] = useState(false);
  const [valueToShowOption, setValueToShowOption] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const boxRef = useRef();
  const inputRef = useRef(null);
  const dropDownListRef = useRef(null);

  const removeTags = (e, indexToRemove) => {
    setOnTag([
      ...onTag.filter((item, index) => {
        return index !== indexToRemove;
      }),
    ]);
    setValueToShowOption([
      ...valueToShowOption.filter((item, index) => {
        return index !== indexToRemove;
      }),
    ]);
  };

  const addTags = (event, valueShow, valueStore) => {
    setSelectedIndex(null);
    if (limit === 0) {
      setOnTag([
        ...onTag.filter((item, i) => {
          return item !== valueStore;
        }),
        valueStore,
      ]);
      setValueToShowOption([
        ...valueToShowOption.filter((item, index) => {
          return item !== valueShow;
        }),
        valueShow,
      ]);
      setSearchValue("");
      inputRef.current.focus();
    } else if (valueToShowOption.length < limit) {
      setOnTag([
        ...onTag.filter((item, i) => {
          return item !== valueStore;
        }),
        valueStore,
      ]);
      setValueToShowOption([
        ...valueToShowOption.filter((item, index) => {
          return item !== valueShow;
        }),
        valueShow,
      ]);
      setSearchValue("");
      inputRef.current.focus();
    } else if (valueToShowOption.length === limit) {
      Swal.fire({
        title: `Only ${limit} selection allowed.`,
        icon: "warning",
      });
      return false;
    }
  };

  const onSearchCahnge = (e) => {
    setSearchValue(e.target.value);
    onSearch(e.target.value);
    scrollToTopList();
    setSelectedIndex(null);
  };

  useEffect(() => {
    setValueToShowOption([]);
  }, [clearSkillsValue]);

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setIsOpenData(false);
      setSearchValue("");
      setSelectedIndex(null);
    }
  };

  const firstSpaceValidation = (event) => {
    const len = event.target.value.length;
    if (len === 0 && event.charCode === 32) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const scrollToTopList = () => {
    const dropDownList = document.querySelector(".dropDownList");
    if (dropDownList) {
      dropDownList.scrollTo(0, 0);
    }
  };

  // Function to handle keyboard navigation
  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex === null ? 0 : Math.min(prevIndex + 1, options.length - 1)
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex === null ? 0 : Math.max(prevIndex - 1, 0)
      );
    } else if (event.key === "Enter" && selectedIndex !== null) {
      event.preventDefault();
      const selectedOption = options[selectedIndex];
      addTags(event, selectedOption[labelToShow], selectedOption[labelToStore]);
    }
  };

  useEffect(() => {
    if (isOpenData) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, isOpenData]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Scroll to selected index if it's not visible
    const dropDownList = dropDownListRef.current;
    const selectedItem =
      dropDownList && dropDownList.querySelector(".dropDownListItemSelected");
    if (selectedIndex !== null && selectedItem) {
      selectedItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [selectedIndex]);

  return (
    <>
      <div className="multiSelectInputTag" ref={boxRef}>
        <div className="multitags-inputOther">
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <ul id="multitags" onClick={scrollToTopList}>
              {valueToShowOption.map((tag, index) => {
                return (
                  <li key={index} className="multitag">
                    <span
                      className="multitag-title"
                      title={tag}
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {tag}
                    </span>
                    <span onClick={(e) => removeTags(e, index)}>
                      <CancelIcon
                        style={{
                          color: "white",
                          fontSize: "16px",
                          marginRight: "0px",
                          marginLeft: "5px",
                        }}
                        className="multitag-close-icon"
                      />
                    </span>
                  </li>
                );
              })}
            </ul>
            <input
              type="text"
              id="fltLocation"
              autoComplete="off"
              onKeyPress={firstSpaceValidation}
              value={searchValue}
              ref={inputRef}
              onChange={onSearchCahnge}
              placeholder={valueToShowOption.length === 0 ? placeholder : ""}
              className={`multiInput multiInputSelect`}
              onFocus={() => setIsOpenData(true)}
              onClick={() => {
                setSelectedIndex(null);
                setIsOpenData(true);
                scrollToTopList();
              }}
              style={{ padding: "8px 13px", borderRadius: "8px" }}
            />
          </div>
        </div>

        {searchValue && (
          <div htmlFor="fltLocation">
            <div
              className={`dropDownList ${
                isOpenData === true ? "" : "displayNone"
              }`}
              ref={dropDownListRef}
            >
              {options
                // .filter((option) => {
                //   if (searchValue === "") return option;
                //   else if (
                //     option[labelToShow]
                //       .toLowerCase()
                //       .includes(searchValue.toLowerCase())
                //   )
                //     return option;
                //   else return false;
                // })
                // .filter((option, i) => {
                //   const value1 = option[labelToShow];
                //   let isTrue = false;
                //   valueToShowOption.map((item) => {
                //     if (value1 === item) {
                //       isTrue = true;
                //     }
                //     return false;
                //   });
                //   return !isTrue;
                // })
                .map((option, i) => {
                  const valueShow = option[labelToShow];
                  const valueStore = option[labelToStore];
                  return (
                    <div
                      key={i}
                      onClick={(e) => addTags(e, valueShow, valueStore)}
                      onMouseMove={(e) => setSelectedIndex(i)}
                      className={`dropDownListItemSelect ${
                        selectedIndex === i
                          ? "dropDownListItemSelected"
                          : "dropDownListItemNotSelected"
                      }`}
                    >
                      {valueShow}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MultiTagSuggestionDropDown;
