import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import CreatContext from "../../../context/CreatContext";
import Prototype from "../../../images/Prototype.png";
import wireframes from "../../../images/wireframes.png";
import Interface from "../../../images/Interface.png";
import softwareRedesign from "../../../images/softwareRedesign.png";
import technicaldesign from "../../../images/technicaldesign.png";
import crossPlatform from "../../../images/crossPlatform.png";
import Marketing from "../../../images/Marketing.png";
import Presentation from "../../../images/Presentation.png";
import Functional from "../../../images/Functional.png";
import Frontend from "../../../images/Frontend.png";
import UItesting from "../../../images/UItesting.png";


const Row = styled.div``
const Maindiv = styled.div`
display : flex ;
`
const Column = styled.div`
margin-bottom : 13px ;
`
const Column1 = styled.div`
margin-top : 20px ;
&:last-child {
   margin-bottom : 30px ;
}
`
const ButtonColumn = styled.div`
margin : 20px 0px ;
text-align : center ;
`
const Img = styled.img`
margin-bottom : 5px ;
`
const Heading = styled.h2`
font-size : 18px ;
color : black ;
font-weight : 700 ;
font-family : 'Mulish'
`
const ServicesHeading = styled.h1`
color: #000;
font-size: 24px;
font-family: Mulish;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top : 30px;
`
const Para = styled.p`
font-size : 14px ;
color : black ;
`
const Image = styled.img`  
height: 75px;
width : 75px ;
margin-right : 15px ;
`
const Conversation = styled(Link)`
 background-color : #00337C;
 padding : 10px 60px;
 border-radius : 15px ;
 font-size: 18px;
 color : #fff ;
 text-decoration : none ;
 border: 1px solid #00337C;
`


const UIdesign = () => {
   const context = useContext(CreatContext);
   const { activeLink, setActiveLink } = context;
   return (
      <>
         <Row className="row mt-4">
            <Column className='col-lg-6 '>
               <Img src={Prototype} alt="Prototype"></Img>
               <Heading>High-fidelity Prototype</Heading>
               <Para>We create a prototype to demonstrate the behavior of the application and show how it would work in reality. We design fully-interactive prototypes to give you an idea of the workflow of your project.</Para>
            </Column>
            <Column className='col-lg-6 '>
               <Img src={wireframes} alt="wireframes"></Img>
               <Heading>Wireframes</Heading>
               <Para>We design wireframes to provide you an outline of a mobile or web app by demonstrating content, structure and functionality that will exist on your app’s and software’s pages.</Para>
            </Column>
            <Column className='col-lg-6'>
               <Img src={Interface} alt="Interface"></Img>
               <Heading>Mobile & Web User Interface Design</Heading>
               <Para>We deliver our best efforts to ingeniously convert your ideas and thoughts into a clear and fully functional design. We design user interfaces that conform to what the user requires and understands.</Para>
            </Column>
            <Column className='col-lg-6'>
               <Img src={crossPlatform} alt="crossPlatform"></Img>
               <Heading>Cross-Platform Compatibility</Heading>
               <Para>Focusing on the core principles of UI design and functionality implementation, we design and deliver responsive and cross-platform compatible designs to ensure that it works across different platforms.</Para>
            </Column>
            <Column className='col-lg-6'>
               <Img src={technicaldesign} alt="technicaldesign"></Img>
               <Heading>Technical Design</Heading>
               <Para>Our team can also create a technical design that helps our clients understand the technical workflow of the project. In technical design, we define technology stack for all the components of the app, third-party integrations and interactions of services and APIs.</Para>
            </Column>
            <Column className='col-lg-6'>
               <Img src={softwareRedesign} alt="softwareRedesign"></Img>
               <Heading>Software Redesign</Heading>
               <Para>If you already have an app or a website but it does not look appealing or professional, we can help you by re-designing the entire website or application. After evaluating your app and understanding the users, we make your software look engaging and beautiful.
               </Para>
            </Column>
            <ButtonColumn className="col-lg-12">
               <Conversation to="/contact" onClick={() => { setActiveLink("contact"); window.scrollTo(0, 0) }} >Start a Conversation</Conversation>
            </ButtonColumn>
            <ServicesHeading>Our approach to providing UI/UX Design Services</ServicesHeading>
            <Column1 className="col-lg-12">
               <Maindiv>
                  <Image src={Marketing} alt="Marketing"></Image>
                  <div>
                     <Heading>Market Research & Analysis</Heading>
                     <Para>We initiate the UX process by collecting, analyzing and defining requirements to understand user desires and goals.</Para>
                  </div>
               </Maindiv>
            </Column1>
            <Column1 className="col-lg-12">
               <Maindiv>
                  <Image src={Presentation} alt="Presentation"></Image>
                  <div>
                     <Heading>Concept Presentation</Heading>
                     <Para>By defining the core features of the project and developing information architecture, we present the concept of the project.</Para>
                  </div>
               </Maindiv>
            </Column1>
            <Column1 className="col-lg-12">
               <Maindiv>
                  <Image src={Interface}  alt="Wireframes"></Image>
                  <div>
                     <Heading>UI/UX Wireframes</Heading>
                     <Para>We prototype the structure of the entire app or website, revealing what should be present on the app pages.</Para>
                  </div>
               </Maindiv>
            </Column1>
            <Column1 className="col-lg-12">
               <Maindiv>
                  <Image src={Functional}  alt="Functional"></Image>
                  <div>
                     <Heading>Fully Functional UX/UI</Heading>
                     <Para>Once the client approves wireframes, we create a fully functional UI/UX for your application.</Para>
                  </div>
               </Maindiv>
            </Column1>
            <Column1 className="col-lg-12">
               <Maindiv>
                  <Image src={Prototype}  alt="Prototype"></Image>
                  <div>
                     <Heading>Prototype Testing</Heading>
                     <Para>We provide early-stage opportunities to clients to test the functionality of UI/UX before creating the final designs.</Para>
                  </div>
               </Maindiv>
            </Column1>
            <Column1 className="col-lg-12">
               <Maindiv>
                  <Image src={Frontend}  alt="Frontend"></Image>
                  <div>
                     <Heading>Front-end Development</Heading>
                     <Para>Once the designs are ready, designs are moved into the front-end development process.</Para>
                  </div>
               </Maindiv>
            </Column1>
            <Column1 className="col-lg-12">
               <Maindiv>
                  <Image src={UItesting}  alt="UItesting"></Image>
                  <div>
                     <Heading>UI Testing Process</Heading>
                     <Para>We check for bugs and errors and provide recommendations on how to enhance the performance and functionality.</Para>
                  </div>
               </Maindiv>
            </Column1>
         </Row>

      </>
   )
}

export default UIdesign
