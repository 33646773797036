import React from 'react'
import styled from 'styled-components';
import Outlook from "../../images/Outlook.png";
import Azure from "../../images/AzureInfo.png";
import Intune from "../../images/Intune.png";
import Access from "../../images/Access.png";
import Publisher from "../../images/Publisher.png";
import Planner from "../../images/Planner.png";
import SharePoint from "../../images/SharePoint.png";
import exchange from "../../images/exchange.png";
import Lists from "../../images/Lists.png";
import Forms from "../../images/Forms.png";
import Bookings from "../../images/Bookings.png";
import MicrosoftTeams from "../../images/MicrosoftTeams.png";
import OneDrive from "../../images/OneDrive.png";
import Excel from "../../images/excel.png";
import MicrosoftTeamsword from "../../images/MicrosoftTeamsword.png";
import powerpoint from "../../images/powerpoint.png";


const StyledP = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
`

const StyledSection = styled.section`
  @media screen and (min-width: 320px) and (max-width: 767px) {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 5px;
    }
  }
`;

const StyledH4 = styled.h1`
    font-size: 18px;
    color: black;
    font-weight: 700;
    margin-top : 30px;
    font-family: 'Mulish';
`

const StyledTable = styled.table`
  width : 100% ;
`
const StyledTableData = styled.td`
font-size : 14px ;
padding: 7px;
`
const StyledTableData1 = styled.td`
font-size : 14px ;
padding: 7px;
`
const StyledTableBody = styled.tbody`
tr:nth-child(odd) {
    background-color: #fff; /* Apply background color to odd rows */
  }

  tr:nth-child(even) {
    background-color:#f2f2f2; /* Apply background color to even rows */
  }
`
const StyledTableImg = styled.img`
height: 18px;
margin-right: 10px;
`

const StyledTablehead = styled.thead`
text-align: center;
    background: aliceblue;
`
const StyledTableRow = styled.tr`

`
const StyledTableh = styled.th`
  font-size: 14px;
  width: 25%;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding : 5px 9px !important ;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding : 10px 20px !important ;
  }
`;
const StyledUl = styled.ul`
padding-left: 17px;
`
const StyledUl1 = styled.ul`
padding-left: 0px;
list-style: none;
font-size: 13px;

@media screen and (min-width: 320px) and (max-width: 519px) {
    margin-top : 5px ;
    & li {
    display : flex; 
}
}
@media screen and (min-width: 520px) and (max-width: 767px) {
    margin-top : 25px ;
    & li {
    display : flex; 
}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top : 25px ;
  }

& li {
    margin-top: 4px;
}
`
const StyledUl11 = styled.ul`
padding-left: 0px;
list-style: none;
font-size: 13px;
margin-top : 24px ;
@media screen and (min-width: 320px) and (max-width: 767px) {
    & li {
        display : flex ;
    }
  }
& li {
    margin-top: 4px;

}
`
const StyledUl14 = styled.ul`
padding-left: 0px;
list-style: none;
font-size: 13px;
margin-top : 25px;

@media screen and (min-width: 320px) and (max-width: 767px) {
    & li {
  display : flex ;

}
  }
& li {
    margin-top: 4px;

}

`
const StyledImg = styled.img`
height : 15px ;
margin-right : 10px;
`
const StyledImgDrive = styled.img`
height : 15px ;
margin-right : 7px;
@media screen and (min-width: 320px) and (max-width: 767px) {
     margin-right : 3px ;
  }

`
const ForyourBusiness = () => {
    return (
        <>
            <StyledSection>
                <StyledTable className="table  table-bordered " >
                    <StyledTablehead>
                        <StyledTableRow>
                            <StyledTableh>Microsoft 365 Business Basic</StyledTableh>
                            <StyledTableh>Microsoft 365 Business Standard</StyledTableh>
                            <StyledTableh>Microsoft 365 Business Premium</StyledTableh>
                            <StyledTableh>Microsoft 365 Apps for business</StyledTableh>
                        </StyledTableRow>
                    </StyledTablehead>
                    <StyledTableBody>
                        <StyledTableRow>
                            <StyledTableData>
                            <StyledUl>
                                <li> Web and mobile versions of Microsoft 365 apps only</li>
                                <li> Chat, call, meet up to 300 attendees</li>
                                <li> 1 TB of cloud storage per user</li>
                                <li> Business-class email</li>
                                <li> Standard security</li>
                                <li>Anytime phone and web support</li>
                                </StyledUl>
                            </StyledTableData>

                            <StyledTableData>
                                Everything in Business Basic, plus:
                                <StyledUl>
                                <li> 	Desktop versions of Microsoft 365 apps with premium features</li>
                                <li> 	Easily host webinars</li>
                                <li>	Attendee registration and reporting tools</li>
                                <li> 	Manage customer appointments</li>
                                </StyledUl>
                            </StyledTableData>

                            <StyledTableData>
                                Everything in Business Standard, plus:
                                <StyledUl>
                                <li> 	Advanced security</li>
                                <li> Access and data control</li>
                                <li>	Cyberthreat protection</li>
                                </StyledUl>
                            </StyledTableData>

                            <StyledTableData>
                                Desktop versions of Microsoft 365 apps with premium features 1 TB of cloud storage per user
                                Standard security
                                Anytime phone and web support

                            </StyledTableData>
                        </StyledTableRow>
                        <StyledTableRow>

                            <StyledTableData1>
                                Web and mobile apps and services:
                                <StyledUl11>
                                <li> <StyledImg src={MicrosoftTeamsword} alt="Word"></StyledImg>	Word</li>
                                <li> <StyledImg style={{ marginRight: '13px' }} src={Excel}  alt="Excel"></StyledImg>Excel</li>
                                <li>  <StyledImg src={powerpoint}  alt="PowerPoint"></StyledImg>	PowerPoint</li>
                                <li>  <StyledImg src={MicrosoftTeams} alt="Teams"></StyledImg>	Teams</li>
                                <li>  <StyledImg src={Outlook} alt="Outlook"></StyledImg>	Outlook</li>
                                <li> <StyledImg  style={{ marginRight: '9px' }} src={exchange} alt="Exchange"></StyledImg>	Exchange</li>
                                <li>	 <StyledImgDrive    src={OneDrive} alt="OneDrive"></StyledImgDrive>OneDrive</li>
                                <li> <StyledImg src={SharePoint} alt="SharePoint"></StyledImg>	SharePoint</li>
                                </StyledUl11>
                            </StyledTableData1>

                            <StyledTableData1>
                                Desktop, web, and mobile apps and services:
                                <StyledUl1>
                                <li> <StyledImg src={MicrosoftTeamsword} alt="Word"></StyledImg>	Word</li>
                                <li> <StyledImg style={{ marginRight: '13px' }} src={Excel}  alt="Excel"></StyledImg>Excel</li>
                                <li>  <StyledImg src={powerpoint}  alt="PowerPoint"></StyledImg>	PowerPoint</li>
                                <li>  <StyledImg src={MicrosoftTeams} alt="Teams"></StyledImg>	Teams</li>
                                <li>  <StyledImg src={Outlook} alt="Outlook"></StyledImg>	Outlook</li>
                                <li> <StyledImg  style={{ marginRight: '9px' }} src={exchange} alt="Exchange"></StyledImg>	Exchange</li>
                                <li>	 <StyledImgDrive    src={OneDrive} alt="OneDrive"></StyledImgDrive>OneDrive</li>
                                <li> <StyledImg src={SharePoint} alt="SharePoint"></StyledImg>	SharePoint</li>
                                <li><StyledImg src={Access} alt="Access"></StyledImg>	Access (PC only)</li>
                                <li>	<StyledImg src={Publisher} alt="Publisher"></StyledImg> Publisher (PC only)</li>
                                </StyledUl1>
                            </StyledTableData1>


                            <StyledTableData1>
                                Desktop, web, and mobile apps and services:
                                <StyledUl1>
                                <li> <StyledImg src={MicrosoftTeamsword} alt="Word"></StyledImg>	Word</li>
                                <li> <StyledImg style={{ marginRight: '13px' }} src={Excel}  alt="Excel"></StyledImg>Excel</li>
                                <li>  <StyledImg src={powerpoint}  alt="PowerPoint"></StyledImg>	PowerPoint</li>
                                <li>  <StyledImg src={MicrosoftTeams} alt="Teams"></StyledImg>	Teams</li>
                                <li>  <StyledImg src={Outlook} alt="Outlook"></StyledImg>	Outlook</li>
                                <li> <StyledImg  style={{ marginRight: '9px' }} src={exchange} alt="Exchange"></StyledImg>	Exchange</li>
                                <li>	 <StyledImgDrive    src={OneDrive} alt="OneDrive"></StyledImgDrive>OneDrive</li>
                                <li> <StyledImg src={SharePoint} alt="SharePoint"></StyledImg>	SharePoint</li>        
                                <li> <StyledImg src={Intune} alt="Intune"></StyledImg>	Intune</li>
                                <li><StyledImg src={Azure} alt="Azure"></StyledImg>	Azure  Information Protection</li>
                                <li><StyledImg src={Access} alt="Access"></StyledImg>	Access (PC only)</li>
                                <li>	<StyledImg src={Publisher} alt="Publisher"></StyledImg> Publisher (PC only)</li>
                                </StyledUl1>
                            </StyledTableData1>
                           
                            <StyledTableData1>
                                Web and mobile apps and services:
                                <StyledUl14>
                                <li> <StyledImg src={MicrosoftTeamsword} alt="Word"></StyledImg>	Word</li>
                                <li> <StyledImg style={{ marginRight: '13px' }} src={Excel}  alt="Excel"></StyledImg>Excel</li>
                                <li>  <StyledImg src={powerpoint}  alt="PowerPoint"></StyledImg>	PowerPoint</li>                             
                                <li>  <StyledImg src={Outlook} alt="Outlook"></StyledImg>	Outlook</li>                    
                                <li>	 <StyledImgDrive    src={OneDrive} alt="OneDrive"></StyledImgDrive>OneDrive</li>                                
                                <li><StyledImg src={Access}  alt="Access"></StyledImg>	Access (PC only)</li>
                                <li>	<StyledImg src={Publisher}  alt="Publisher"></StyledImg> Publisher (PC only)</li>
                               
                                </StyledUl14>
                            </StyledTableData1>
                        
                        </StyledTableRow>

                    </StyledTableBody>
                </StyledTable>
            </StyledSection>
        </>
    )
}

export default ForyourBusiness
