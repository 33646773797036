import React, { useState, useContext } from "react";
import CreatContext from "../../context/CreatContext";
import MultiInputDropDownTag from "./MultiInputDropDownTag";
import MultiInputTags from "./MultiInputTags";

// import images
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const FilterSearch = (props) => {
  const { setPage } = props;

  const context = useContext(CreatContext);
  const {
    getJobDetail,
    locInfo,
    enterMaxExperience,
    setEnterMaxExperience,
    enterLocation,
    setEnterLocation,
    enterSkillDesignation,
    setEnterSkillDesignation,
    setEmploymentType,
    employmentType,
    jobPostTime,
    setJobPostTime,
  } = context;

  // const multiselectRef = useRef();

  const [minExpKey, setMinExpKey] = useState("");
  const [fullTime, setFullTime] = useState(false);
  const [temporaryTime, setTemporaryTime] = useState(false);
  const [partTime, setPartTime] = useState(false);
  // const [employmentType, setEmploymentType] = useState(['Full Time']);
  // const [jobPostTime, setJobPostTime] = useState('All time');

  const options = locInfo.map((value) => {
    return {
      Location: value.cityName,
    };
  });

  const multiselectOnChange = (value) => {
    // getLocationDetails(value);
  };

  const handleKeyPressExp = (event) => {
    if (
      event.keyCode === 8 ||
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 46
    ) {
      return true;
    }
    event.preventDefault();
    return false;
  };

  const onChangeCheckBox = (data) => {
    if (data === "Full Time") {
      setFullTime(!fullTime);
      if (fullTime === false) {
        setEmploymentType([...employmentType, "Full Time"]);
      } else {
        setEmploymentType([
          ...employmentType.filter((item) => {
            return item !== "Full Time";
          }),
        ]);
      }
    }
    if (data === "Temporary") {
      setTemporaryTime(!temporaryTime);
      if (temporaryTime === false) {
        setEmploymentType([...employmentType, "Temporary"]);
      } else {
        setEmploymentType([
          ...employmentType.filter((item) => {
            return item !== "Temporary";
          }),
        ]);
      }
    }
    if (data === "Part Time") {
      setPartTime(!partTime);
      if (partTime === false) {
        setEmploymentType([...employmentType, "Part Time"]);
      } else {
        setEmploymentType([
          ...employmentType.filter((item) => {
            return item !== "Part Time";
          }),
        ]);
      }
    }
  };

  const onChangeRadio = (e) => {
    setJobPostTime(e.target.value);
  };

  const clearFilter = async () => {
    setEnterSkillDesignation([]);
    setEnterLocation([]);
    setMinExpKey("");
    setEnterMaxExperience("");
    setFullTime(false);
    setTemporaryTime(false);
    setPartTime(false);
    setEmploymentType([]);
    setJobPostTime("All time");

    getJobDetail("All time", "", "", 0, 0, "");
    setPage(1);
  };

  const jobSearchFilterJobBoard = async () => {
    // getJobDetail('All time', '', '', 0, 0, 'Full Time, Temporary, Part Time');
    let keywordInputString = "";
    let locationSearchString = "";
    let minExperience = 0;
    let maxExperience = 0;

    if (minExpKey !== "") {
      minExperience = minExpKey;
    }
    if (enterMaxExperience !== "") {
      maxExperience = enterMaxExperience;
    }

    if (enterSkillDesignation.length > 0) {
      keywordInputString = enterSkillDesignation.join(", ");
    }
    // if (enterLocation.length > 0) {
    //     locationSearchString = enterLocation.map((item) => {
    //         return (item.Location)
    //     }).join(", ");
    // }

    if (enterLocation.length > 0) {
      locationSearchString = enterLocation.join(", ");
    }

    let employmentTypeString = employmentType
      .map((item, i) => {
        return item;
      })
      .join(", ");

    getJobDetail(
      jobPostTime,
      keywordInputString,
      locationSearchString,
      minExperience,
      maxExperience,
      employmentTypeString
    );
    setPage(1);
  };

  return (
    <>
      <div
        className="filter-box"
        style={{ position: "sticky", top: "15%", padding: "0px" }}
        id="jobBoardFiltterSearch"
      >
        <div
          style={{
            background: "#0b5ed7",
            padding: "10px 10px 0px 10px",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <div className="d-flex align-items-baseline justify-content-between">
            <h1 className="filter-text">Filters</h1>
            <span className="clear-all cursorPointer" onClick={clearFilter}>
              {" "}
              Clear all
              <CancelOutlinedIcon
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  marginLeft: "5px",
                  marginBottom: "2px",
                }}
              />
              {/* <img className="position-relative" src={clear_filter_1} style={{ bottom: '1' + 'px' }} /> */}
            </span>
          </div>
        </div>
        <div style={{ padding: "0px 10px 10px 10px" }}>
          <div className="border-bottom-1 pb-3 mt-2">
            <label className="custom-label-2 full-width">
              Keyword
              <span className="float-end">
                <button tyle="button" className="searchfilerbtnjb">
                  <i
                    className="fa fa-search"
                    onClick={() => jobSearchFilterJobBoard()}
                  ></i>{" "}
                </button>
              </span>
            </label>
            <div className="heightIncDiv" id="jobRoleDiv">
              {/* <InputTags className="form-control custom-input-1 NoStar jobBoardTagsInput" values={keywordInput} onTags={(value) => setEnterSkillDesignation(value.values)} id="jobRole" placeholder='Input text' /> */}
              <MultiInputTags
                className="form-control custom-input-1 NoStar jobBoardTagsInput"
                selectedTags={(value) => {
                  setEnterSkillDesignation(value);
                }}
                onTag={
                  enterSkillDesignation.length === 0
                    ? []
                    : enterSkillDesignation
                }
                placeholder={
                  enterSkillDesignation.length === 0
                    ? "Search by Designation/Skills"
                    : ""
                }
              />
            </div>
          </div>
          <div className="border-bottom-1 pb-3 mt-2">
            <label className="custom-label-2 full-width">
              Location
              <span className="float-end">
                <button
                  tyle="button"
                  className="searchfilerbtnjb"
                  onClick={() => jobSearchFilterJobBoard()}
                >
                  <i className="fa fa-search" aria-hidden="true"></i>
                </button>
              </span>
            </label>
            <div className="heightIncDiv" id="jobLocDiv">
              <MultiInputDropDownTag
                onTag={enterLocation}
                setOnTag={(value) => {
                  setEnterLocation(value);
                }}
                label="Location"
                preSelectedTag={[]}
                placeholder="Search by Location"
                options={options}
                onSearch={(value) => {
                  multiselectOnChange(value);
                }}
              />
            </div>
          </div>
          <div className="border-bottom-1 pb-3 mt-2">
            <div className="d-flex justify-content-between">
              <div className="col-lg-6 pl-0">
                <h2 className="custom-label-2 full-width">
                  Min. Exp.
                  <span className="float-end">
                    <button
                      tyle="button"
                      className="searchfilerbtnjb"
                      onClick={() => jobSearchFilterJobBoard()}
                    ></button>
                  </span>
                </h2>
              </div>
              <div className="col-lg-6 pr-0">
                <h2 className="custom-label-2 full-width">
                  Max. Exp.
                  <span className="float-end">
                    <button
                      tyle="button"
                      className="searchfilerbtnjb"
                      onClick={() => jobSearchFilterJobBoard()}
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="d-flex justify-content-between col-lg-12 col-12 pr-0 pl-0"
              id="exp"
            >
              <div
                className="col-lg-6 col-6 pl-0 "
                style={{ paddingRight: "10px" }}
              >
                <input
                  className="form-control form-control-sm"
                  style={{ height: "30px" }}
                  type="text"
                  name=""
                  id="valueMinExp"
                  onKeyPress={handleKeyPressExp}
                  maxLength={4}
                  min={0.0}
                  step="0.1"
                  placeholder="In Years"
                  value={minExpKey}
                  onChange={(e) => {
                    if (/^\d*\.?\d{0,1}$/.test(e.target.value)) {
                      setMinExpKey(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-6 pr-0">
                <input
                  className="form-control form-control-sm "
                  style={{ height: "30px" }}
                  type="text"
                  name=""
                  id="valueMaxExp"
                  onKeyPress={handleKeyPressExp}
                  maxLength={4}
                  min={0.0}
                  step="0.1"
                  placeholder="In Years"
                  value={enterMaxExperience}
                  onChange={(e) => {
                    if (/^\d*\.?\d{0,1}$/.test(e.target.value)) {
                      setEnterMaxExperience(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="border-bottom-1 pb-3 mt-2">
            {/* <label className="custom-label-2">Type of Employment</label> */}
            <label className="custom-label-2 full-width">
              Type of Employment
              <span className="float-end">
                <button tyle="button" className="searchfilerbtnjb">
                  <i
                    className="fa fa-search"
                    onClick={() => jobSearchFilterJobBoard()}
                  ></i>{" "}
                </button>
              </span>
            </label>
            <ul className="	list-group" style={{}} id="employementType">
              <li className="list-group-item border-0 p-0 d-flex align-items-center">
                <input
                  type="checkbox"
                  className="custom-checkbox cursor-pointer"
                  id="checkBoxFullTime"
                  value="Full Time"
                  onChange={() => {
                    onChangeCheckBox("Full Time");
                  }}
                  checked={fullTime}
                />
                <label htmlFor="checkBoxFullTime">
                  <div className="mt-alignment-span-1 checkbox-label mx-2">
                    Full-time
                  </div>
                </label>
              </li>
              <li className="list-group-item border-0 p-0 d-flex align-items-center">
                <input
                  type="checkbox"
                  className="custom-checkbox cursor-pointer"
                  id="checkBoxTemporary"
                  value="Temporary"
                  onChange={() => {
                    onChangeCheckBox("Temporary");
                  }}
                  checked={temporaryTime}
                />
                <label htmlFor="checkBoxTemporary">
                  <div className="mt-alignment-span checkbox-label mx-2">
                    Temporary
                  </div>
                </label>
              </li>
              <li className="list-group-item border-0 p-0 d-flex align-items-center">
                <input
                  type="checkbox"
                  className="custom-checkbox cursor-pointer"
                  id="checkBoxPartTime"
                  value="Part Time"
                  onChange={() => {
                    onChangeCheckBox("Part Time");
                  }}
                  checked={partTime}
                />
                <label htmlFor="checkBoxPartTime">
                  <div className="mt-alignment-span checkbox-label mx-2">
                    Part Time
                  </div>
                </label>
              </li>
            </ul>
          </div>
          <div className="pb-3 mt-2" id="jobPostingDate">
            {/* <label className="custom-label-2">Date of Posting</label> */}
            <label className="custom-label-2 full-width">
              Date of Posting
              <span className="float-end">
                <button tyle="button" className="searchfilerbtnjb">
                  <i
                    className="fa fa-search"
                    onClick={() => jobSearchFilterJobBoard()}
                  ></i>{" "}
                </button>
              </span>
            </label>
            <div className="custom-control custom-radio mt_2 fw-500">
              <input
                type="radio"
                className="custom-control-input"
                id="cap-opt-1"
                name="capacity1"
                value="All time"
                checked={jobPostTime === "All time"}
                onChange={(e) => onChangeRadio(e)}
              />
              <label className="custom-control-label" htmlFor="cap-opt-1">
                <span className="cap-opt-1 mx-2">All time</span>
              </label>
            </div>
            <div className="custom-control custom-radio fw-500">
              <input
                type="radio"
                className="custom-control-input"
                id="cap-opt-2"
                name="capacity1"
                value="Last 24 hours"
                checked={jobPostTime === "Last 24 hours"}
                onChange={(e) => onChangeRadio(e)}
              />
              <label className="custom-control-label" htmlFor="cap-opt-2">
                <span className="cap-opt-1 mx-2">Last 24 hours </span>
              </label>
            </div>
            <div className="custom-control custom-radio fw-500">
              <input
                type="radio"
                className="custom-control-input"
                id="cap-opt-3"
                name="capacity1"
                value="Last 3 days"
                checked={jobPostTime === "Last 3 days"}
                onChange={(e) => onChangeRadio(e)}
              />
              <label className="custom-control-label" htmlFor="cap-opt-3">
                <span className="cap-opt-1 mx-2">Last 3 days</span>
              </label>
            </div>
            <div className="custom-control custom-radio fw-500">
              <input
                type="radio"
                className="custom-control-input"
                id="cap-opt-4"
                name="capacity1"
                value="Last 7 days"
                checked={jobPostTime === "Last 7 days"}
                onChange={(e) => onChangeRadio(e)}
              />
              <label className="custom-control-label" htmlFor="cap-opt-4">
                <span className="cap-opt-1 mx-2">Last 7 days</span>
              </label>
            </div>
          </div>
        </div>
        <div>
          {/* <button className="btn btn-blue-1" style={{width: '100'+'%'}}
										onClick={onSelectChange}>Search</button>  */}
        </div>
      </div>
    </>
  );
};

export default FilterSearch;
