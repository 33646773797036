import React, { useContext, useEffect, useState } from "react";
import CreatContext from "../../context/CreatContext";
import { Pagination } from "antd";

// images import
import BG_Waves from "../../images/bg-waves.png";
import Spin from "../../images/spin.gif";

// import other components
import LoaderGif from "./LoaderGif";
import UserRecommendFriend from "./UserRecommendFriend";
import UserJobDetailModal from "./UserJobDetailModal";
import FilterSearch from "./FilterSearch";
import JobCard from "./JobCard";
// import NotifyMeForJobs from "./NotifyMeForJobs";
// import PostResume from "./PostResume";
import JobCardMobileView from "./JobCardMobileView";
import FilterSearchMobileView from "./FilterSearchMobileView";

const JobBoard = () => {
  const context = useContext(CreatContext);
  const {
    // getJobDetail,
    // getMatchingJob,
    statusCheck,
    jobInfo,
    applyNowForJobTicket,
    // getJobDetailJmid,
    // getJobCompanyProfileSiteId,
    loader,
    setQuestionFlag,
    showSorry,
    // applyNowForJobTicket1,
    // getQestionsDetails,
    getLocationDetailsByJmId,
    setCompleteJobDetail,
  } = context;

  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [applyNowJmId, setApplyNowJmId] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobCardsScroll, setJobCardsScroll] = useState(0);

  // const onFilterJob = jobInfo.filter((data) => {
  //     if (onKeyword.length === 0) {
  //         return data;
  //     } else {
  //         return onKeyword.some((item) => {
  //             if (data.skill_name.toLowerCase().includes(item.toLowerCase()) || data.job_title.toLowerCase().includes(item.toLowerCase())) { return (data) }
  //         })
  //     }
  // }).filter((data) => {
  //     if (onLocation.length === 0) {
  //         return data;
  //     } else {
  //         return onLocation.some((item) => {
  //             if (data.preferred_location.toLowerCase().includes(item.Location.toLowerCase())) { return (data) }
  //         })
  //     }
  // }).filter((data) => {
  //     if (onMinExperience === 0 || onMinExperience === '') {
  //         return data;
  //     } else if (parseInt(data.min_total_exp) >= onMinExperience) {
  //         return data;
  //     }
  // }).filter((data) => {
  //     if (onMaxExperience === 0 || onMaxExperience === '') {
  //         return data;
  //     } else if (onMaxExperience >= parseInt(data.max_total_exp) ) {
  //         return data;
  //     }
  // }).filter((data) => {
  //     if (employmentType.length === 0) {
  //         return data;
  //     } else {
  //         return employmentType.some((item) => {
  //             if (data.employment_type.toLowerCase().includes(item.toLowerCase())) { return (data) }
  //         })
  //     }
  // })

  const total = jobInfo.length;

  const indexOfLastPage = page * recordsPerPage;
  const indexOfFirstPage = indexOfLastPage - recordsPerPage;
  const currentPage = jobInfo.slice(indexOfFirstPage, indexOfLastPage);
  const [storeJobUniqueIdJobBoard, setStoreJobUniqueIdJobBoard] =
    useState(null);
  const onShowSizeChange = (current, pageSize) => {
    setRecordsPerPage(pageSize);
  };

  const jobBoardApplyNowTicketJmID = (
    jmIdValue,
    jobTitleStr,
    jobQuesPresent,
    item
  ) => {
    setApplyNowJmId(jmIdValue);
    setJobTitle(jobTitleStr);
    setQuestionFlag(jobQuesPresent);
    // getQestionsDetails(jmIdValue);
    getLocationDetailsByJmId(jmIdValue);
    setCompleteJobDetail(item);
  };

  const jobBoardApplyJobTicket = (
    fname,
    lname,
    mobile,
    email,
    currentLoc,
    preferred_locString,
    currentCTC,
    expectedCTC,
    skillString,
    remarks,
    candidateExperience,
    relevantExperience,
    applyJobResumeFile,
    preferredLoc,
    date,
    noticePeriod,
    servingNoticePeriod,
    prefferedLocLmID
  ) => {
    applyNowForJobTicket(
      applyNowJmId,
      fname,
      lname,
      mobile,
      email,
      currentLoc,
      preferred_locString,
      currentCTC,
      expectedCTC,
      skillString,
      remarks,
      candidateExperience,
      relevantExperience,
      applyJobResumeFile,
      preferredLoc,
      date,
      noticePeriod,
      servingNoticePeriod,
      prefferedLocLmID
    );
  };

  // const jobBoardApplyJobTicket1 = (
  //   fname,
  //   lname,
  //   mobile,
  //   email,
  //   currentLoc,
  //   preferred_locString,
  //   currentCTC,
  //   expectedCTC,
  //   skillString,
  //   remarks,
  //   applyJobResumeFile,
  //   candidateExperience,
  //   preferredLoc,
  //   date,
  //   noticePeriod,
  //   servingNoticePeriod
  // ) => {
  //   applyNowForJobTicket1(
  //     fname,
  //     lname,
  //     mobile,
  //     email,
  //     currentLoc,
  //     preferred_locString,
  //     currentCTC,
  //     expectedCTC,
  //     skillString,
  //     remarks,
  //     applyJobResumeFile,
  //     candidateExperience,
  //     preferredLoc,
  //     date,
  //     noticePeriod,
  //     servingNoticePeriod
  //   );
  // };

  const openJobDetail = (jobEncrypt) => {
    window.open(`/job-detail${jobEncrypt}`, `/job-detail${jobEncrypt}`);
  };

  // const scrollToPostYourResume = (event) => {
  //   event.preventDefault();
  //   const section = document.querySelector("#post-resume");
  //   const navbarHeight = 80; // Replace with the actual height of your navbar
  //   const top =
  //     section.getBoundingClientRect().top + window.scrollY - navbarHeight;

  //   window.scrollTo({
  //     top: top,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const job_Card = document.querySelector(".jobCardScrollTopClass");
    if (job_Card) {
      job_Card.scrollTo(0, 0);
    }
  }, [jobCardsScroll]);

  return (
    <>
      <div
        id="jobBoardContainer"
        style={{
          backgroundImage: `url(${BG_Waves})`,
          backgroundRepeat: "repeat",
          backgroundPosition: "center",
          marginBottom: "32px",
          marginTop: "80px",
        }}
      >
        {/* {loader && <LoaderGif />} */}
        {statusCheck === "not ok" && <LoaderGif />}

        {/* user RecommendFriend_modalEnd- */}
        {/* <UserRecommendFriend /> */}

        <UserRecommendFriend jobTitle={jobTitle} applyNowJmId={applyNowJmId} />

        {/* user RecommendFriend_modalEnd  */}

        {/* <!-- Job_Detail_page_modalStart_by_Simran Arora --> */}
        <UserJobDetailModal
          jobBoardApplyJobTicket={jobBoardApplyJobTicket}
          jobTitle={jobTitle}
          applyNowJmId={applyNowJmId}
        />
        {/* <!-- Job_Detail_page_modalEnd_by_Simran Arora --> */}

        {/* <HeadingDiv /> */}

        {/* Job and job Search  */}
        <div className="bg-grey-1">
          <section className="main-section-job-board">
            <div className="container">
              <h4 id="totalJobCount">
                All Active Jobs
                <span className="total-job-count"> ({total} Jobs)</span>
              </h4>
              <div>
                <div className="row">
                  <div className="col-lg-3" id="filterSearchPc">
                    <FilterSearch setPage={setPage} />
                  </div>
                  <div className="col-lg-3" id="filterSearchMobileView">
                    <FilterSearchMobileView setPage={setPage} />
                  </div>

                  <div
                    className="col-lg-6 jobCardScrollTopClass"
                    id="tikectDiv"
                  >
                    <span className="d-flex justify-content-center">
                      {loader && <img src={Spin} alt="" />}
                    </span>
                    {showSorry === 0 && (
                      <div
                        style={{
                          height: "390px",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingLeft: "15px",
                        }}
                      >
                        <span className="d-flex justify-content-center mt-5">
                          <h4 style={{ fontSize: "35px" }}>
                            Sorry, No results were found!
                          </h4>
                        </span>

                        {/* <span className="d-flex justify-content-center mt-4">
                          <h4 style={{ fontSize: "16px" }}>
                            <a
                              href="#post-resume"
                              style={{
                                color: "#5467EF",
                                textDecoration: "none",
                              }}
                              onClick={scrollToPostYourResume}>
                              Post your resume
                            </a>{" "}
                            below to get update on new jobs!
                          </h4>
                        </span> */}
                      </div>
                    )}
                    {currentPage.map((item, index) => {
                      return (
                        <JobCard
                          key={index}
                          item={item}
                          jobBoardApplyNowTicketJmID={
                            jobBoardApplyNowTicketJmID
                          }
                          openJobDetail={openJobDetail}
                          storeJobUniqueIdJobBoard={storeJobUniqueIdJobBoard}
                          setStoreJobUniqueIdJobBoard={
                            setStoreJobUniqueIdJobBoard
                          }
                        />
                      );
                    })}
                  </div>
                  <div
                    className="col-lg-6 jobCardScrollTopClass"
                    id="tikectDivMobileView"
                  >
                    <h4 id="totalJobCountMobileView" className="mb-2 mt-3">
                      All Active Jobs
                      <span className="total-job-count"> ({total} Jobs)</span>
                    </h4>
                    <span className="d-flex justify-content-center">
                      {loader && <img src={Spin} alt="" />}
                    </span>
                    {showSorry === 0 && (
                      <div
                        style={{
                          marginTop: "40px",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Sorry no jobs found!!</h4>
                        {/* <h4>
                          Post your resume below to get update on new jobs!
                        </h4> */}
                      </div>
                    )}

                    {currentPage.map((item, index) => {
                      return (
                        <JobCardMobileView
                          key={index}
                          item={item}
                          jobBoardApplyNowTicketJmID={
                            jobBoardApplyNowTicketJmID
                          }
                          openJobDetail={openJobDetail}
                          jobCardsScroll={jobCardsScroll}
                        />
                      );
                    })}
                  </div>
                  {/* <div className={`col-lg-3`}>
                    <NotifyMeForJobs />
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div
                className="col-lg-10 paginationStyle"
                style={{ position: "relative", bottom: "0" }}
              >
                <div
                  className="d-flex align-items-baseline justify-content-center"
                  id="jobBoardFooter"
                >
                  <div id="jobBoardRecordCountDiv"></div>
                  <div
                    className="customPage paginationJobBoard mt-3"
                    id="paginationJobBoard"
                  >
                    {total / recordsPerPage > 1 && (
                      <Pagination
                        onChange={(page) => {
                          setPage(page);
                          setJobCardsScroll(jobCardsScroll + 1);
                        }}
                        pageSize={recordsPerPage}
                        total={total}
                        current={page}
                        onShowSizeChange={onShowSizeChange}
                        pageSizeOptions={[20, 30, 40]}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Post Resume */}
        {/* <section className={`post-your-job`} id="DirectGo-PostResume">
          <PostResume jobBoardApplyJobTicket1={jobBoardApplyJobTicket1} />
        </section> */}
      </div>
    </>
  );
};

export default JobBoard;
