import React from 'react'
import styled from 'styled-components';


const StyledP = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
    
    @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
  }
`

const StyledH4 = styled.h1`
    font-size: 18px;
    color: black;
    font-weight: 700;
    margin-top : 30px;
    font-family: 'Mulish';
`

const StyledTable = styled.table`
  width : 100% ;
`
const StyledTablehead = styled.thead``
const StyledTableRow = styled.tr``
const StyledTableh = styled.th`
font-size : 14px ;
`
const StyledTableData = styled.td`
font-size : 14px ;

@media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
  }
`
const StyledTableBody = styled.tbody``
const StyledTableImg = styled.img`
height: 18px;
margin-right: 10px;
`

const Azure = () => {
    return (
        <>
            
            <StyledP style={{marginTop:'13px'}}>Microsoft Azure is a comprehensive cloud computing platform provided by Microsoft. It offers a vast array of cloud services designed to assist organizations in building, deploying, and managing applications and services through Microsoft-managed data centers.</StyledP>
            <StyledP>With Azure, businesses can leverage a scalable and flexible infrastructure that spans across a global network of Microsoft-managed data centers. These data centers are strategically located around the world, ensuring reliable performance, data redundancy, and compliance with local regulations.</StyledP>
            <StyledP>Azure provides a wide range of services, including infrastructure as a service (IaaS), platform as a service (PaaS), and software as a service (SaaS). It supports various programming languages, frameworks, and tools, enabling developers to build applications using their preferred technologies.</StyledP>

            <StyledTable className="table table-striped table-bordered " >
                <StyledTableBody>
                    <StyledTableRow>
                        <StyledTableData>  <StyledTableImg src={require('../../images/icon-Virtual-Machines.svg').default} alt="Virtual-Machines" /> Azure Virtual Machines—Windows</StyledTableData>
                        <StyledTableData><StyledTableImg src={require('../../images/vm-linux.svg').default} alt="vm-linux" />Azure Virtual Machines—Linux</StyledTableData>
                        <StyledTableData><StyledTableImg src={require('../../images/azure-sql-database.svg').default} alt="sql-database" />Azure SQL Database</StyledTableData>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableData><StyledTableImg src={require('../../images/storage-blobs1.svg').default} alt="Cosmos" />Azure Cosmos DB</StyledTableData>
                        <StyledTableData><StyledTableImg src={require('../../images/mysql.svg').default} alt="mysql" />Azure Database for MySQL</StyledTableData>
                        <StyledTableData><StyledTableImg src={require('../../images/postgresql.svg').default} alt="postgresql" />Azure Database for PostgreSQL</StyledTableData>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableData><StyledTableImg src={require('../../images/azure-devops.svg').default} alt="devops" />Azure DevOps</StyledTableData>
                        <StyledTableData><StyledTableImg src={require('../../images/storage-blobs.svg').default} alt="Blob" />Blob Storage</StyledTableData>
                        <StyledTableData><StyledTableImg src={require('../../images/search.svg').default} alt="Search" />Cognitive Search</StyledTableData>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableData><StyledTableImg src={require('../../images/data-factory.svg').default} alt="Factory" />Data Factory</StyledTableData>
                        <StyledTableData><StyledTableImg src={require('../../images/form-recognizer.svg').default} alt="Recognizer" />Form Recognizer</StyledTableData>
                        <StyledTableData><StyledTableImg src={require('../../images/virtual-network.svg').default} alt="Network" />Virtual Network	</StyledTableData>
                    </StyledTableRow>
                </StyledTableBody>
            </StyledTable>


            <StyledP className="mt-4">
                The cloud offers a multitude of services, and Microsoft Azure provides an extensive range of options to help you host and launch your website, web application, or mobile application quickly and efficiently. With Azure's comprehensive suite of services, you can leverage a wide array of tools and solutions to build your product and establish your online presence seamlessly and swiftly.
            </StyledP>
        </>
    )
}

export default Azure
