import React, { useContext, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import CreatContext from '../../context/CreatContext';

const UserRecommendFriend = (props) => {

    const spanElement = document.getElementsByClassName("EmailSpanRecommend")[0];
    const spanElement1 = document.getElementsByClassName("EmailSpanRecommend1")[0];

    const { jobTitle, applyNowJmId } = props;

    const context = useContext(CreatContext);
    const { recommendToFriendSave } = context;

    const userRecommendFriendCloseModal = useRef();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [friendName, setFriendName] = useState('');
    const [friendEmail, setFriendEmail] = useState('');
    // const [recommendData, setRecommendData] = useState({ name: "", email: '', friendName: '', friendEmail: '' });

    // const submitFormData = (e) => {
    //     setRecommendData({ ...recommendData, [e.target.name]: e.target.value })
    // }

    const SubmitRecommendData = () => {
        const youremail = email;
        const fremail = friendEmail;
        const Yourname = name;
        const frName = friendName;

        if (youremail === "" || fremail === "" || Yourname === "" || frName === "") {
            Swal.fire({
                title: 'Please fill all mandatory fileds!',
                icon: 'warning'
            });
            return false;
        }

        let pattern = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!pattern.test(email) || !pattern.test(friendEmail)) {
            Swal.fire({
                title: 'Please enter a valid email!',
                icon: 'warning'
            });
            return false;
        }

        recommendToFriendSave(applyNowJmId, Yourname, youremail, frName, fremail);
        userRecommendFriendCloseModal.current.click();
        clearModalData();
    }

    const clearModalData = () => {
        setName('');
        setEmail('');
        setFriendName('');
        setFriendEmail('');
        spanElement.textContent = "*";
        spanElement1.textContent = "*";
    }

    const emailFormat = (e) => {
        const emailValue = e.target.value;;
        var pattern = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (pattern.test(emailValue) || emailValue === "") {
            spanElement.textContent = "*";
        } else {
            spanElement.textContent = "(Please Enter the Valid Email)";
        }
    }

    const emailFormat1 = (e) => {
        const emailValue = e.target.value;;
        var pattern = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (pattern.test(emailValue) || emailValue === "") {
            spanElement1.textContent = "*";
        } else {
            spanElement1.textContent = "(Please Enter the Valid Email)";
        }
    }

    const firstSpaceValidation = (event) => {
        const len = event.target.value.length;
        if (len === 0 && event.charCode === 32) {
            event.preventDefault();
            return false
        }
        return true
    }

    const firstLetterCapitalized = (string) => {
        return (string.charAt(0).toUpperCase() + string.slice(1));
    }

    return (
        <>
            {/* <!-- RecommendFriend_modalEnd--> */}
            <div>
                <div id="userRecommendFriend1" className="modal fade" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content contentjobDetailPage">
                            <div className="modal-header-JobDetail">
                                <h4 className="modal-title" id="">Recommend to Friend - {jobTitle}</h4>
                                <button type="button" className="close-modal-jd pt-0 " aria-label="Close" data-bs-dismiss="modal" data-dismiss="modal" aria-hidden="true" id="closeJobDetailBtn1" ref={userRecommendFriendCloseModal} onClick={() => clearModalData()}>X</button>
                            </div>
                            <div className="modal-body p-0">
                                <div style={{}}>

                                    <div className="row">
                                        <div className="col-lg-6 pt-0 pb-0">
                                            <div className="form-group">
                                                <label className="" style={{ color: 'black', fontWeight: 600 }}>Your Name <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control custom-input-Modal"
                                                    name="name" onKeyPress={firstSpaceValidation} id="" placeholder="Your Name" value={name} onChange={(e) => { setName(firstLetterCapitalized(e.target.value)) }} autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 pt-0 pb-0">
                                            <div className="form-group">
                                                <label className="" style={{ color: 'black', fontWeight: 600 }}>Your Email <span className="text-danger EmailSpanRecommend">*</span></label>
                                                <input type="email" className="form-control custom-input-Modal"
                                                    name="email" onKeyPress={firstSpaceValidation} id="" value={email} placeholder="Your Email ID" onChange={(e) => { setEmail(e.target.value) }} onInput={(e) => emailFormat(e)}
                                                    autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="" style={{ color: 'black', fontWeight: 600 }}>Your Friend Name <span className="text-danger">*</span></label> <input type="text" className="form-control custom-input-Modal" name="friendName" onKeyPress={firstSpaceValidation} id="" value={friendName} onChange={(e) => { setFriendName(firstLetterCapitalized(e.target.value)) }} placeholder="Your Friend Name" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="from-group">
                                                <label className="" style={{ color: 'black', fontWeight: 600 }}>Your Friend Email Id <span className="text-danger EmailSpanRecommend1">*</span></label> <input type="email" className="form-control custom-input-Modal" onKeyPress={firstSpaceValidation} name="friendEmail" id="" value={friendEmail} onChange={(e) => { setFriendEmail(e.target.value) }} onInput={(e) => emailFormat1(e)} placeholder="Your Friend Email ID" autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 m-auto">
                                            <button className="btn btn-blue-2 mt-3" onClick={() => SubmitRecommendData()}>Submit</button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {/* <!-- 					/.modal-content --> */}
                    </div>
                    {/* <!-- 				/.modal-dialog --> */}
                </div>
                {/* <!-- 			/.modal --> */}
            </div>
            {/* <!-- RecommendFriend_modalEnd --> */}

        </>
    )
}

export default UserRecommendFriend