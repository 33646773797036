import React from "react";
import styled from "styled-components";
import skillicon1 from "../../../images/talent-front-end 1.png";
import talentdesigners from "../../../images/talent-designers 1.png";
import talentemailmarketers from "../../../images/talent-email-marketers 1.png";
import talentsoftwareengineer from "../../../images/talent-software-engineer 1.png";
import talentqualityanalyst from "../../../images/talent-quality-analyst 1.png";
import talentbackend from "../../../images/talent-back-end 2.png";
import talentdigitalmarketers from "../../../images/talent-digital-marketers 1.png";
import talentappdeveloper from "../../../images/talent-app-developer 1.png";
import talentsalesforce from "../../../images/talent-salesforce 1.png";

const Row = styled.div`
  margin-top: 27px;
  margin-bottom: 27px;
`;
const Row1 = styled.div`
  align-items: center;
`;
const COl = styled.div``;
const DisplayFlex = styled.div`
  display: flex;
  align-items: center;
`;
const ColNew = styled.div`
  border: 1px solid #4c4646;
  border-radius: 10px;
  padding: 15px 10px;
  margin: 20px;
`;
const StyledImg = styled.img`
  margin-right: 10px;
`;
const SkillName = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #3b4445;
  padding: 5px;
  text-transform: capitalize;
`;
const StyledH5 = styled.h1`
  font-size: 20px;
  font-weight: 700;
  font-family: "Mulish";
`;
const SkilledMainDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SkilledDiv = styled.div`
  background: #f2f3f3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 26px;
  margin: 0px 0px 8px 0px;
  @media (min-width: 368px) and (max-width: 768px) {
    margin: 7px 0px 0px 0px;
  }
`;
const SkilledDiv1 = styled.div`
  background: #fffed3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 26px;
  margin: 0px 0px 8px 0px;
  @media (min-width: 368px) and (max-width: 768px) {
    margin: 7px 0px 0px 0px;
  }
`;
const SkilledDiv2 = styled.div`
  background: #f1c0c0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 26px;
  @media (min-width: 368px) and (max-width: 768px) {
    margin: 7px 0px 0px 0px;
  }
`;
const SkilledDiv3 = styled.div`
  background: #eafeb1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 26px;
  @media (min-width: 368px) and (max-width: 768px) {
    margin: 7px 0px 0px 0px;
  }
`;
const SkilledDiv4 = styled.div`
  background: #e1e5ff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 26px;
  @media (min-width: 368px) and (max-width: 768px) {
    margin: 7px 0px 0px 0px;
  }
`;
const SkillSection = () => {
  return (
    <Row>
      <ColNew className="col-lg-12">
        <Row1 className="row">
          <COl className="col-lg-5">
            <DisplayFlex>
              <StyledImg src={skillicon1} alt="Front-End"></StyledImg>
              <StyledH5>Front-End Developers</StyledH5>
            </DisplayFlex>
          </COl>
          <COl className="col-lg-7">
            <SkilledMainDiv>
              <SkilledDiv className="skillDiv ms-2">
                <SkillName className="skillName">HTML</SkillName>
              </SkilledDiv>
              <SkilledDiv1 className="skillDiv ms-2 ">
                <SkillName className="skillName">React JS</SkillName>
              </SkilledDiv1>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">Vue.js</SkillName>
              </SkilledDiv2>
              <SkilledDiv3 className="skillDiv ms-2">
                <SkillName className="skillName">Angular JS</SkillName>
              </SkilledDiv3>
              <SkilledDiv4 className="skillDiv ms-2">
                <SkillName className="skillName">Mean Stack</SkillName>
              </SkilledDiv4>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">Mern Stack</SkillName>
              </SkilledDiv2>
            </SkilledMainDiv>
          </COl>
        </Row1>
      </ColNew>

      <ColNew className="col-lg-12">
        <Row1 className="row">
          <COl className="col-lg-5">
            <DisplayFlex>
              <StyledImg src={talentbackend} alt="Back-End"></StyledImg>
              <StyledH5>Back-End Developers</StyledH5>
            </DisplayFlex>
          </COl>
          <COl className="col-lg-7">
            <SkilledMainDiv>
              <SkilledDiv className="skillDiv ms-2 ">
                <SkillName className="skillName">Node.js</SkillName>
              </SkilledDiv>
              <SkilledDiv1 className="skillDiv ms-2 ">
                <SkillName className="skillName">Laravel</SkillName>
              </SkilledDiv1>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">WordPress</SkillName>
              </SkilledDiv2>
              <SkilledDiv3 className="skillDiv ms-2">
                <SkillName className="skillName">Shopify</SkillName>
              </SkilledDiv3>
              <SkilledDiv4 className="skillDiv ms-2">
                <SkillName className="skillName">Hubspot</SkillName>
              </SkilledDiv4>
              <SkilledDiv className="skillDiv ms-2">
                <SkillName className="skillName">Magento</SkillName>
              </SkilledDiv>
              <SkilledDiv1 className="skillDiv ms-2">
                <SkillName className="skillName">Mean Stack</SkillName>
              </SkilledDiv1>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">Mern Stack</SkillName>
              </SkilledDiv2>
              <SkilledDiv3 className="skillDiv ms-2">
                <SkillName className="skillName">Drupal</SkillName>
              </SkilledDiv3>
            </SkilledMainDiv>
          </COl>
        </Row1>
      </ColNew>
      <ColNew className="col-lg-12">
        <Row1 className="row">
          <COl className="col-lg-5">
            <DisplayFlex>
              <StyledImg
                src={talentdigitalmarketers}
                alt="Digital_Marketers"
              ></StyledImg>
              <StyledH5>Digital Marketers</StyledH5>
            </DisplayFlex>
          </COl>
          <COl className="col-lg-7">
            <SkilledMainDiv>
              <SkilledDiv className="skillDiv ms-2 ">
                <SkillName className="skillName">
                  Performance Marketer
                </SkillName>
              </SkilledDiv>
              <SkilledDiv1 className="skillDiv ms-2 ">
                <SkillName className="skillName">
                  Programmatic Specialists
                </SkillName>
              </SkilledDiv1>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">SEO Experts</SkillName>
              </SkilledDiv2>
              <SkilledDiv className="skillDiv ms-2">
                <SkillName className="skillName">Data Analyst</SkillName>
              </SkilledDiv>
              <SkilledDiv3 className="skillDiv ms-2">
                <SkillName className="skillName">
                  Social Media Ads Expert
                </SkillName>
              </SkilledDiv3>
              <SkilledDiv4 className="skillDiv ms-2">
                <SkillName className="skillName">
                  Google Analytics Expert
                </SkillName>
              </SkilledDiv4>

              <SkilledDiv1 className="skillDiv ms-2 ">
                <SkillName className="skillName">
                  Conversion Optimization Specialist
                </SkillName>
              </SkilledDiv1>
            </SkilledMainDiv>
          </COl>
        </Row1>
      </ColNew>
      <ColNew className="col-lg-12">
        <Row1 className="row">
          <COl className="col-lg-5">
            <DisplayFlex>
              <StyledImg src={talentdesigners} alt="Designers"></StyledImg>
              <StyledH5>Designers</StyledH5>
            </DisplayFlex>
          </COl>
          <COl className="col-lg-7">
            <SkilledMainDiv>
              <SkilledDiv className="skillDiv ms-2 ">
                <SkillName className="skillName">UI Designer</SkillName>
              </SkilledDiv>
              <SkilledDiv1 className="skillDiv ms-2 ">
                <SkillName className="skillName">UX Designer</SkillName>
              </SkilledDiv1>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">Product Designer</SkillName>
              </SkilledDiv2>
              <SkilledDiv3 className="skillDiv ms-2">
                <SkillName className="skillName">Graphic Designer</SkillName>
              </SkilledDiv3>
            </SkilledMainDiv>
          </COl>
        </Row1>
      </ColNew>
      <ColNew className="col-lg-12">
        <Row1 className="row">
          <COl className="col-lg-5">
            <DisplayFlex>
              <StyledImg
                src={talentsalesforce}
                alt="Salesforce_Experts"
              ></StyledImg>
              <StyledH5>Salesforce Experts </StyledH5>
            </DisplayFlex>
          </COl>
          <COl className="col-lg-7">
            <SkilledMainDiv>
              <SkilledDiv className="skillDiv ms-2 ">
                <SkillName className="skillName">Administrator</SkillName>
              </SkilledDiv>
              <SkilledDiv1 className="skillDiv ms-2 ">
                <SkillName className="skillName">
                  Functional Consultants
                </SkillName>
              </SkilledDiv1>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">
                  Technical Consultants
                </SkillName>
              </SkilledDiv2>
              <SkilledDiv3 className="skillDiv ms-2">
                <SkillName className="skillName">Developers</SkillName>
              </SkilledDiv3>
              <SkilledDiv4 className="skillDiv ms-2">
                <SkillName className="skillName">
                  Marketing Cloud Experts
                </SkillName>
              </SkilledDiv4>
            </SkilledMainDiv>
          </COl>
        </Row1>
      </ColNew>
      <ColNew className="col-lg-12">
        <Row1 className="row">
          <COl className="col-lg-5">
            <DisplayFlex>
              <StyledImg
                src={talentemailmarketers}
                alt="Email_Marketers"
              ></StyledImg>
              <StyledH5>Email Marketers </StyledH5>
            </DisplayFlex>
          </COl>
          <COl className="col-lg-7">
            <SkilledMainDiv>
              <SkilledDiv className="skillDiv ms-2 ">
                <SkillName className="skillName">Campaign Managers</SkillName>
              </SkilledDiv>
              <SkilledDiv1 className="skillDiv ms-2 ">
                <SkillName className="skillName">
                  Marketing Specialists
                </SkillName>
              </SkilledDiv1>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">Developers</SkillName>
              </SkilledDiv2>
            </SkilledMainDiv>
          </COl>
        </Row1>
      </ColNew>

      <ColNew className="col-lg-12">
        <Row1 className="row">
          <COl className="col-lg-5">
            <DisplayFlex>
              <StyledImg
                src={talentsoftwareengineer}
                alt="Software_Engineers"
              ></StyledImg>
              <StyledH5>Software Engineers </StyledH5>
            </DisplayFlex>
          </COl>
          <COl className="col-lg-7 pe-0">
            <SkilledMainDiv>
              <SkilledDiv className="skillDiv ms-2 ">
                <SkillName className="skillName">Python</SkillName>
              </SkilledDiv>
              <SkilledDiv1 className="skillDiv ms-2 ">
                <SkillName className="skillName">.Net</SkillName>
              </SkilledDiv1>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">Java</SkillName>
              </SkilledDiv2>
              <SkilledDiv3 className="skillDiv ms-2">
                <SkillName className="skillName">DevOps</SkillName>
              </SkilledDiv3>
              <SkilledDiv4 className="skillDiv ms-2">
                <SkillName className="skillName">Azure</SkillName>
              </SkilledDiv4>
              <SkilledDiv className="skillDiv ms-2">
                <SkillName className="skillName">Cloud</SkillName>
              </SkilledDiv>
              <SkilledDiv1 className="skillDiv ms-2">
                <SkillName className="skillName">Data</SkillName>
              </SkilledDiv1>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">Data Science</SkillName>
              </SkilledDiv2>
            </SkilledMainDiv>
          </COl>
        </Row1>
      </ColNew>

      <ColNew className="col-lg-12">
        <Row1 className="row">
          <COl className="col-lg-5">
            <DisplayFlex>
              <StyledImg
                src={talentappdeveloper}
                alt="App_Developers"
              ></StyledImg>
              <StyledH5>App Developers </StyledH5>
            </DisplayFlex>
          </COl>
          <COl className="col-lg-7">
            <SkilledMainDiv>
              <SkilledDiv className="skillDiv ms-2 ">
                <SkillName className="skillName">iOS</SkillName>
              </SkilledDiv>
              <SkilledDiv1 className="skillDiv ms-2 ">
                <SkillName className="skillName">Android</SkillName>
              </SkilledDiv1>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">React Native</SkillName>
              </SkilledDiv2>
            </SkilledMainDiv>
          </COl>
        </Row1>
      </ColNew>
      <ColNew className="col-lg-12">
        <Row1 className="row">
          <COl className="col-lg-5">
            <DisplayFlex>
              <StyledImg
                src={talentqualityanalyst}
                alt="talentqualityanalyst"
              ></StyledImg>
              <StyledH5>Data and QA </StyledH5>
            </DisplayFlex>
          </COl>
          <COl className="col-lg-7">
            <SkilledMainDiv>
              <SkilledDiv className="skillDiv ms-2 ">
                <SkillName className="skillName">Data Engineer</SkillName>
              </SkilledDiv>
              <SkilledDiv1 className="skillDiv ms-2 ">
                <SkillName className="skillName">Data Analyst</SkillName>
              </SkilledDiv1>
              <SkilledDiv2 className="skillDiv ms-2">
                <SkillName className="skillName">Data Scientist</SkillName>
              </SkilledDiv2>
              <SkilledDiv3 className="skillDiv ms-2">
                <SkillName className="skillName">Quality Analyst</SkillName>
              </SkilledDiv3>
            </SkilledMainDiv>
          </COl>
        </Row1>
      </ColNew>
    </Row>
  );
};

export default SkillSection;
