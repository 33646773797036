import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Swal from 'sweetalert2';
import CreatContext from "../../../../context/CreatContext";
const Bgform = styled.div`
background: #F4F8FF;
padding: 41px 25px;
border-radius: 15px;
box-shadow: 0px 0px 4px 0px #00000040;
`;

const FormLabel = styled.span`
font-Family : 'Mulish';
font-weight: 400;
font-size: 18px;
color: #000000;
`;
const LabelSpan = styled.span`
 color : red ;
`;
const StyledInput = styled.input`
font-family: 'Mulish';
border-radius: 10px;
margin-top: 10px;
font-size: 14px!important;
margin-bottom: 15px;
padding: 13px 25px;
border: none;
 &::placeholder {
color:#888888;
}
`;
const Styletextarea = styled.textarea`
font-family: 'Mulish';
border-radius: 10px;
margin-top: 10px;
font-size: 14px!important;
margin-bottom: 15px;
padding: 13px 25px;
border: none;
width:100%;
&:focus {
    outline: none;
    box-shadow: none;
    border-color: #00337C;
    
  } 
 &::placeholder {
color:#888888;
}
`
const BtnContact = styled.button`
background: #00337C;
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    border-radius: 15px;
    padding: 13px 10px;
    border: none;
&:hover{
background: #00337C;
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    border-radius: 15px;
    padding: 13px 10px;
}
&:active{
    background: #00337C;
        width: 100%;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        border-radius: 15px;
        padding: 13px 10px;
    }
`

const ContactForm = () => {
    const context = useContext(CreatContext);
    const { contactUsFormSaveData } = context;

    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [remarks, setRemarks] = useState('');

    const contactUs = (e) => {

        if (firstName === '' ||  email === '' ||  phone === '' || remarks === '') {
            Swal.fire({
                title: 'Please fill all mandatory fields',
                icon: 'warning'
            });
            return false;
        }

        var pattern = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!pattern.test(email)) {
            Swal.fire({
                title: 'Please enter a valid email!',
                icon: 'warning'
            });
            return false;
        }

        if (phone.length < 10) {
            Swal.fire({
                title: 'Please enter a valid phone number!',
                icon: 'warning'
            });
            return false;
        }

        contactUsFormSaveData(firstName,email, phone,remarks);
        clearForm();
    }
    const clearForm = () => {
        setPhone('');
        setEmail('');
        setFirstName('');
        setRemarks('');
    }
    const firstSpaceValidation = (event) => {
        const len = event.target.value.length;
        if (len === 0 && event.charCode === 32) {
            event.preventDefault();
            return false
        }
        return true
    }
    const handleKeyPress = (event) => {
        if (event.keyCode === 8 || (event.charCode >= 48 && event.charCode <= 57)) {
            return true;
        }
        event.preventDefault();
        return false;
    };
    const firstLetterCapitalized = (string) => {
        return (string.charAt(0).toUpperCase() + string.slice(1));
    }
    const spanElementContactUs = document.getElementsByClassName("EmailSpanContactUs")[0];
    const emailFormat = (e) => {
        const emailValue = e.target.value;
        var pattern =
            /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (pattern.test(emailValue) || emailValue === "") {
            spanElementContactUs.textContent = " *";
        } else {
            spanElementContactUs.textContent = " (Please enter valid email)";
        }
    };
    const MobileElementContactUs = document.getElementsByClassName("phoneNumberContactUs")[0];
    const mobileFieldValidation = (e) => {
        const mobileValue = e.target.value;
        if (mobileValue.length === 10 || mobileValue.length === 0) {
            MobileElementContactUs.textContent = " *";
        } else {
            MobileElementContactUs.textContent = " (Max 10 digit allowed)";
        }
    };
    return (
        <Bgform>

            <FormLabel htmlFor="Name" className="form-label">Name<LabelSpan > *</LabelSpan>
            </FormLabel>
            <StyledInput
                type="text"
                onKeyPress={firstSpaceValidation}
                className="form-control  form-control-sm"
                id="Name"
                name="Name"
                value={firstName}
                placeholder='Enter your First Name'
                onChange={(e) => setFirstName(firstLetterCapitalized(e.target.value))}
                required
            />
            <FormLabel htmlFor="email" className="form-label">Email<LabelSpan className='EmailSpanContactUs'> *</LabelSpan>
                </FormLabel>
                <StyledInput
                    type="email"
                    value={email}
                    onKeyPress={firstSpaceValidation}
                    className="form-control  form-control-sm"
                    id="email"
                    name="email"
                    placeholder='Enter your Email'
                    onInput={(e) => emailFormat(e)}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <FormLabel htmlFor="phoneNumber" className="form-label ">Phone no<LabelSpan className='phoneNumberContactUs'> *</LabelSpan>
                </FormLabel>
                <StyledInput
                    type="text"
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyPress={handleKeyPress}
                    onInput={(e) => mobileFieldValidation(e)}
                    value={phone}
                    className="form-control form-control-sm"
                    id="phoneNumber"
                    maxLength="10"
                    name="phoneNumber"
                    placeholder='Enter your Phone Number'
                    required
                />
                {/* <FormLabel htmlFor="address" className="form-label ">Address
                <LabelSpan > *</LabelSpan></FormLabel>
                <StyledInput
                    type="text"
                    className="form-control form-control-sm"
                    name="address"
                    id="address"
                    placeholder='Enter your address'
                    required
                /> */}
                <FormLabel htmlFor="Message" className="form-label ">Message<LabelSpan className='phoneNumberContactUs'> *</LabelSpan></FormLabel>
                <Styletextarea onKeyPress={firstSpaceValidation} id="Message" name="w3review" rows="5" cols="45" value={remarks} placeholder='Write a message if any' onChange={(e) => setRemarks(e.target.value)} required></Styletextarea>

                <BtnContact type="submit" onClick={(e) => contactUs(e)}>Submit</BtnContact>



        </Bgform>
    )
}

export default ContactForm