import React from 'react';

// import images
import spin from '../../images/spin.gif';

const LoaderGif = () => {

    return (
        <>
            <div id="loader" style={{ position: 'fixed', width: '100vw', height: '100vh',top: '0', alignItems: 'center', backgroundColor: '#6e6e6e', zIndex: '999', opacity: '.7' }}>
                <div style={{ position: 'fixed', left: '45%', top: '45%' }}>
                    <img src={spin} alt='Loading...' />
                </div>
            </div>
        </>
    )
}

export default LoaderGif;