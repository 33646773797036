import React from 'react';
import styled from 'styled-components';
import contact from "../../../../src/images/Rectangle 23840.png";
import talk from "../../../../src/images/Frame 1000005801.png";
import { Link } from "react-router-dom";
const Container = styled.div``
const StyledTextOne = styled.p`
color: #1672B5;
    font-size: 14px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    padding-top: 130px;
    text-align: center;
`
const StyledTextBold = styled.p`
color: #FFF;
text-align: center;
font-size: 20px;
font-family: Mulish;
font-style: normal;
font-weight: 700;
line-height: 25px;
`
const StyledH1 = styled.h1`
color: #FFF;
text-align: center;
font-size: 23px;
font-family: Mulish;
font-style: normal;
font-weight: 700;
line-height: 25px;
`

const StyledTextSmall = styled.p`
color: #FFF;
text-align: center;
font-size: 16px;
font-family: Mulish;
font-style: normal;
font-weight: 700;
line-height: 25px;
`
const StyledDiv = styled.div`
position:relative;
`
const StyledTextDiv = styled.div`
position:absolute;
width:100%;
`
const StyledImage = styled.img`
width:100%;
`
const StyledImageTwo = styled.img`
width: 50px;
    height: 38px;
    margin-bottom: 2px;
`
const StyledButton = styled.button`
color: #000;
text-align: center;
font-family: Mulish;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 36px;
background: #FFF;
border:none;
`
const StyledDivTwo = styled.div`
display:flex;
justify-content:center;
`
export const ContactSection = () => {
  return (
    <Container>
      <StyledDiv>
        <StyledTextDiv>
          <StyledTextOne>Contact us now</StyledTextOne>
          <StyledH1>Have any questions?</StyledH1>
          <StyledTextBold>0129 488 2321</StyledTextBold>
          <StyledTextSmall>or go to contact form:</StyledTextSmall>
          <StyledDivTwo>
            <Link to="/contact" onClick={() => {window.scrollTo(0,0)}}>
              <StyledButton>Let’s start now</StyledButton>
              <StyledImageTwo src={talk} />
            </Link>
          </StyledDivTwo>
        </StyledTextDiv>
        <StyledImage src={contact} />
      </StyledDiv>
    </Container>
  )
}
