import styled, { keyframes } from 'styled-components';
import backgroundImg from "../../../../images/backgroundimg.png"

export const BGbanner = styled.div`
height: 100%;
background-repeat: no-repeat;
font-family: 'Mulish';
background-image: url(${backgroundImg});
background-size: cover;
@media (max-width: 768px) {
   & .imgClassCss{
      margin-top: 30px;
      margin-bottom: 30px;
   }
 }
`;

export const StyleDiv = styled.div`
display:flex;
justify-content:center;
padding-top:151px;
padding-bottom:345px;
`;

export const StyleH5 = styled.h1`
font-size: 33px;
font-weight: 700;
color: #fff;
margin-bottom: 25px;
`;

export const StylePara = styled.p`
color: #fff;
font-size: 16px;
font-weight: 400;
font-style: normal;
font-family: 'Mulish';
`;

export const ColDiv = styled.div`
padding-left:127px;
padding-right:0px;
@media (max-width: 768px) {
   padding-left:20px;
padding-right:20px;
 }
`;

export const Stylespan = styled.span`
display:block;
font-size:30px;
font-weight:700;
margin-left: 6px;

@media (max-width: 768px) {
   font-size:12px;
   margin-left: 1px;
}
`;

export const Discuss_btn = styled.button`
margin-top: 20px;
background: #54D7EC;
color: #062A6D;
border-radius: 10px;
border: 1px solid #54D7EC;
padding:12px 40px 12px 19px;
font-weight:400;
position:relative;

&::before {
  content: '';
  position: absolute;
  top: 20px;
  right: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #062A6D;
}`;

export const Animationdiv1 = styled.div`
  display: flex;
//   justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 308px;
  padding-left: 120px;
  padding-top: 250px;
  overflow-x: hidden;

  @media (max-width: 768px) {
margin-bottom: 100px;
  padding-left: 20px;
  padding-top: 200px;
  }
`;

const slide_img = keyframes`
0%{
    transform: translateX(-100%);
    opacity: 4;
    z-index: 2;
}
25% {
   transform: translateX(300%);
   opacity: 4;
   z-index: 2;
}
50% {
   transform: translateX(-40%);
   opacity: 4;
   z-index: 2;
}
60% {
   transform: translateX(0%);
   opacity: 4;
   z-index: 2;
}
80% {
   transform: translateX(0%);
   opacity: 4;
   z-index: 2;
}
75% {
   transform: translateX(0%);
   opacity: 4;
   z-index: 2;
}
100% {
    transform: translateX(0%);
    opacity: 4;
    z-index: 2;
}
`;

export const Animation1 = styled.div`
color:#fff;
animation: ${slide_img}  6s linear infinite;

& img {
    width: 150px;
    height: 150px;
}

@media (max-width: 768px) {
   & img {
      width: 50px;
      height:50px;
  }
}
`;

const slide_text = keyframes`
0% {
   transform: translateX(-600%);
   opacity: 0;
}
20% {
   transform: translateX(-300%);
   opacity: 1;
}
40% {
   transform: translateX(10%);
   opacity: 1;
}
60% {
   transform: translateX(0%);
   opacity: 1;
}
75% {
   transform: translateX(0%);
   opacity: 1;
}
100% {
    transform: translateX(0%);
    opacity: 4;
}
`;

export const Animation2 = styled.div`
overflow-x: hidden;
color:#fff;
white-space: nowrap;
animation: ${slide_text}  6s linear infinite;

& h1 {
    font-size:100px;
    font-weight:800;
}

@media (max-width: 768px) {
   & h1 {
      font-size:28px;
      font-weight:800;
  }
}

`;

export const Animation3 = styled.div`

`;