import React, { useContext, useEffect, useState } from 'react';
import CreatContext from '../../../../../context/CreatContext';
import { Link } from "react-router-dom";
import BlogDetailCards from './BlogDetailCards';
import BlogDetailbanner from "./BlogDetailbanner";
import { useNavigate } from 'react-router';

// import { async } from 'q';

const BlogDetail = (props) => {
  const navigate = useNavigate();
  const context = useContext(CreatContext);
  const { blogpagedata, setActiveLink, blogUniqueUrlId } = context;


  function formatDate(createddt) {
    const dateObj = new Date(createddt);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();


    return `${padZero(day)}/${padZero(month)}/${year}`;
  }

  function padZero(number) {
    return number.toString().padStart(2, '0');
  }

  const openBlogDetail1 = (blogUniqueUrl, metaDescription, metaTag) => {
    window.open(`/blog/${blogUniqueUrl}`, `/blog/${blogUniqueUrl}`);
    // window.open(`/blogDetailMain?=${post_id}`, `/blogDetailMain?=${post_id}`)
    // document.getElementsByTagName("META")[3].content = `${metaDescription}`;
    // document.getElementsByTagName("META")[4].content = `${metaTag}`;
    // callByUrlBlog();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // document.getElementsByTagName("META")[3].content = "Explore our collection of insightful and engaging blog articles on recruitment, technology, innovation, and more. Stay up-to-date with the latest trends and discoveries. Discover thought-provoking content from industry experts at RarrTech";
  }, []);



  return (
    <div className='blogDetail-section'>
       {
        blogpagedata.filter((item) => {
          if (item.blog_unique_url === blogUniqueUrlId) {
            return item;
          }
        }).map((item, i) => {
          return (<BlogDetailbanner formatDate={formatDate} key={i} item={item} />)
        })
      }
      {blogpagedata.length > 0 ? (
      <div className='container'>
        <h1 className="LatestPost" >Latest Post</h1>

        <div className='blogCard-section row'>
          {
            blogpagedata
              .filter((item) => item.blog_unique_url !== blogUniqueUrlId).slice(0, 3)
              .map((item, i) => (
                <BlogDetailCards
                  key={i}
                  item={item}
                  formatDate={formatDate}
                  openBlogDetail1={openBlogDetail1}
                />
              ))
          }
        </div>
        <div style={{ textAlign: 'center' }}>
          <Link style={{padding:'10px'}} className='blogViewAll-btn ' to="/blog/" onClick={() => { setActiveLink("#BlogMainSection"); window.scrollTo(0, 0) }} >View All</Link>
        </div>
      </div>
      ) : (
        null
      )}

    </div>
  )
}

export default BlogDetail
