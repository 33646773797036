import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MsmeLogo from "../../../images/msmeLogo.png";
import StartupLogo from "../../../images/startupLogo.png";
import IndexOne from "../../../images/index 1.png";
import Microsoft from "../../../images/microsoft.png";
import { styled } from 'styled-components'

const ImgLogo = styled.img`
 width : 200px ;
`
const MainSection = styled.section`
font-family : "Mulish" ;
margin : 129px 0px  0px 0px ;
@media (max-width: 768px) {
  margin : 50px 0px  0px 0px ;
}
`
const Container = styled.div`
& .mobileViewRec{
  display:none;
}
@media (max-width: 768px) {
  & .mobileViewRec{
    display:block;

  }
}
`
const RecognitionH1 = styled.h1`
color: #010851;
font-size : 40px;
font-weight: 800;
@media (min-width: 320px) and (max-width: 767px){
  font-size:32px;
}
`
const Institution = styled.span`
color: #63657E;
@media (max-width: 768px) {
  padding-left: 5px;
}
`
const ImgDiv = styled.div`
margin : 20px  0px;
@media (max-width: 768px) {
  display:none;
}
`;

const CarouselDiv = styled.div`
 & img {
    object-fit: contain;
    width:100%;
 }
 `;

const StyledCarousel = styled(Carousel)`
  .react-multiple-carousel__arrow {
    display: none;
  }
  
`;
const Card = styled.div`
display: flex;
justify-content: center;
align-items: center;
    height: 200px;
    border-radius: 12px;
    margin: 10px 10px 20px 10px;
    padding: 0px 15px 0px 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-color: azure;
`;

const Recognition = () => {
  const images = [MsmeLogo, StartupLogo, IndexOne, Microsoft];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <MainSection >
        <Container className="container">
          <div style={{ paddingLeft: '11px' }}>
            <RecognitionH1>  Our Recognitions  </RecognitionH1>
            <Institution>
              From the renowned Institutions
            </Institution>
            <ImgDiv className="container">
              <div className="row align-items-baseline">
                <div className="col-lg-3">
                  <ImgLogo alt="MSME logo" src={MsmeLogo} />
                </div>
                <div className="col-lg-3 ">
                  <ImgLogo alt="startupindia" src={StartupLogo} />
                </div>
                <div className="col-lg-3 ">
                  <ImgLogo alt="IIML incubator" src={IndexOne} />
                </div>
                <div className="col-lg-3 ">
                  <ImgLogo alt="microsoft" src={Microsoft} />
                </div>
              </div>
            </ImgDiv>

            <div className="mobileViewRec">

              <CarouselDiv className="col-lg-12">

                <StyledCarousel
                  autoPlay={true}
                  autoPlaySpeed={2000}
                  infinite={true}
                  responsive={responsive}
                >
                  {images.map((image, index) => (
                    <Card className="card">
                      <div key={index}>
                        <img
                          src={image}
                          alt={`Slide ${index + 1}`}
                        />
                      </div>
                    </Card>
                  ))}
                </StyledCarousel>

              </CarouselDiv>
            </div>
          </div>
        </Container>
      </MainSection >
    </>
  )
}

export default Recognition
