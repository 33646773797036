import React, { useState } from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share';
import styled from 'styled-components';

import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Tooltip from '@mui/material/Tooltip';

const OutSideStyle = styled.div`
display:flex;
// flex-wrap: wrap;
// justify-content: space-between;

@media (max-width: 768px) {
    display: flex;
    justify-content: inherit;
    margin-top: 0px;
}
`;

const FacebookStyle = styled(FacebookShareButton)`
cursor: pointer;
// background:#1177f0 !important;
padding: 5px 10px 5px 8px !important;
// margin-bottom: 10px;
border-radius:8px;
display:flex;
& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
margin-left: 0px;
font-size: 24px;
color: #1177f0;
}
& .mobileViewStyledShare{
    color: #fff;
    margin-left: 5px;
    font-size: 14px;
}

@media (max-width: 768px) {

     & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
        margin-left: 0px;
        margin-top: 10px;
        font-size: 20px;
        color: #1177f0;
        }
}
`;
const TwitterStyle = styled(TwitterShareButton)`
cursor: pointer;
// background:#2c67b3 !important;
padding: 5px 10px 5px 8px !important;
// margin-bottom: 10px;
border-radius:8px;
display:flex;
& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
margin-left: 0px;
font-size: 24px;
color: #2c67b3;
}
& .mobileViewStyledShare{
    color: #fff;
    margin-left: 5px;
    font-size: 14px;
}

@media (max-width: 768px) {
    
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
        margin-left: 0px;
        margin-top: 10px;
        font-size: 20px;
        color: #2c67b3;
        }
}
`;
const LinkedinStyle = styled(LinkedinShareButton)`
cursor: pointer;
// background:#229af0 !important;
padding: 5px 10px 5px 8px !important;
// margin-bottom: 10px;
border-radius:8px;
display:flex;
& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
margin-left: 0px;
font-size: 24px;
color: #229af0;
}
& .mobileViewStyledShare{
    color: #fff;
    margin-left: 5px;
    font-size: 14px;
}

@media (max-width: 768px) {
  
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
        margin-left: 0px;
        margin-top: 10px;
        font-size: 20px;
        color: #229af0;
        }
}
`;
const WhatsappStyle = styled.div`
cursor: pointer;
// background:#29cf47 !important;
padding: 5px 0px 5px 0px !important;
// margin-bottom: 10px;
border-radius:8px;
display:flex;
& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
margin-left: 0px;
font-size: 24px;
color: #29cf47;
}

& .mobileViewStyledShare{
    color: #fff;
    margin-left: 5px;
    font-size: 14px;
}

@media (max-width: 768px) {
    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
        margin-left: 0px;
        font-size: 20px;
        margin-top: 10px;
        color: #29cf47;
        }
}
`;

const SocialShare = ({ url, title }) => {

    const shareMessage = ``;

    const [openWhatsApp, setOpenWhatsApp] = useState(false);
    const [openLinkedIn, setOpenLinkedIn] = useState(false);
    const [openFacebook, setOpenFacebook] = useState(false);
    const [openTwitter, setopenTwitter] = useState(false);

    const handleTooltipWhatsAppClose = () => {
        setOpenWhatsApp(false);
    };

    const handleTooltipWhatsAppOpen = () => {
        setOpenWhatsApp(true);
    };

    const handleTooltipLinkedInClose = () => {
        setOpenLinkedIn(false);
    };

    const handleTooltipLinkedInOpen = () => {
        setOpenLinkedIn(true);
    };

    const handleTooltipFacebookClose = () => {
        setOpenFacebook(false);
    };

    const handleTooltipFacebookOpen = () => {
        setOpenFacebook(true);
    };

    const handleTooltipTwitterClose = () => {
        setopenTwitter(false);
    };

    const handleTooltipTwitterOpen = () => {
        setopenTwitter(true);
    };

    const openWhatsAppApiCall = () => {
        window.open(`https://api.whatsapp.com/send?text=${url}`)
    }

    return (
        <OutSideStyle>
            <WhatsappStyle onClick={openWhatsAppApiCall} onMouseEnter={handleTooltipWhatsAppOpen} onMouseLeave={handleTooltipWhatsAppClose}>
                <Tooltip arrow
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipWhatsAppClose}
                    open={openWhatsApp}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Share on WhatsApp"
                    placement="top"
                >
                    <WhatsAppIcon />
                    {/* <div className='mobileViewStyledShare'>
                    Share on WhatsApp
                </div> */}
                </Tooltip>
            </WhatsappStyle>
            <LinkedinStyle url={window.location.href} title={title} onMouseEnter={handleTooltipLinkedInOpen} onMouseLeave={handleTooltipLinkedInClose}>
                <Tooltip arrow
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipLinkedInClose}
                    open={openLinkedIn}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Share on LinkedIn"
                    placement="top"
                >
                    <LinkedInIcon />
                    {/* <div className='mobileViewStyledShare'>
                    Share on LinkedIn
                </div> */}
                </Tooltip>
            </LinkedinStyle>
            <FacebookStyle url={window.location.href} quote={title} onMouseEnter={handleTooltipFacebookOpen} onMouseLeave={handleTooltipFacebookClose}>
                <Tooltip arrow
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipFacebookClose}
                    open={openFacebook}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Share on Facebook"
                    placement="top"
                >
                    <FacebookOutlinedIcon />
                    {/* <div className='mobileViewStyledShare'>
                    Share on Facebook
                </div> */}
                </Tooltip>
            </FacebookStyle>
            <TwitterStyle url={window.location.href} title={title} onMouseEnter={handleTooltipTwitterOpen} onMouseLeave={handleTooltipTwitterClose}>
                <Tooltip arrow
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipTwitterClose}
                    open={openTwitter}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Share on Twitter"
                    placement="top"
                >
                    <TwitterIcon />
                    {/* <div className='mobileViewStyledShare'>
                    Share on Twitter
                </div> */}
                </Tooltip>
            </TwitterStyle>
        </OutSideStyle>
    );
};

export default SocialShare;
