import React, { useContext, useEffect, useState } from 'react';
import CreatContext from '../../../../../context/CreatContext';
import BlogDetailCards from '../blogmain/BlogDetailCards';
import BlogBannerSection from "../detailwithBanner/BlogBannerSection";
import { useNavigate } from 'react-router';
import bgBlue from "../../../../../images/banner-6.jpg";
// import { async } from 'q';

const BlogDetail = () => {
  const navigate = useNavigate();
  const context = useContext(CreatContext);
  const { blogpagedata, postUrl, blogUniqueUrlId } = context;

  function formatDate(createddt) {
    const dateObj = new Date(createddt);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();


    return `${padZero(day)}/${padZero(month)}/${year}`;
  }

  function padZero(number) {
    return number.toString().padStart(2, '0');
  }

  const openBlogDetail1 = (blogUniqueUrl, metaDescription, metaTag) => {
    window.open(`/blog/${blogUniqueUrl}`, `/blog/${blogUniqueUrl}`);
    // navigate(`/blogDetailMain?=${post_id}`);
    // document.getElementsByTagName("META")[3].content = `${metaDescription}`;
    // document.getElementsByTagName("META")[4].content = `${metaTag}`;
    // callByUrlBlog();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    document.getElementsByTagName("META")[3].content = "Explore our collection of insightful and engaging blog articles on recruitment, technology, innovation, and more. Stay up-to-date with the latest trends and discoveries. Discover thought-provoking content from industry experts at RarrTech";
  }, []);



  return (
    <div className='blogDetail-section' style={{marginTop:'100px'}}>
      {blogpagedata
        .filter((item, i) => {
          if (i === 0) {
            return item;
          }
        })
        .map((item, i) => {
          return (
            <BlogBannerSection key={i} item={item} formatDate={formatDate} openBlogDetail1={openBlogDetail1} />
          );
        })}
       
      <div className='container'>
      <h1 className="LatestPost" >Latest Post</h1>

        <div className='blogCard-section row'>

          {
            blogpagedata.filter((item) => {
              if (parseInt(item.blog_unique_url) !== blogUniqueUrlId) {
                return item;
              }
            }).filter((item, i) => i > 0).map((item, i) => {
              return (<BlogDetailCards key={i} item={item} formatDate={formatDate} openBlogDetail1={openBlogDetail1} />)
            })
          }
        </div>
      </div>

    </div>
  )
}

export default BlogDetail
