import React, { useContext } from 'react'
import styled from 'styled-components';
import CreatContext from "../../../context/CreatContext";
import { Link } from 'react-router-dom';

// import images
import FrameLineImg from '../../../images/FrameLine.png'
import FrameLineImg2 from '../../../images/FrameLine2.png'
import SleepingLine from '../../../images/SleepingLine.png'
import BulletImg from '../../../images/Bullets.png'
import HoverBulletImg from '../../../images/Hoverbullet.png'


const StyleDiv = styled.div``;
const MainSection = styled.section`
margin : 90px 0px 0px 0px ;
@media (max-width: 768px) {
  margin : 40px 0px 0px 0px ;
}
`;

const Coldiv = styled.div`
height:455px;
background-image:url(${FrameLineImg});
background-repeat: no-repeat;
background-position: right;
padding-bottom: 40px;
padding-left: 23px;
padding-right: 23px;
background-size: contain;
&:last-child {
  background-image:none;
}
@media (max-width: 768px) {
  background-image:none;
  padding-right: 14px;
  height:auto;

  & .displayFlexImg{
    display:flex;
  }

  & .marginTopImg{
    margin-top: -49px;
  }
  & .marginBottomImg{
    margin-bottom: 25px;
  }

}
`;

const ImgLine = styled.div`
display:none;
@media (max-width: 768px) {
  display:block;
  background-image:url(${FrameLineImg});
background-repeat: no-repeat;
padding-left: 20px;
padding-right: 20px;
height: auto;
}
`;

const ImgLine_2 = styled.div`
display:none;
@media (max-width: 768px) {
  display:block;
  background-image:url(${FrameLineImg});
background-repeat: no-repeat;
padding-left: 20px;
padding-right: 0px;
background-position: top;
height: auto;
}
`;

const ImgLineRotate = styled.div`
display:none;
@media (max-width: 768px) {
  display:block;
  background-image:url(${SleepingLine});
background-repeat: no-repeat;
padding-left: 20px;
padding-right: 20px;
height: 10px;
width: auto;
margin-top: -6px;
margin-right: 5px;
    margin-left: 1px;
    background-position: inherit;
}
`;


const BgSleeping = styled.div`
background-image:url(${SleepingLine});
z-index: 999;
    position: absolute;
    bottom: -2px;
    background-repeat: no-repeat;
    display: flex;
    padding: 7px 0px 6px 0px;
    width: 100%;
    background-size: contain;
    margin-bottom: -2px;
    @media (max-width: 768px) {
      background-image:none;
    }
`;

const Card1 = styled.div`
height:486px;
background-image:url(${FrameLineImg2});
background-repeat: no-repeat;
background-position: right top 0px;
padding-bottom: 40px;
padding-left: 23px;
padding-right: 23px;
margin-top: -12px;
background-size: contain;

& .heightSpace{
  height: 422px;
}

&:last-child {
  background-image: none;
}
@media (max-width: 768px) {
  height:auto;
  position:relative;
  background-image:none;
  padding-right: 14px;

  & .displayFlexImg{
    display:flex;
  }

  & .marginTopImg{
    margin-top: -38px;
  }
  & .marginBottomImg{
    margin-bottom: 25px;
  }

  & .imgDot{
    height:9px;
    width:9px;
    position:absolute;
    left: 23px;
    bottom: 40px;
    background-color:#00acee;
    border-radius:50%;
  }
  & .imgDot1{
    height:9px;
    width:9px;
    position:absolute;
    right: 20px;
    bottom: 40px;
    background-color:#00acee;
    border-radius:50%;
  }
}
`;
const Midline = styled.div`
position:relative;
`;

const H5tag = styled.h2`
    padding-top: 30px;
    color: #010851;
    font-size: 40px;
    font-weight: bold;
    font-family: 'Mulish';
    margin-bottom: 30px;
    @media (min-width: 320px) and (max-width: 767px){
      font-size: 30px;
    }
`;
const Ptag = styled.p`
font-size:20px;
font-weight:700;
color: #010851;
@media (min-width: 320px) and (max-width: 767px){
  font-size:17px;
}
`;
const Hpink = styled.h5`
color:#FD79AF;
font-size:72px;
font-weight:500;
padding-top: 30px;
margin-bottom: 40px;
@media (min-width: 320px) and (max-width: 767px){
  font-size:60px;
}
`;
const Hblue = styled.h5`
color: #00ADEF;
font-size:72px;
font-weight:500;
padding-top: 30px;
margin-bottom: 40px;
@media (min-width: 320px) and (max-width: 767px){
  font-size:60px;
}
`;
const Horange = styled.h5`
color: #F1B14D;
font-size:72px;
font-weight:500;
padding-top: 30px;
margin-bottom: 40px;
@media (min-width: 320px) and (max-width: 767px){
  font-size:60px;
}
`;
const HMediumAqua = styled.h5`
color: #0EAFBC;
font-size:72px;
font-weight:500;
padding-top: 30px;
margin-bottom: 40px;
@media (min-width: 320px) and (max-width: 767px){
  font-size:60px;
}
`
const Hslateblue = styled.h5`
color: #755ECE;
font-size:72px;
font-weight:500;
padding-top: 30px;
margin-bottom: 40px;
@media (min-width: 320px) and (max-width: 767px){
  font-size:60px;
}
`


const Ptagbold = styled.p`
font-size:24px;
font-weight:600;
color:#090F4E;
font-family: 'Mulish';
`;
const Psmall = styled.p`
color: #000;
font-size: 16px;
font-family: Mulish;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
margin-bottom: 40px;
padding-right: 17px;
`;
const Stylespan = styled.span`
display:block;
`
const StyledRelative = styled.div`

`
const StyledLink = styled(Link)`
text-decoration: none;
font-size: 12px;
color: #062A6E;
font-weight: 400;
background-image:url(${BulletImg});
background-repeat: no-repeat;
background-position: top 11px right;
padding: 6px 14px;
border-radius: 18px;
transition: background-image 0.10s, background-color 0.2s;
&:hover {
  background-image: url(${HoverBulletImg});
  background-color: #062A6E;
  color:#fff;
  padding: 9px 43px 12px 20px;
  border-radius: 10px;
  background-position: top 8px right 14px;
  background-size: 20px;
}

@media (min-width: 320px) and (max-width: 767px){
  & .btn-bottom-rec{
    bottom: 55px !important;
  }
}
`;

const StyledLinkTwo = styled(Link)`
text-decoration: none;
font-size: 12px;
color: #062A6E;
font-weight: 400;
background-image:url(${BulletImg});
background-repeat: no-repeat;
background-position: top 11px right;
padding: 6px 14px;
border-radius: 18px;
transition: background-image 0.10s, background-color 0.2s;
&:hover {
  background-image: url(${HoverBulletImg});
  background-color: #062A6E;
  color:#fff;
  padding: 9px 43px 12px 20px;
  border-radius: 10px;
  background-position: top 8px right 14px;
  background-size: 20px;
}


`;
const StyledLinkThree = styled(Link)`
text-decoration: none;
font-size: 12px;
color: #062A6E;
font-weight: 400;
background-image:url(${BulletImg});
background-repeat: no-repeat;
background-position: top 11px right;
padding: 6px 14px;
border-radius: 18px;
transition: background-image 0.10s, background-color 0.2s;
&:hover {
  background-image: url(${HoverBulletImg});
  background-color: #062A6E;
  color:#fff;
  padding: 9px 43px 12px 20px;
  border-radius: 10px;
  background-position: top 8px right 14px;
  background-size: 20px;
}


`;


const BridgeSection = () => {
  const context = useContext(CreatContext);
  const { activeLink, setActiveLink } = context;
  return (
    <>
      <MainSection id="BridgeSection">
        <StyleDiv className='container mb-3'>
          <div className='row'>
            <Coldiv className='col-lg-3'>
              <div className="displayFlexImg">
                <ImgLine />
                <div style={{ width: '100%' }}>
                  <H5tag>Bridge the Gap</H5tag>
                  <Ptag>Between Business and Technology</Ptag>
                </div>
                <ImgLine_2 />
              </div>
              <ImgLineRotate />
            </Coldiv>

            <Coldiv className='col-lg-3'>
              <div className="displayFlexImg marginTopImg">
                <ImgLine />
                <div className='marginBottomImg'>
                  <div className='heightSpace'>
                    <Hpink>01</Hpink>
                    <Ptagbold>Microsoft Solutions</Ptagbold>
                    <Psmall>Unlock the power of Microsoft's suite of solutions and elevate your business. Streamline operations, boost collaboration, and drive innovation with our expertly tailored Microsoft services. </Psmall>
                  </div>
                  <div>
                    <StyledRelative><StyledLink to='/microsoft-solutions/ ' onClick={() => {
                      setActiveLink("#BridgeSection");
                      window.scrollTo(0, 0);
                    }}>Learn More</StyledLink></StyledRelative>
                  </div>
                </div>
                <ImgLine_2 />
              </div>
              <ImgLineRotate />
            </Coldiv>

            <Coldiv className='col-lg-3'>
              <div className="displayFlexImg marginTopImg">
                <ImgLine />
                <div className='marginBottomImg'>
                  <div className='heightSpace'>
                    <Hblue>02</Hblue>
                    <Ptagbold>Coconut  ERP</Ptagbold>
                    <Psmall style={{ marginBottom: '59px' }}>With Coconut ERP you get Industry 4.0 compatible ERP. Now you do have visibility of all of your plants and sites with single intelligent and smart age dashboard.</Psmall>
                  </div>
                  <div>
                    <StyledLink to='/coconut-erp/' onClick={() => {
                      setActiveLink("#BridgeSection");
                      window.scrollTo(0, 0);
                    }} >Learn More</StyledLink>
                  </div>
                </div>
                <ImgLine_2 />
              </div>
              <ImgLineRotate />
            </Coldiv>

            <Coldiv className='col-lg-3'>
              <div className="displayFlexImg marginTopImg">
                <ImgLine />
                <div className='marginBottomImg'>
                  <div className='heightSpace'>
                    <Horange>03</Horange>
                    <Ptagbold>atsMantra</Ptagbold>
                    <Psmall>Case is defined in businesses as one of issues to be handled. With Caseio Enterprises can resolve complex issues and ensure that optimal solutions are put together.</Psmall>
                  </div>
                  <div>
                    <StyledLink to='/atsmantra/' onClick={() => {
                      setActiveLink("#BridgeSection");
                      window.scrollTo(0, 0);
                    }}>Learn More</StyledLink>
                  </div>
                </div>
                <ImgLine_2 />
              </div>
              <ImgLineRotate />
            </Coldiv>
          </div>

          <Midline>
            <BgSleeping></BgSleeping>
          </Midline>
          <div className='row'>
            <Card1 className='col-lg-3 Recruitment-btn-position'>
              <div className="displayFlexImg marginTopImg">
                <ImgLine />
                <div className='marginBottomImg'>
                  <div className='heightSpace'>
                    <HMediumAqua>04</HMediumAqua>
                    <Ptagbold>Recruitment Services</Ptagbold>
                    <Psmall style={{ marginTop: '50px' }}>Revolutionize your hiring process with our expert recruitment solutions. We'll find your perfect dream team while you stay focused on your core business.</Psmall>
                  </div>
                  <div>
                    <StyledLink to='/recruitment-services/' onClick={() => {
                      setActiveLink("#BridgeSection");
                      window.scrollTo(0, 0);
                    }} className='btn-bottom-rec'>Learn More</StyledLink>
                  </div>
                </div>
                <ImgLine_2 />
              </div>
              <ImgLineRotate />
            </Card1>

            <Card1 className='col-lg-3'>
              <div className="displayFlexImg marginTopImg">
                <ImgLine />
                <div className='marginBottomImg'>
                  <div className='heightSpace'>
                    <Hslateblue>05</Hslateblue>
                    <Ptagbold>Database Design & Development</Ptagbold>
                    <Psmall style={{ marginBottom: '58px' }}>RARR Database Specialists focus on providing potential outcomes with the complex needs of organizations. Potent IT services for optimized data storage & management.</Psmall>
                  </div>
                  <div>
                    <StyledRelative><StyledLinkThree to='/database-design-and-development/' onClick={() => {
                      setActiveLink("#BridgeSection");
                      window.scrollTo(0, 0);
                    }}>Learn More</StyledLinkThree></StyledRelative>
                  </div>
                </div>
                <ImgLine_2 />
              </div>
              <ImgLineRotate />
            </Card1>

            <Card1 className='col-lg-3'>
              <div className="displayFlexImg marginTopImg">
                <ImgLine />
                <div className='marginBottomImg'>
                  <div className='heightSpace'>
                    <Horange>06</Horange>
                    <Ptagbold>Application <Stylespan>development</Stylespan></Ptagbold>
                    <Psmall style={{ marginBottom: '58px' }}>RARR Application developer focuses on developing tailor-made mobile applications using top notch technologies. mobile app development with expert analysis and UI/EX design.</Psmall>
                  </div>
                  <div>
                    <StyledLink to='/application-development/' onClick={() => {
                      setActiveLink("#BridgeSection");
                      window.scrollTo(0, 0);
                    }}>Learn More</StyledLink>
                  </div>
                </div>
                <ImgLine_2 />
              </div>
              <ImgLineRotate />
            </Card1>

            <Card1 className='col-lg-3'>
              <div className="displayFlexImg marginTopImg">
                <ImgLine />
                <div className='marginBottomImg'>
                  <div className='heightSpace'>
                    <Hblue>07</Hblue>
                    <Ptagbold>UI/UX Design</Ptagbold>
                    <Psmall style={{ marginTop: '50px' }}>Elevate your digital presence with our exceptional UI/UX design service. We craft captivating interfaces that engage users, prioritize seamless interactions, and ensure responsive design across devices.</Psmall>
                  </div>
                  <div>
                    <StyledLink to='/ui-ux-designing/' onClick={() => {
                      setActiveLink("#BridgeSection");
                      window.scrollTo(0, 0);
                    }} >Learn More</StyledLink>
                  </div>
                </div>
                <ImgLine_2 />
              </div>
              <div className='imgDot' />
              <div className='imgDot1' />
              {/* <ImgLineRotate /> */}
            </Card1>

          </div>
        </StyleDiv>
      </MainSection>
    </>
  )
}

export default BridgeSection