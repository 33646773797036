import React, { useState } from 'react'
import styled from 'styled-components';
import SalesMarketing from './SalesMarketing';
import Service from "./Service";
import FinanceOperator from "./FinanceOperator";
import SupplyChain from "./SupplyChain";

const StyledP = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-align: justify;

    @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
  }
`

const StyledH4 = styled.h1`
    font-size: 18px;
    color: black;
    font-weight: 700;
    margin-top : 30px;
    font-family: 'Mulish';
`
const StyledH5 = styled.h4`
    font-size: 18px;
    color: black;
    font-weight: 700;
    margin-top : 30px;
    font-family: 'Mulish';
`

const Dynamic = () => {
    const [activeTab, setActiveTab] = useState(1); 

    const handleTabClick = (tabIndex) => {
      setActiveTab(tabIndex);
    };
    return (
        <>
           
            <StyledP className="mt-4">
                Microsoft Dynamics 365 is a powerful suite of business applications that combines CRM (Customer Relationship Management) and ERP (Enterprise Resource Planning) functionalities into a single, unified platform. Designed to help organizations of all sizes and industries, Dynamics 365 provides a range of intelligent tools and solutions to streamline business processes, drive customer engagement, and enable data-driven decision-making.
            </StyledP>
            <StyledP>
                With Dynamics 365, businesses can effectively manage their sales, customer service, marketing, and field service operations. The CRM modules empower organizations to build strong customer relationships, track leads, manage opportunities, and deliver exceptional customer experiences. Through integrated marketing automation capabilities, businesses can create targeted campaigns, nurture leads, and measure marketing performance.
            </StyledP>
            <StyledP>
                On the ERP side, Dynamics 365 offers comprehensive solutions for financial management, supply chain management, manufacturing, human resources, and more. These modules enable organizations to optimize their financial operations, streamline supply chain processes, improve manufacturing efficiency, and effectively manage their workforce.
            </StyledP>
            <StyledP>
                Dynamics 365 leverages the power of Microsoft Azure and integrates seamlessly with other Microsoft products and services, providing a unified and intelligent ecosystem. It harnesses the capabilities of artificial intelligence (AI), machine learning (ML), and advanced analytics to unlock valuable insights from data, automate processes, and deliver personalized experiences.
            </StyledP>
            <StyledP>
                The cloud-based nature of Dynamics 365 offers flexibility, scalability, and security. Businesses can access their applications and data from anywhere, on any device, and easily scale their resources as their needs evolve. Built-in security measures and compliance certifications ensure the protection of sensitive information and adherence to industry regulations.
            </StyledP>
            <StyledP >
                Overall, Microsoft Dynamics 365 is a comprehensive business solution that empowers organizations to transform their operations, enhance customer engagement, and achieve their business goals with agility and intelligence.
            </StyledP>
            <StyledH5 >
                List of Products (Function Wise)
            </StyledH5>


            <div className="vertical-tabs-container">
                <div className="vertical-tabs">
                    <button
                        className={activeTab === 1 ? 'active' : ''}
                        onClick={() => handleTabClick(1)}
                    >
                       Sales and marketing
                    </button>
                    <button
                        className={activeTab === 2 ? 'active' : ''}
                        onClick={() => handleTabClick(2)}
                    >
                       Service
                    </button>
                    <button
                        className={activeTab === 3 ? 'active' : ''}
                        onClick={() => handleTabClick(3)}
                    >
                       Finance & Operations
                    </button>
                    <button
                        className={activeTab === 4 ? 'active' : ''}
                        onClick={() => handleTabClick(4)}
                    >
                     Supply Chain
                    </button>
                </div>

                <div className="tab-content1">
                    {activeTab === 1 &&  <SalesMarketing/>}
                    {activeTab === 2 && <Service/>}
                    {activeTab === 3 &&  <FinanceOperator/>}
                    {activeTab === 4 &&  <SupplyChain/>}
                </div>
            </div>


            <StyledP className='mt-4' >
            This is not all, the cloud-based nature of Dynamics 365 and seamless integration with other Microsoft products, D35 offers flexibility, scalability, and security. Businesses can access their applications and data from anywhere, on any device, and easily scale their resources as their needs evolve. Built-in security measures and compliance certifications ensure the protection of sensitive information and adherence to industry regulations.
            </StyledP>
            <StyledP >
            Overall, Microsoft Dynamics 365 is a comprehensive business solution that empowers organizations to transform their operations, enhance customer engagement, and achieve their business goals with agility and intelligence.
            </StyledP>
        </>
    )
}

export default Dynamic
