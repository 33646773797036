import React, { useState, useContext, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiTagSuggestionDropDown from "./MultiTagSuggestionDropDown";
import CreatContext from "../../context/CreatContext";
import Swal from "sweetalert2";
// import MultiInputTags from "./MultiInputTags";
import DeleteIcon from "@mui/icons-material/Delete";
import MultiInputDropDownTagOther from "./MultiInputDropDownTagOther";
import SingleInputDropDownTagOther from "./SingleInputDropDownTagOther";
import SingleInputWithoutOther from "./SingleInputWithoutOther";
import Captcha from "./Captcha";

// import images
import upload_button from "../../images/upload-button.png";
import word from "../../images/word.png";
import pdf from "../../images/pdf.png";

const UserJobDetailModal = (props) => {
  const { jobBoardApplyJobTicket, jobTitle, applyNowJmId } = props;

  const context = useContext(CreatContext);
  const {
    noticePeriodData,
    getQuestionData,
    setGetQuestionData,
    questionAnswerSave,
    locInfo,
    jmIdLocInfo,
    questionFlag,
    storeSkills,
  } = context;

  const userJobDetailModalCloseBtn = useRef();
  const nextModal = useRef();
  const closeSubmitAnswer = useRef();

  const MobileElement = document.getElementsByClassName(
    "phoneNumberspanComp"
  )[0];
  const spanElement = document.getElementsByClassName("EmailSpan")[0];

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobApplyData, setJobApplyData] = useState({
    mobile: "",
    email: "",
    remarks: "",
  });
  const [currentLoc, setCurrentLoc] = useState("");
  const [prefferedLoc, setPrefferedLoc] = useState("");
  const [candidateExperience, setCandidateExperience] = useState("");
  const [relevantExperience, setRelevantExperience] = useState("");
  const [currentCTC, setCurrentCTC] = useState("");
  const [expectedCTC, setExpectedCTC] = useState("");
  const [skillsApply, setSkillsApply] = useState([]);
  const [clearSkillsValue, setClearSkillsValue] = useState(false);
  const [currentLocation, setCurrentLocation] = useState([]);
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [applyJobResumeFile, setApplyJobResumeFile] = useState("");
  const [captchaOpacity, setCaptchaOpacity] = useState(0);
  const [captchaOperation, setCaptchaOperation] = useState("");
  const [fileExtensionImg, setFileExtensionImg] = useState("");
  const [refreshCaptcha, setRefreshCaptcha] = useState(0);
  const [curOtherField, setCurOtherField] = useState(false);
  const [preOtherField, setPreOtherField] = useState(false);
  const [otherFieldValueShow, setOtherFieldValueShow] = useState([]);
  const [valueToShowOption, setValueToShowOption] = useState([]);
  const [skillsOption, setSkillsOption] = useState([]);
  // const [preferredLocationShow, setPreferredLocationShow] = useState([]);
  const [servingNoticePeriodRadioButton, setServingNoticePeriodRadioButton] =
    useState("N");
  const [lastWorkingDay, setLastWorkingDay] = useState(null);
  const [LastWorkingDayDatePicker, setLastWorkingDayDatePicker] =
    useState(null);
  const [enableLastWorkingDay, setEnableLastWorkingDay] = useState(false);
  const [noticePeriod, setNoticePeriod] = useState([]);
  const [noticePeriodLong, setNoticePeriodLong] = useState(null);
  const [noticePeriodOtherField, setNoticePeriodOtherField] = useState(false);

  const [queAnswer, setQueAnswer] = useState([]);

  const [inputValue, setInputValue] = useState(
    new Array(getQuestionData.length).fill("")
  );

  const handleInputChangeQueAnswerFront = (event, index) => {
    const newValue = event.target.value;
    const preValue = [...inputValue];
    preValue[index] = newValue;
    setInputValue(preValue);
  };

  const handleInputChangeQueAnswer = (
    event,
    index,
    d,
    jqId,
    job_question,
    ja_id,
    jqr_id
  ) => {
    const newValue = event.target.value;

    // Create a copy of the data array
    const updatedArray = {};

    updatedArray["jqmId"] = jqId;
    updatedArray["quesNumResponse"] = 0;
    updatedArray["quesTxtResponse"] = "";

    updatedArray["job_question"] = job_question;
    updatedArray["ja_id"] = ja_id;
    updatedArray["jqr_id"] = jqr_id;

    // Update the value at the specified index
    if (d === 1) {
      updatedArray["quesNumResponse"] = newValue;
    } else {
      updatedArray["quesTxtResponse"] = newValue;
      updatedArray["quesNumResponse"] = "";
    }

    // Update the state with the updated array
    setQueAnswer((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = {
        jqrId: updatedArray["jqr_id"],
        jaId: updatedArray["ja_id"],
        jmId: applyNowJmId,
        jqId: updatedArray["jqmId"],
        jobQuestion: updatedArray["job_question"],
        quesNumResponse: updatedArray["quesNumResponse"],
        quesTxtResponse: updatedArray["quesTxtResponse"],
      };
      return newArray;
    });
  };

  const handleSuccess = () => {
    setCaptchaOpacity(1);
    setCaptchaOperation("success");
  };

  const handleFailure = () => {
    setCaptchaOpacity(0);
    setCaptchaOperation("");
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const searchDot = await fileName.lastIndexOf(".");
    const fileExtension = fileName.substring(searchDot + 1);

    if (file.size / 1048576 > 2) {
      Swal.fire({
        title: "Resume should be less than 2MB",
        icon: "warning",
      });
      return false;
    }

    if (
      fileExtension === "pdf" ||
      fileExtension === "PDF" ||
      fileExtension === "docx" ||
      fileExtension === "DOCX"
    ) {
      setApplyJobResumeFile(file);
      event.target.value = "";
    } else {
      Swal.fire({
        title: "Only pdf, docx files are allowed",
        icon: "warning",
      });
      setFileExtensionImg("");
      setApplyJobResumeFile("");
      return false;
    }

    setFileExtensionImg(fileExtension);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 8 || (event.charCode >= 48 && event.charCode <= 57)) {
      return true;
    }
    event.preventDefault();
    return false;
  };

  const onChangeApplyForm = (e) => {
    setJobApplyData({ ...jobApplyData, [e.target.name]: e.target.value });
  };

  const jobApplyForm1 = async () => {
    for (let i = 0; i < getQuestionData.length; i++) {
      const element = getQuestionData[i];
      if (element.must_have === "Y") {
        if (
          (parseInt(element.question_type) === 1 &&
            (queAnswer[i]?.quesNumResponse === "" ||
              queAnswer[i]?.quesNumResponse === undefined)) ||
          ((parseInt(element.question_type) === 2 ||
            parseInt(element.question_type) === 3) &&
            (queAnswer[i]?.quesTxtResponse === "" ||
              queAnswer[i]?.quesTxtResponse === undefined))
        ) {
          Swal.fire({
            title: "Please fill all mandatory fields",
            icon: "warning",
          });
          return false;
        }
      }
    }

    questionAnswerSave(queAnswer);
    closeSubmitAnswer.current.click();
    clearModalUserData();
  };

  const jobApplyForm = async () => {
    // console.log(applyJobResumeFile.size/1048576);
    // console.log(preferredLocation);

    let preferred_locString = "";
    let current_locString = "";
    let skillString = "";

    if (
      firstName === "" ||
      lastName === "" ||
      jobApplyData.email === "" ||
      currentCTC.length === undefined ||
      currentCTC.length === 0 ||
      expectedCTC.length === undefined ||
      relevantExperience === "" ||
      candidateExperience === "" ||
      expectedCTC.length === 0 ||
      skillsApply.length === 0 ||
      currentLocation.length === 0
    ) {
      Swal.fire({
        title: "Please fill all mandatory fields",
        icon: "warning",
      });
      return false;
    }

    current_locString = await currentLocation.join(", ");
    skillString = await skillsApply.join(", ");

    if (jobApplyData.mobile.length < 10) {
      Swal.fire({
        title: "Please enter a valid mobile number!",
        icon: "warning",
      });
      return false;
    }

    var pattern =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!pattern.test(jobApplyData.email)) {
      Swal.fire({
        title: "Please enter a valid email!",
        icon: "warning",
      });
      return false;
    }

    if (curOtherField) {
      if (currentLoc === "") {
        Swal.fire({
          title: "Please fill the current location",
          icon: "warning",
        });
        return false;
      }
      current_locString = currentLoc;
    }

    if (preOtherField) {
      if (prefferedLoc === "") {
        Swal.fire({
          title: "Please fill the preferred location",
          icon: "warning",
        });
        return false;
      }
      preferred_locString = prefferedLoc;
    } else if (preferredLocation.length === 0) {
      Swal.fire({
        title: "Please fill the preferred location",
        icon: "warning",
      });
      return false;
    }
    const prefferedLocLmID = await valueToShowOption.join(",");
    if (servingNoticePeriodRadioButton === "N") {
      if (noticePeriod.length === 0) {
        Swal.fire({
          title: "Please fill the notice period",
          icon: "warning",
        });
        return false;
      }
    } else if (servingNoticePeriodRadioButton === "Y") {
      if (lastWorkingDay === null) {
        Swal.fire({
          title: "Please fill the Last Working Day",
          icon: "warning",
        });
        return false;
      }
    }

    if (applyJobResumeFile === "") {
      Swal.fire({
        title: "Resume is mandatory",
        icon: "warning",
      });
      return false;
    }
    if (captchaOperation === "") {
      Swal.fire({
        title: "Please fill the captcha and it should be correct",
        icon: "warning",
      });
      return false;
    }

    jobBoardApplyJobTicket(
      firstName,
      lastName,
      jobApplyData.mobile,
      jobApplyData.email,
      current_locString,
      preferred_locString,
      currentCTC,
      expectedCTC,
      skillString,
      jobApplyData.remarks,
      candidateExperience,
      relevantExperience,
      applyJobResumeFile,
      preferredLocation,
      lastWorkingDay,
      noticePeriodLong,
      servingNoticePeriodRadioButton,
      prefferedLocLmID
    );

    userJobDetailModalCloseBtn.current.click();

    clearModalUserData();
  };

  const clearModalUserData = () => {
    setFirstName("");
    setLastName("");
    setJobApplyData({ mobile: "", email: "", remarks: "" });
    setPrefferedLoc("");
    setCurrentLoc("");
    setCaptchaOperation("");
    setCaptchaOpacity(0);
    setApplyJobResumeFile("");
    setFileExtensionImg("");
    setServingNoticePeriodRadioButton("N");
    setNoticePeriodLong(null);
    setLastWorkingDay(null);
    setLastWorkingDayDatePicker(null);
    setEnableLastWorkingDay(false);
    setNoticePeriod([]);
    setNoticePeriodOtherField(false);
    setCurrentLocation([]);
    setPreferredLocation([]);
    setSkillsApply([]);
    setClearSkillsValue(!clearSkillsValue);
    setExpectedCTC("");
    setCurrentCTC("");
    setCandidateExperience("");
    setRelevantExperience("");
    scrollToTop();
    setInputValue(new Array(getQuestionData.length).fill(""));
    setQueAnswer([]);
    refreshCaptchaInput();
    setCurOtherField(false);
    setPreOtherField(false);
    setOtherFieldValueShow([]);
    setValueToShowOption([]);
    spanElement.textContent = "*";
    MobileElement.textContent = "*";
  };

  const handleKeyPressExp1 = (event) => {
    if (
      event.keyCode === 8 ||
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 46
    ) {
      return true;
    }
    event.preventDefault();
    return false;
  };

  const optionsNoticePeriod = noticePeriodData.map((value) => {
    return {
      TimePeriod: value.timePeriod,
      Values: value.values,
    };
  });

  const handleKeyPressExp2 = (event) => {
    if (
      event.keyCode === 8 ||
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 46
    ) {
      return true;
    }
    event.preventDefault();
    return false;
  };

  const handleKeyPressExp3 = (event) => {
    const value = event.target.value;
    const length = value.length;

    if (length < 3) {
      if (
        event.charCode === 8 ||
        (event.charCode >= 48 && event.charCode <= 57) ||
        event.charCode === 46
      ) {
        return true;
      }
    } else if (value.includes(".")) {
      if (
        event.charCode === 8 ||
        (event.charCode >= 48 && event.charCode <= 57) ||
        event.charCode === 46
      ) {
        return true;
      }
    } else if (event.charCode === 8) {
      return true;
    }

    event.preventDefault();
    return false;
  };

  const handleDate = async (date) => {
    setLastWorkingDayDatePicker(date);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    setLastWorkingDay(formattedDate);
  };

  const refreshCaptchaInput = () => {
    setRefreshCaptcha(refreshCaptcha + 1);
    setCaptchaOpacity(0);
    setCaptchaOperation("");
  };

  const scrollToTop = () => {
    const modalbody = document.querySelector(".ScrollTopClass");
    const modalbody1 = document.querySelector(".ScrollTopClass1");
    if (modalbody || modalbody1) {
      modalbody.scrollTo(0, 0);
      modalbody1.scrollTo(0, 0);
    }
  };

  const deleteFileResume = () => {
    setApplyJobResumeFile("");
    setFileExtensionImg("");
  };

  const emailFormat = (e) => {
    const emailValue = e.target.value;
    var pattern =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (pattern.test(emailValue) || emailValue === "") {
      spanElement.textContent = "*";
    } else {
      spanElement.textContent = "(Please enter valid email)";
    }
  };

  const mobileFieldValidation = (e) => {
    const mobileValue = e.target.value;
    if (mobileValue.length === 10 || mobileValue.length === 0) {
      MobileElement.textContent = "*";
    } else {
      MobileElement.textContent = "(Max 10 digit allowed)";
    }
  };

  const options = locInfo.map((value) => {
    return {
      Location: value.cityName,
    };
  });

  const options_2 = jmIdLocInfo.map((value) => {
    return {
      Location: value.city_name,
      LmId: value.lm_id,
    };
  });

  const multiselectOnChange = async (value) => {
    const data = await storeSkills
      .filter((item) =>
        item.skill_name
          ?.toString()
          .toLowerCase()
          .includes(value?.toString().toLowerCase())
      )
      .slice(0, 500)
      .map((item) => {
        return {
          smId: item.sm_id,
          skillName: item.skill_name,
        };
      });
    setSkillsOption(data);
  };

  //   useEffect(() => {
  //     const handleToSetSkillData = async () => {
  //       const data = await storeSkills.slice(0, 100).map((item) => {
  //         return {
  //           smId: item.sm_id,
  //           skillName: item.skill_name,
  //         };
  //       });
  //       setSkillsOption(data);
  //     };

  //     if (storeSkills) {
  //       handleToSetSkillData();
  //     }
  //   }, [storeSkills]);

  const firstSpaceValidation = (event) => {
    const len = event.target.value.length;
    if (len === 0 && event.charCode === 32) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const firstLetterCapitalized = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const openNextQuestionnaireModal = async () => {
    if (getQuestionData.length > 0) {
      getQuestionData.map((element, index) => {
        setQueAnswer((prevArray) => {
          const newArray = [...prevArray];
          newArray[index] = {
            jqrId: element.jqr_id,
            jaId: element.ja_id,
            jmId: applyNowJmId,
            jqId: element.jq_id,
            quesNumResponse: "",
            quesTxtResponse: "",
            jobQuestion: element.job_question,
          };
          return newArray;
        });
      });
      nextModal.current.click();
    }
    // nextModal.current.click();
  };

  useEffect(() => {
    openNextQuestionnaireModal();
  }, [getQuestionData]);

  return (
    <>
      {/* <!-- Job_Detail_page_modalStart_by_Simran Arora --> */}
      <div>
        <div
          id="quesitonnaire"
          className="modal fade"
          tabIndex="-1"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content contentjobDetailPage">
              <div className="modal-header-JobDetail">
                <div className="d-flex justify-content-center modal-title">
                  <h4
                    className="userJobModaltitlewidth"
                    id="heading"
                    title={jobTitle}
                    style={{ fontWeight: "600" }}
                  >
                    Job Apply - {jobTitle}
                  </h4>
                </div>
                <button
                  type="button"
                  className="close-modal-jd pt-0 btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  data-dismiss="modal"
                  aria-hidden="true"
                  id="closeJobDetailBtn"
                  ref={closeSubmitAnswer}
                  onClick={() => {
                    clearModalUserData();
                    setGetQuestionData([]);
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body p-0" id="questionbodyModal">
                <div
                  className="ScrollTopClass1"
                  style={{
                    overflowY: "scroll",
                    height: "calc(60vh - 40px)",
                    padding: "10px",
                    overflowX: "hidden",
                  }}
                >
                  <form id="userJobDetailForm1">
                    <div
                      className="additional-ques mb-2"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      Additional Questions
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          {getQuestionData.map((que, i) => {
                            return (
                              <div key={i} className="">
                                <label
                                  className="mt-3"
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "10px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {que.job_question}
                                  <span
                                    className={`${
                                      que.must_have === "Y"
                                        ? "text-danger"
                                        : "d-none"
                                    }`}
                                  >
                                    {" "}
                                    *
                                  </span>
                                </label>
                                {parseInt(que.question_type) === 1 ? (
                                  <input
                                    type="text"
                                    placeholder="Enter numeric response"
                                    className="form-control   custom-question"
                                    maxLength="11"
                                    onKeyPress={handleKeyPressExp2}
                                    value={inputValue[i]}
                                    onChange={(e) => {
                                      if (
                                        /^\d*\.?\d{0,2}$/.test(e.target.value)
                                      ) {
                                        handleInputChangeQueAnswerFront(e, i);
                                        handleInputChangeQueAnswer(
                                          e,
                                          i,
                                          1,
                                          que.jq_id,
                                          que.job_question,
                                          que.ja_id,
                                          que.jqr_id
                                        );
                                      }
                                    }}
                                  />
                                ) : null}

                                {parseInt(que.question_type) === 2 ? (
                                  <select
                                    value={inputValue[i]}
                                    onChange={(e) => {
                                      handleInputChangeQueAnswerFront(e, i);
                                      handleInputChangeQueAnswer(
                                        e,
                                        i,
                                        2,
                                        que.jq_id,
                                        que.job_question,
                                        que.ja_id,
                                        que.jqr_id
                                      );
                                    }}
                                    className="form-select custom-question"
                                    aria-label="Default select example"
                                  >
                                    <option>Select an Option</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                ) : null}

                                {parseInt(que.question_type) === 3 ? (
                                  <input
                                    type="text"
                                    onKeyPress={firstSpaceValidation}
                                    placeholder="Enter your response"
                                    value={inputValue[i]}
                                    className="form-control custom-question"
                                    onChange={(e) => {
                                      handleInputChangeQueAnswerFront(e, i);
                                      handleInputChangeQueAnswer(
                                        e,
                                        i,
                                        3,
                                        que.jq_id,
                                        que.job_question,
                                        que.ja_id,
                                        que.jqr_id
                                      );
                                    }}
                                  />
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer Jobdetail-Modal-footer align-items-end">
                {/* <div>
                                    <button type="button" className="btn" value="submit" data-bs-toggle="modal" data-bs-target="#userJobDetailModal" >Back</button>
                                </div> */}
                <div>
                  <button
                    type="submit"
                    id="submit"
                    className="btn btn-primary mt-3 custom-width"
                    value="submit"
                    onClick={jobApplyForm1}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="userJobDetailModal"
          className="modal fade"
          tabIndex="-1"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content contentjobDetailPage">
              <div className="modal-header-JobDetail">
                <div className="d-flex justify-content-center modal-title">
                  <h4
                    className="userJobModaltitlewidth"
                    id="heading"
                    title={jobTitle}
                    style={{ fontWeight: "600" }}
                  >
                    Job Apply - {jobTitle}
                  </h4>
                </div>
                <button
                  type="button"
                  className="close-modal-jd pt-0 btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  data-dismiss="modal"
                  aria-hidden="true"
                  id="closeJobDetailBtn"
                  ref={userJobDetailModalCloseBtn}
                  onClick={() => clearModalUserData()}
                >
                  X
                </button>
              </div>
              <div className="modal-body p-0" id="applicantModal">
                <div
                  className="ScrollTopClass"
                  style={{
                    overflowY: "scroll",
                    height: "calc(60vh - 40px)",
                    padding: "10px",
                  }}
                >
                  <form id="userJobDetailForm">
                    <input type="hidden" id="jobBoardJmid" value="" />
                    <input type="hidden" id="jobBoardTitle" value="" />
                    <div className="row">
                      <div className="col-lg-6 pt-0 pb-0">
                        <div className="form-group">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            First Name <span className="text-danger">*</span>
                          </div>
                          <input
                            type="text"
                            className="form-control custom-input-Modal"
                            name="firstName"
                            onKeyPress={firstSpaceValidation}
                            id="firstname"
                            mandatory="true"
                            placeholder="First Name"
                            autoComplete="off"
                            value={firstName}
                            message="First Name"
                            onChange={(e) =>
                              setFirstName(
                                firstLetterCapitalized(e.target.value)
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pt-0 pb-0">
                        <div className="form-group">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Last Name <span className="text-danger">*</span>
                          </div>
                          <input
                            type="text"
                            className="form-control custom-input-Modal"
                            name="lastName"
                            id="lastName"
                            onKeyPress={firstSpaceValidation}
                            mandatory="true"
                            value={lastName}
                            placeholder="Last Name"
                            autoComplete="off"
                            onChange={(e) =>
                              setLastName(
                                firstLetterCapitalized(e.target.value)
                              )
                            }
                            message="Last Name"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 pt-0 pb-0">
                        <div className="form-group">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Mobile No.{" "}
                            <span className="text-danger phoneNumberspanComp">
                              *
                            </span>
                          </div>{" "}
                          <input
                            type="text"
                            className="form-control custom-input-Modal"
                            id="mobileJobBoard"
                            name="mobile"
                            maxLength="10"
                            placeholder="Mobile Number"
                            mandatory="true"
                            autoComplete="off"
                            message="Mobile Number"
                            value={jobApplyData.mobile}
                            onChange={(e) => {
                              if (/^\d*\.?\d{0,1}$/.test(e.target.value)) {
                                onChangeApplyForm(e);
                              }
                            }}
                            onInput={(e) => mobileFieldValidation(e)}
                            onKeyPress={handleKeyPress}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pt-0 pb-0">
                        <div className="form-group">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Email Id{" "}
                            <span className="text-danger EmailSpan">*</span>
                          </div>
                          <input
                            type="email"
                            className="form-control custom-input-Modal"
                            name="email"
                            id="emailJobBoard"
                            placeholder="Email"
                            mandatory="true"
                            onKeyPress={firstSpaceValidation}
                            autoComplete="off"
                            message="Email"
                            value={jobApplyData.email}
                            onChange={(e) => onChangeApplyForm(e)}
                            onInput={(e) => emailFormat(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 pt-0 pb-0">
                        <div className="form-group">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Current Location{" "}
                            <span className="text-danger">*</span>
                          </div>

                          <SingleInputDropDownTagOther
                            onTag={currentLocation}
                            setOnTag={(value) => {
                              setCurrentLocation(value);
                            }}
                            label="Location"
                            setOtherField={(value) => setCurOtherField(value)}
                            otherField={curOtherField}
                            preSelectedTag={[]}
                            placeholder="Current Location"
                            limit={1}
                            options={options}
                            onSearch={(value) => {
                              //   multiselectOnChange(value);
                            }}
                          />

                          <input
                            type="text"
                            className={`form-control mt-2 custom-input-Modal ${
                              curOtherField ? "" : "d-none"
                            }`}
                            id="currentLocaitonJobBoard"
                            name="currentLoc"
                            onKeyPress={firstSpaceValidation}
                            placeholder="Enter your Current Location"
                            mandatory="true"
                            autoComplete="off"
                            message="Current Location"
                            value={currentLoc}
                            onChange={(e) => setCurrentLoc(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pt-0 pb-0">
                        <div className="form-group" id="prefLocationJob">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Preferred Location{" "}
                            <span className="text-danger">*</span>
                          </div>

                          <MultiInputDropDownTagOther
                            onTag={preferredLocation}
                            setOnTag={(value) => {
                              setPreferredLocation(value);
                            }}
                            label="Location"
                            label_2="LmId"
                            setValueToShowOption={setValueToShowOption}
                            valueToShowOption={valueToShowOption}
                            limit={6}
                            placeholder="Preferred Location"
                            otherFieldValueShow={otherFieldValueShow}
                            setOtherFieldValueShow={setOtherFieldValueShow}
                            setOtherField={(value) => setPreOtherField(value)}
                            otherField={preOtherField}
                            options={options_2}
                            onSearch={(value) => {
                              /*multiselectOnChange(value)*/
                            }}
                          />

                          <input
                            type="text"
                            className={`form-control mt-2 custom-input-Modal ${
                              preOtherField ? "" : "d-none"
                            }`}
                            id="prefferedLocaitonJobBoard"
                            name="prefferedLoc"
                            placeholder="Enter your Preferred Location"
                            mandatory="true"
                            onKeyPress={firstSpaceValidation}
                            value={prefferedLoc}
                            style={{ border: "1px solid #e0e1e3" }}
                            onChange={(e) => setPrefferedLoc(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 pt-0 pb-0 form-group">
                        <div
                          htmlFor=""
                          className="custom-label"
                          style={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          Experience (In Years){" "}
                          <span className="text-danger">*</span>
                        </div>
                        <div className="ml-0" style={{ display: "block" }}>
                          <input
                            className="form-control form-control-sm "
                            style={{ height: "30px" }}
                            type="text"
                            maxLength={4}
                            onKeyPress={handleKeyPressExp3}
                            name=""
                            min={0.0}
                            step="0.1"
                            placeholder="Total Experience (In Years)"
                            value={candidateExperience}
                            onChange={(e) => {
                              if (/^\d*\.?\d{0,1}$/.test(e.target.value)) {
                                setCandidateExperience(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pt-0 pb-0 form-group">
                        <div
                          htmlFor=""
                          className="custom-label"
                          style={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          Relevant Experience (In Years){" "}
                          <span className="text-danger">*</span>
                        </div>
                        <div className="ml-0" style={{ display: "block" }}>
                          <input
                            className="form-control form-control-sm "
                            style={{ height: "30px" }}
                            type="text"
                            maxLength={4}
                            onKeyPress={handleKeyPressExp3}
                            name=""
                            min={0.0}
                            step="0.1"
                            placeholder="Relevant Experience (In Years)"
                            value={relevantExperience}
                            onChange={(e) => {
                              if (/^\d*\.?\d{0,1}$/.test(e.target.value)) {
                                setRelevantExperience(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 pt-0 pb-0">
                        <div className="form-group">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Current CTC (In LPA){" "}
                            <span className="text-danger">*</span>
                          </div>
                          <input
                            type="text"
                            min={0}
                            className="form-control custom-input-Modal"
                            id="currentCtcJobBoard"
                            onKeyPress={handleKeyPressExp1}
                            onPaste={handleKeyPressExp1}
                            name="currentCtc"
                            placeholder="Current CTC (In LPA)"
                            mandatory="true"
                            autoComplete="off"
                            message="Current CTC (In LPA)"
                            value={currentCTC}
                            onChange={(e) => {
                              if (
                                /^\d{0,3}(?:\.\d{0,2})?$/.test(e.target.value)
                              ) {
                                setCurrentCTC(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pt-0 pb-0">
                        <div className="form-group">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Expected CTC (In LPA){" "}
                            <span className="text-danger">*</span>
                          </div>
                          <input
                            type="text"
                            min={0}
                            className="form-control custom-input-Modal"
                            name="expCtc"
                            onKeyPress={handleKeyPressExp1}
                            onPaste={handleKeyPressExp1}
                            id="expectedCtcJobBoard"
                            placeholder="Expected CTC (In LPA)"
                            mandatory="true"
                            message="Expected CTC"
                            value={expectedCTC}
                            onChange={(e) => {
                              if (
                                /^\d{0,3}(?:\.\d{0,2})?$/.test(e.target.value)
                              ) {
                                setExpectedCTC(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group" id="skillJob">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Skills <span className="text-danger">*</span>
                          </div>

                          {/* <MultiInputTags
                            className="form-control"
                            selectedTags={(value) => setSkillsApply(value)}
                            onTag={skillsApply}
                            placeholder={`${
                              skillsApply.length === 0 ? "Skills" : ""
                            }`}
                          /> */}
                          <MultiTagSuggestionDropDown
                            options={skillsOption}
                            labelToShow="skillName"
                            labelToStore="skillName"
                            onTag={skillsApply}
                            setOnTag={(value) => {
                              setSkillsApply(value);
                            }}
                            placeholder="Skills"
                            clearSkillsValue={clearSkillsValue}
                            onSearch={multiselectOnChange}
                            limit={0}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-lg-4"
                        style={{ fontWeight: "600", fontSize: "14px" }}
                      >
                        Currently Serving Notice Period{" "}
                        <span className="text-danger">*</span>
                        <div
                          className="mt-2"
                          style={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          <div className="d-flex">
                            <label className="d-flex">
                              <div className="">
                                <input
                                  htmlFor="ServingNoticePeriodYes"
                                  type="radio"
                                  value="Y"
                                  checked={
                                    servingNoticePeriodRadioButton === "Y"
                                  }
                                  onChange={() => {
                                    setServingNoticePeriodRadioButton("Y");
                                    setEnableLastWorkingDay(true);
                                  }}
                                  name="ServingNoticePeriod"
                                />
                              </div>
                              <div className="ms-1" id="ServingNoticePeriodYes">
                                Yes
                              </div>
                            </label>
                            <label className="d-flex ms-3">
                              <div className="">
                                <input
                                  htmlFor="ServingNoticePeriodNo"
                                  type="radio"
                                  value="N"
                                  checked={
                                    servingNoticePeriodRadioButton === "N"
                                  }
                                  onChange={() => {
                                    setServingNoticePeriodRadioButton("N");
                                    const result = noticePeriod.join(",");
                                    if (result !== "Immediate") {
                                      setEnableLastWorkingDay(false);
                                    }
                                    setLastWorkingDay(null);
                                    setLastWorkingDayDatePicker(null);
                                  }}
                                  name="ServingNoticePeriod"
                                />
                              </div>
                              <div className="ms-1" id="ServingNoticePeriodNo">
                                No
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Notice Period{" "}
                            <span
                              className={`text-danger ${
                                servingNoticePeriodRadioButton === "N"
                                  ? ""
                                  : "d-none"
                              }`}
                            >
                              *
                            </span>
                          </div>
                          <SingleInputWithoutOther
                            onTag={noticePeriod}
                            setOnTag={(value) => {
                              setNoticePeriod(value);
                            }}
                            label="TimePeriod"
                            servingNoticePeriodRadioButton={
                              servingNoticePeriodRadioButton
                            }
                            setNoticePeriodLong={setNoticePeriodLong}
                            setOtherField={(value) =>
                              setNoticePeriodOtherField(value)
                            }
                            otherField={noticePeriodOtherField}
                            setLastWorkingDayDatePicker={
                              setLastWorkingDayDatePicker
                            }
                            setEnableLastWorkingDay={setEnableLastWorkingDay}
                            setLastWorkingDay={setLastWorkingDay}
                            preSelectedTag={[]}
                            placeholder="Notice Period"
                            limit={1}
                            options={optionsNoticePeriod}
                            onSearch={(value) => {}}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Last Working Day{" "}
                            <span
                              className={`text-danger ${
                                servingNoticePeriodRadioButton === "Y"
                                  ? ""
                                  : "d-none"
                              }`}
                            >
                              *
                            </span>
                          </div>
                          <DatePicker
                            disabled={!enableLastWorkingDay}
                            selected={LastWorkingDayDatePicker}
                            onChange={(date) => handleDate(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            // minDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div
                            className=""
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            Remarks
                          </div>
                          <textarea
                            maxLength={500}
                            className="form-control mb-3"
                            name="remarks"
                            id="jobBoardRemark"
                            rows="1"
                            autoComplete="off"
                            value={jobApplyData.remarks}
                            onChange={(e) => onChangeApplyForm(e)}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="" style={{ marginBottom: "-24px" }}>
                          <div
                            style={{
                              display: "block",
                              color: "black",
                              fontWeight: 600,
                              fontSize: "14px",
                            }}
                          >
                            Upload your Resume{" "}
                            <span className="text-danger">*</span>
                          </div>
                          <div
                            className="row mb-4"
                            id="resumeDiv"
                            style={{ marginLeft: "0px", marginRight: "2px" }}
                          >
                            <div
                              className="col-lg-12"
                              id="uploadDivJobApplicantOuterDivJobBoard"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                border: "1px solid #dee2e6",
                                borderRadius: "8px",
                                paddingLeft: "0px",
                              }}
                            >
                              <span className="postResumeSpan">
                                <input
                                  hidden={true}
                                  type="file"
                                  onChange={(e) => handleFileInputChange(e)}
                                  id="file-input-appliJobBoard"
                                  accept=".docx,.pdf"
                                  mandatory="true"
                                  message="Resume"
                                />
                                <label
                                  title="Upload Resume"
                                  htmlFor="file-input-appliJobBoard"
                                  className="uploadDocsLabelDiv col-lg-6"
                                  id="outerDivLabelApplicantMstJobBoard"
                                  style={{
                                    cursor: "pointer",
                                    width: "45px",
                                    margin: "0px 10px 0px 0px",
                                  }}
                                >
                                  {fileExtensionImg.length === 0 && (
                                    <img
                                      src={upload_button}
                                      style={{
                                        height: "45px",
                                        width: "45px",
                                        borderRadius: "8px",
                                        marginRight: "10px",
                                      }}
                                      alt=""
                                    />
                                  )}

                                  {(fileExtensionImg === "pdf" ||
                                    fileExtensionImg === "PDF") && (
                                    <img
                                      src={pdf}
                                      style={{
                                        height: "45px",
                                        borderRadius: "8px",
                                        width: "45px",
                                        marginRight: "10px",
                                      }}
                                      alt=""
                                    />
                                  )}

                                  {(fileExtensionImg === "docx" ||
                                    fileExtensionImg === "DOCX") && (
                                    <img
                                      src={word}
                                      style={{
                                        height: "45px",
                                        borderRadius: "8px",
                                        width: "45px",
                                        marginRight: "10px",
                                      }}
                                      alt=""
                                    />
                                  )}
                                </label>

                                <span className="mobileViewResumeNameLength PcResumeNameLength">
                                  {applyJobResumeFile === ""
                                    ? "DOCX, PDF | Max: 2 MB"
                                    : `${applyJobResumeFile.name}`}
                                </span>
                              </span>
                              <span
                                className={`${
                                  fileExtensionImg.length === 0
                                    ? "displayNone"
                                    : ""
                                } `}
                                title="Delete Resume"
                              >
                                <DeleteIcon
                                  style={{
                                    color: "black",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={deleteFileResume}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-lg-12">
                        <div className="form-group mt-2">
                          <div
                            className=""
                            style={{
                              display: "block",
                              color: "black",
                              fontWeight: 600,
                              fontSize: "14px",
                            }}
                          >
                            Captcha <span className="text-danger"> *</span>
                          </div>
                          <div className="">
                            <Captcha
                              length={5}
                              checkIconOpacity={captchaOpacity}
                              handleSuccess={handleSuccess}
                              handleFailure={handleFailure}
                              refreshCaptcha={refreshCaptcha}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {questionFlag === "Y" ? (
                <div className="modal-footer Jobdetail-Modal-footer row">
                  <div className="col-lg-12 m-auto pb-2">
                    <button
                      type="button"
                      className="btn btn-primary mt-3 next-btn-center"
                      value="submit"
                      style={{ float: "right" }}
                      onClick={jobApplyForm}
                    >
                      Next
                    </button>
                    <span
                      className="d-none"
                      data-bs-toggle="modal"
                      data-bs-target="#quesitonnaire"
                      ref={nextModal}
                    >
                      N
                    </span>
                  </div>
                  {/* <div className="col-lg-12 m-auto">
                                        By clicking Register, you agree to the{" "}
                                        <Link to="/terms" target="_blank">Terms and Conditions</Link> &{" "}
                                        <Link to="/privacypolicy" target="_blank">Privacy Policy</Link> of atsMantra
                                    </div> */}
                </div>
              ) : (
                ""
              )}

              {questionFlag === "N" ? (
                <div className="modal-footer Jobdetail-Modal-footer row">
                  <div className="col-lg-5 m-auto pb-2">
                    <button
                      type="submit"
                      id="submit"
                      className="btn btn-primary mt-3"
                      value="submit"
                      onClick={jobApplyForm}
                    >
                      Submit
                    </button>
                  </div>

                  {/* <div className="col-lg-12 m-auto">
                                        By clicking Register, you agree to the{" "}
                                        <Link to="/terms" target="_blank" >Terms and Conditions</Link> &{" "}
                                        <Link to="/privacypolicy" target="_blank">Privacy Policy</Link> of atsMantra
                                    </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserJobDetailModal;
