import React from "react";
import styled from "styled-components";

const Styleh2 = styled.h2`
  font-family: Mulish;
  font-weight: 400;
  font-size: 30px;
  color: #13005a;
  margin-top: 52px;
  margin-bottom: 25px;

  @media (max-width: 767px) {
    margin-top: 30px;
    margin-bottom: 25px;
  }
  @media (min-width: 769px) and (max-width: 991px) {
    margin-top: 30px;
    margin-bottom: 25px;
  }
`;

const GoogleMap = () => {
  return (
    <>
      <Styleh2>Location</Styleh2>
      <div>
        <iframe
          title="Map of RARR Technologies Pvt. Ltd."
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14035.180020463595!2d77.3108551!3d28.4254418!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdd5133f49a25%3A0xd1a00d8c8e2bb0a2!2sRARR%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1709791228294!5m2!1sen!2sin"
          width="100%"
          height="350"
        ></iframe>
      </div>
    </>
  );
};

export default GoogleMap;
