import React, { useState, useEffect, useContext, useRef } from 'react'
import styled from "styled-components";
import CreatContext from "../../context/CreatContext";
import { NavLink, useLocation, Link } from 'react-router-dom';
import { useNavigate } from 'react-router';


import RarrLogo from "../../images/RarrLogoWebsite.png";
import RarrLogo1q from '../../images/RarrLogo1q.png';
import RarrLogo2q from '../../images/RarrLogo2q.png';
import RarrLog1qw from '../../images/RarrLog1qw.png';
import backgroundImg from "../../images/backgroundimg.png"

const NavbarHead = styled.nav`
  Font-family  :"Mulish";
  background-image: url(${backgroundImg});
`;
const Container = styled.div`
`;


const Logo = styled(NavLink)`
&img {

  height: 50px;
}
`;

const NavbarCollapse = styled.div`
  flex-grow: 0;
`;

const NavUl = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`   
list-style: none;
    display: inline-block;
    margin: 0px 0px 0px 40px;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 20px;
    }
`;
const Anchor = styled(Link)`
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration : none;
     color : white;
     &:hover{
      text-decoration : none;
      color : white;
     }
  &.active {
    border-bottom: 2px solid #fff;
    padding-bottom: 4px;
    color : white;
  }
  &:active {
    border-bottom: 2px solid #fff;
    padding-bottom: 4px;
    color : white;
  }
`;

const Anchor_1 = styled.div`
cursor:pointer;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration : none;
     color : white;
     &:hover{
      text-decoration : none;
      color : white;
     }
  &.active {
    border-bottom: 2px solid #fff;
    padding-bottom: 4px;
    color : white;
    margin-top: 5px;
  }
  
`

const ContactLink = styled(NavLink)`
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration : none;
     color : white !important;
     &:hover{
      text-decoration : none;
     }
     &.active {
    border-bottom: 2px solid #fff;
    padding-bottom: 4px;
    color : white!important;
  }
`
const Togglerbtn = styled.button`
color:#fff;
border: 1px solid #fff;
  &:focus {
  outline: none;
  box-shadow: none;
}
`;
const Filterspan = styled.span`
filter: invert(1);
`

const Header = () => {
  // const [activeLink, setActiveLink] = useState('');
  // const locationOne = window.location.pathname;
  const context = useContext(CreatContext);
  const { activeLink, setActiveLink, jobDetailPathName, postUrl } = context;
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const closeMobileNavBar = useRef();

  const closeMobileMenu = () => {
    closeMobileNavBar.current.click();
  }

  const handleActiveLinkHeader = () => {
    if (location.pathname === '/bannerDetail' || location.pathname === postUrl) {
      setActiveLink("#BlogMainSection");
    } else if (location.pathname === '/job-detail' || location.pathname === ('/job-detail' + jobDetailPathName) || location.pathname === ('/jobDetail' + jobDetailPathName) || location.pathname === ('/jobDetail') || location.pathname === '/current-job-opening') {
      setActiveLink("jobBoard");
    } else if (location.pathname === '/contact') {
      setActiveLink("contact");
    } else if (location.pathname === '/atsmantra/' || location.pathname === '/coconut-erp/' || location.pathname === '/application-development/' || location.pathname === '/database-design-and-development/' || location.pathname === '/microsoft-solutions/' || location.pathname === '/recruitment-services/' || location.pathname === '/ui-ux-designing/') {
      setActiveLink("#BridgeSection");
    }
  }

  const handleLinkClick = (event, sectionId) => {
    event.preventDefault();
    navigate('/');
    const timer = setTimeout(() => {
      const targetElement = document.querySelector(sectionId);
      if (targetElement) {
        scrollToRecommend(event, sectionId);
        setActiveLink(sectionId);
      }
    }, 100);
    return () => clearTimeout(timer);
  };

  const scrollToRecommend = (event, sectionId) => {
    event.preventDefault();
    const section = document.querySelector(sectionId);
    const navbarHeight = 85; // Replace with the actual height of your navbar
    const padding = 0; // Padding value in pixels

    const top =
      section.getBoundingClientRect().top +
      window.scrollY -
      navbarHeight -
      padding;

    window.scrollTo({
      top: top,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    handleActiveLinkHeader();

    // const handleScroll = () => {
    //   const aboutUsSection = document.getElementById('Revolution');
    //   const solutionsSection = document.getElementById('BridgeSection');
    //   const blogsSection = document.getElementById('BlogMainSection');

    //   const scrollPosition = window.scrollY + 100; // Add 80 pixels to the scroll position

    //   if (
    //     aboutUsSection &&
    //     scrollPosition >= aboutUsSection.offsetTop &&
    //     scrollPosition < aboutUsSection.offsetTop + aboutUsSection.offsetHeight
    //   ) {
    //     setActiveLink('#Revolution');
    //   } else if (
    //     solutionsSection &&
    //     scrollPosition >= solutionsSection.offsetTop &&
    //     scrollPosition < solutionsSection.offsetTop + solutionsSection.offsetHeight
    //   ) {
    //     setActiveLink('#BridgeSection');
    //   } else if (
    //     blogsSection &&
    //     scrollPosition >= blogsSection.offsetTop &&
    //     scrollPosition < blogsSection.offsetTop + blogsSection.offsetHeight
    //   ) {
    //     setActiveLink('#BlogMainSection');
    //   } else {
    //     setActiveLink('');
    //   }
    // };

    // window.addEventListener('scroll', handleScroll);

    // // Clean up the event listener on component unmount
    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, []);


  return (
    <>
      {/* <NavbarHead className="navbar navbar-expand-lg pt-3 fixed-top"> */}
      <NavbarHead className={`navbar navbar-expand-lg pt-3 fixed-top ${isScrolled ? 'scrolled' : ''}`}>
        <Container className="container">

          <NavLink to='/' onClick={() => { setActiveLink(''); window.scrollTo(0, 0) }}> <img src={RarrLog1qw} alt="logo" /></NavLink>
          <Togglerbtn
            ref={closeMobileNavBar}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <Filterspan className="navbar-toggler-icon" />
          </Togglerbtn>
          <NavbarCollapse className="collapse navbar-collapse" id="navbarSupportedContent">
            <NavUl className="navbar-nav me-auto mb-2 mb-lg-0">
              <NavItem className="nav-item">
                {/* <Anchor to="#Revolution" onClick={(event) => handleLinkClick(event, '#Revolution')}
                  className={activeLink === '#Revolution' ? 'active' : ''}> About Us </Anchor> */}
                <Anchor_1 onClick={(event) => { closeMobileMenu(); handleLinkClick(event, '#Revolution'); }}
                  className={activeLink === '#Revolution' ? 'active' : ''}> About Us </Anchor_1>
              </NavItem>
              <NavItem className="nav-item">
                {/* <Anchor to='#BridgeSection' onClick={(event) => handleLinkClick(event, '#BridgeSection')}
                  className={activeLink === '#BridgeSection' ? 'active' : ''}> Solutions</Anchor> */}
                <Anchor_1 onClick={(event) => { closeMobileMenu(); handleLinkClick(event, '#BridgeSection'); }}
                  className={activeLink === '#BridgeSection' ? 'active' : ''}> Solutions</Anchor_1>
              </NavItem>
              {/* 
              <NavItem className="nav-item">
                <Anchor_1 onClick={(event) => handleLinkClick(event, '#Gallery')}
                  className={activeLink === '#Gallery' ? 'active' : ''}> Gallery</Anchor_1>
              </NavItem> */}
              <NavItem className="nav-item" >
                {/* <Anchor
                  to="#BlogMainSection"
                  onClick={(event) => handleLinkClick(event, '#BlogMainSection')}
                  className={
                    (activeLink === '#BlogMainSection') ? 'active' : ''
                  }
                >
                  Blogs
                </Anchor> */}
                <Anchor_1
                  onClick={(event) => { closeMobileMenu(); handleLinkClick(event, '#BlogMainSection'); }}
                  className={
                    (activeLink === '#BlogMainSection') ? 'active' : ''
                  }
                >
                  Blogs
                </Anchor_1>
              </NavItem>
              <NavItem className="nav-item" >

                <Anchor onClick={() => { closeMobileMenu(); setActiveLink("jobBoard"); }} className={`${activeLink === 'jobBoard' ? 'active' : ''}`} to="/current-job-opening">Job Openings</Anchor>
              </NavItem>
              <NavItem className="nav-item" >
                <Anchor
                  onClick={() => { closeMobileMenu(); setActiveLink('contact'); window.scrollTo(0, 0); }}
                  className={`${activeLink === 'contact' ? 'active' : ''}`}
                  to="/contact"
                >
                  Contact Us
                </Anchor>
              </NavItem>
            </NavUl>
          </NavbarCollapse>

        </Container>
      </NavbarHead>
    </>
  )
}

export default Header
