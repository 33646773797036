import React from 'react'
import styled from 'styled-components';
import { MainBanner } from '../Subpages/components/MainBanner';
import { ContactSection } from '../Subpages/components/ContactSection';
import { MainHeading } from '../Subpages/components/MainHeading';
import MainTabSection from '../UI/UXdesign/MainTabSection';
import ContentDBdevelopment from '../dbDevelopment/components/ContentDBdevelopment'

// css_components
const Container = styled.div``
const LeftDiv  = styled.div`
order: 2;
@media (min-width: 991px) {
  order: 1;
}
`;
const RightDiv = styled.div`
  order: 1;

  @media (min-width: 991px) {
    order: 2;
  }
`;

const DataBaseDevelopment = () => {
  return (
    <>
    <Container>
       <MainBanner name="Database Design &  Development" pageName="Database Design &  Development"/>
            <Container className='container mb-3'>
                <div className='row'>
                <LeftDiv className="col-lg-3">
                  <div className='fixedSection'>
                  <MainTabSection/>
                  <ContactSection />
                 </div>   
                </LeftDiv>
                <RightDiv className='col-lg-9'>
                <MainHeading name='Database Design &  Development'/>
                    <ContentDBdevelopment />
                </RightDiv>
                </div>
            </Container>
    </Container>
    </>
    
  )
}

export default DataBaseDevelopment