import React from 'react'
import { styled } from 'styled-components'
import People from "../../../images/people.png"
import Code from "../../../../src/images/mdi_code.png"
import Healthy from "../../../../src/images/healthy.png"
import Years from "../../../../src/images/years.png"
const MainSection = styled.section`
margin-top : 131px ;
font-family : "Mulish";
@media (max-width: 768px) {
    margin-top : 0px ;
}
`;
const Column2 = styled.div`
`
const Column1 = styled.div`
`
const Marginleft = styled.div`
margin-left : 30px;

@media (max-width: 768px) {
    margin-left : 23px;
    margin-right : 20px;
}
`;
const Container = styled.div` `
const Row = styled.div`
& .displayNoneAchMobile{
    display: none;
}
@media (max-width: 768px) {

    & .displayNoneAch{
        display: none;
    }
    & .displayNoneAchMobile{
        display: block;
    }
  & .marginSetAch{
padding:23px;
    }
}
`;
const Stats = styled.h1`
    color: #010851;
    font-weight: bold;
    font-size: 40px;
    @media (min-width: 320px) and (max-width: 767px){
        font-size:32px;
      }
`
const Para = styled.p`
color: #63657E;
font-size: 16px;
margin-top : 20px;
`
const FlexDiv = styled.div`
 display : flex ;
 align-items: center;
 margin : 7px 0px ;
`
const Heading = styled.h1`
color : #010851;
font-weight: 800;
font-family : "Mulish";
float : right ;
`
const Paragraph = styled.p`
font-size : 12px;
color : #63657E;
font-family : "Mulish";
margin-bottom : 2px;
`
const PreTag = styled.pre`
font-size : 14px;
color : #63657E;
margin-top : 30px;
font-family : "Mulish";
`
const Experience = styled.h2`
color : #090F4E;
font-weight : 600 ; 
font-size : 18px;
`
const RightRow = styled.div`
 border-top: 1px solid #ada1a1;
 align-items: center;

 &:last-child {
    border-bottom : 1px solid #ada1a1 ;
 }

 @media (max-width: 768px) {
    padding-right:0px;
    padding-left:0px;
    & .mobilePicPeople{
        display:none;
    }

    & .col-lg-2{
        width:30%;
    }
    & .col-lg-7{
        width:70%;
    }
 }
`;

const Achievment = () => {
    return (
        <>
            <MainSection>
                <Container className='container '>
                    <Row className='row'>
                        <Column1 className='col-lg-4 marginSetAch'>
                            <Stats>Our Stats and Achievements </Stats>
                            <p className="displayNoneAch">{`Explore our impressive achievements and metrics in software solutions and app development, demonstrating our expertise in driving digital success`}</p>

                            <div className="displayNoneAchMobile">{`Explore our impressive achievements and metrics in software solutions and app development, demonstrating our expertise in driving digital success`}</div>
                        </Column1>
                        <Column2 className='col-lg-8'>
                            <Marginleft >
                                <RightRow className='row'>

                                    <div className='col-lg-2 mobilePicPeople'> <img src={Years} alt="people icon"></img></div>
                                    <div className='col-lg-2'>   <Heading>4+</Heading></div>
                                    <div className='col-lg-7'>

                                        <FlexDiv>
                                            <div>
                                                <Experience>Years of Experience</Experience>
                                                
                                            </div>
                                        </FlexDiv>
                                    </div>
                                </RightRow>
                                <RightRow className='row'>
                                    <div className='col-lg-2 mobilePicPeople'> <img src={People} alt="people icon"></img></div>
                                    <div className='col-lg-2'>
                                        <Heading>35+</Heading>
                                    </div>
                                    <div className='col-lg-7'>
                                        <FlexDiv>
                                            <div>
                                                <Experience>Team Members</Experience>
                                               
                                            </div>
                                        </FlexDiv>
                                    </div>
                                </RightRow>
                                <RightRow className='row'>
                                    <div className='col-lg-2 mobilePicPeople'> <img src={Code} alt="people icon"></img></div>
                                    <div className='col-lg-2'>  <Heading>100k+</Heading></div>
                                    <div className='col-lg-7'>
                                        <FlexDiv>

                                            <div>
                                                <Experience>Line of Codes pushed weekly</Experience>
                                                
                                            </div>
                                        </FlexDiv>
                                    </div>
                                </RightRow>
                                <RightRow className='row'>
                                    <div className='col-lg-2 mobilePicPeople'> <img src={Healthy} alt="people icon"></img></div>
                                    <div className='col-lg-2'>   <Heading>1000+</Heading></div>
                                    <div className='col-lg-7'>
                                        <FlexDiv>
                                            <div>
                                                <Experience>Healthy Discussions</Experience>
                                                
                                            </div>
                                        </FlexDiv>
                                    </div>
                                </RightRow>
                                
                            </Marginleft>
                        </Column2>
                    </Row>
                </Container>
            </MainSection>
        </>
    )
}

export default Achievment
