import React, { useRef, useState, useEffect } from "react";

const MultiInputDropDownTag = (props) => {
  const {
    options,
    label,
    onTag,
    setOnTag,
    preSelectedTag = [],
    placeholder,
    onSearch,
    limit = 0,
    otherField,
    setOtherField,
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const [isOpenData, setIsOpenData] = useState(false);
  const [selectedIndexS, setSelectedIndexS] = useState(null);
  const [searchedOptions, setSearchedOptions] = useState([]);

  const boxRef = useRef();
  const inputsub = useRef();
  const dropDownListRef = useRef(null);

  const removeTags = (e, indexToRemove) => {
    setOtherField(false);
    setOnTag([
      ...onTag.filter((item, index) => {
        return index !== indexToRemove;
      }),
    ]);
  };

  const handleToFilter = async () => {
    if (searchValue === "") {
      const data = await options.filter((item) => {
        if (onTag.some((value) => value === item[label])) return false;
        else return true;
      });
      setSearchedOptions(data);
    } else {
      const data = await options.filter((item) => {
        const itemLabel = item[label]?.toString().toLowerCase() ?? "";
        const searchValueLower = searchValue?.toString().toLowerCase() ?? "";
        if (onTag.some((value) => value === item[label])) return false;
        return itemLabel.includes(searchValueLower);
      });
      setSearchedOptions(data);
    }
  };

  const addTags = (event, option) => {
    setSelectedIndexS(null);
    setOnTag([option]);
    setSearchValue("");
    setIsOpenData(false);
    setOtherField(false);
    onSearch("");
  };

  const onSearchCahnge = (e) => {
    setSelectedIndexS(null);
    setSearchValue(e.target.value);
    onSearch(e.target.value);
    scrollToTopList();
  };

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setIsOpenData(false);
      setSearchValue("");
    }
  };

  const firstSpaceValidation = (event) => {
    const len = event.target.value.length;
    if (len === 0 && event.charCode === 32) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const inputFieldFocus = () => {
    inputsub.current.click();
  };

  const scrollToTopList = () => {
    const dropDownListSingle = document.querySelector(".dropDownListSingle");
    if (dropDownListSingle) {
      dropDownListSingle.scrollTo(0, 0);
    }
  };

  const handleKeyDownS = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedIndexS((prevIndex) =>
        prevIndex === null ? 0 : Math.min(prevIndex + 1, searchedOptions.length)
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedIndexS((prevIndex) =>
        prevIndex === null ? 0 : Math.max(prevIndex - 1, 0)
      );
    } else if (event.key === "Enter" && selectedIndexS !== null) {
      event.preventDefault();
      if (selectedIndexS === searchedOptions.length) {
        addTags(event, "OTHER");
        setOtherField(true);
        setIsOpenData(false);
      } else {
        const selectedOption = searchedOptions[selectedIndexS];
        addTags(event, selectedOption[label]);
      }
    }
  };

  useEffect(() => {
    if (isOpenData) {
      document.addEventListener("keydown", handleKeyDownS);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDownS);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndexS, isOpenData]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    handleToFilter();
  }, [options, searchValue]);

  useEffect(() => {
    // Scroll to selected index if it's not visible
    const dropDownList = dropDownListRef.current;
    const selectedItem =
      dropDownList && dropDownList.querySelector(".dropDownListItemSelected");
    if (selectedIndexS !== null && selectedItem) {
      selectedItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [selectedIndexS]);

  return (
    <>
      <div className="multiSelectInputTagSingle" ref={boxRef}>
        <div
          className="multitags-inputSingle"
          onClick={() => {
            setIsOpenData(true);
            inputFieldFocus();
          }}
        >
          <div className="d-flex" style={{ width: "88%" }}>
            <ul id="multitagsSingle">
              {preSelectedTag.map((tag, index) => {
                return (
                  <li key={index} className="multitagSingle">
                    <span
                      className="multitag-titleSingle"
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      title={tag}
                    >
                      {tag}
                    </span>
                  </li>
                );
              })}

              {onTag.map((tag, index) => {
                return (
                  <li key={index} className="multitagSingle">
                    <span
                      className="multitag-titleSingle"
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      title={tag}
                    >
                      {tag}
                    </span>
                  </li>
                );
              })}
            </ul>
            <input
              type="text"
              id="fltLocationSingle"
              onKeyPress={firstSpaceValidation}
              value={searchValue}
              autoComplete="off"
              onChange={onSearchCahnge}
              placeholder={onTag.length === 0 ? placeholder : ""}
              className={`multiInputSingle multiInputSelect ${
                onTag.length === 1 ? "d-none" : ""
              } ${otherField ? "d-none" : ""}`}
              onFocus={() => setIsOpenData(true)}
              disabled={otherField}
              onClick={() => {
                setSelectedIndexS(null);
                setIsOpenData(true);
                scrollToTopList();
              }}
              style={{ padding: "8px 13px", borderRadius: "8px" }}
            />
          </div>
        </div>

        <div
          htmlFor="fltLocationSingle"
          className={`${isOpenData === true ? "" : "displayNone"}`}
        >
          <div className={`dropDownListSingle1`}>
            <input
              type="text"
              id=""
              autoComplete="off"
              onKeyPress={firstSpaceValidation}
              value={searchValue}
              onChange={onSearchCahnge}
              placeholder="search"
              className={`multiInputSingle1 multiInputSelect ${
                onTag.length < 1 ? "d-none" : ""
              } ${otherField ? "d-none" : ""}`}
              onFocus={() => setIsOpenData(true)}
              disabled={otherField}
              onClick={() => {
                setSelectedIndexS(null);
                setIsOpenData(true);
                scrollToTopList();
              }}
              style={{
                padding: "8px 13px",
                borderRadius: "8px",
                border: "1px solid #e0e1e3",
              }}
              ref={inputsub}
            />

            <div className={`dropDownListSingle`} ref={dropDownListRef}>
              {searchedOptions
                // .filter((option) => {
                //   if (searchValue === "") {
                //     return option;
                //   } else if (
                //     option[label]
                //       .toLowerCase()
                //       .includes(searchValue.toLowerCase())
                //   ) {
                //     return option;
                //   }
                // })
                .map((option, i) => {
                  const value = option[label];
                  return (
                    <div
                      key={i}
                      onClick={(e) => addTags(e, value)}
                      onMouseMove={(e) => setSelectedIndexS(i)}
                      className={`dropDownListItemSingleSelected ${
                        selectedIndexS === i
                          ? "dropDownListItemSelected"
                          : "dropDownListItemNotSelected"
                      }`}
                    >
                      {value}
                    </div>
                  );
                })}
              <div
                onClick={(e) => {
                  addTags(e, "OTHER");
                  setOtherField(true);
                  setIsOpenData(false);
                }}
                onMouseMove={(e) => setSelectedIndexS(searchedOptions.length)}
                className={`dropDownListItemSingleSelected ${
                  selectedIndexS === searchedOptions.length
                    ? "dropDownListItemSelected"
                    : "dropDownListItemNotSelected"
                }`}
              >
                OTHER
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiInputDropDownTag;
