import React from "react";
import tag from "../../images/tag.png";
import SocialShare from "./SocialShare";
function JobSkill(props) {
  const { item } = props;
  const skillNameJob =
    item.job_skills !== null && item.job_skills !== undefined
      ? item.job_skills.split(",")
      : [];
  const skillColorjob =
    item.colors !== null && item.colors !== undefined
      ? item.colors.split(",")
      : [];

  const message = `\*Job Title:\* ${item.job_title}\n\*Experience:\* ${
    item.job_exp
  }\n\*Location(s):\* ${item.job_loc}\n\*Skills:\* ${item.job_skills}\n${
    item.ctc ? `\*Budget:\* ${item.ctc}\n` : ""
  }\n\*Apply here\* \n${window.location.href}`;
  const message2 = `Job Title: ${item.job_title} ${
    item.no_of_position > 1 ? "(" + item.no_of_position + " positions)" : ""
  }\n\nExperience: ${item.job_exp}\n\nLocation(s): ${item.job_loc}\n\nSkills: ${
    item.job_skills
  }\n\nBudget:${item.ctc}\n\nApply here: \n${window.location.href}`;
  const teleMessage = `\**Job Title:\** ${item.job_title}\n\**Experience:\** ${
    item.job_exp
  }\n\**Location(s):\** ${item.job_loc}\n\**Skills:\** ${item.job_skills}\n${
    item.ctc ? `\**Budget:\** ${item.ctc}\n` : ""
  }\n\**Apply here\** \n${window.location.href}`;

  const URIMessage = encodeURIComponent(message);
  const URIMessageTele = encodeURIComponent(teleMessage);
  return (
    <div>
      <div
        className="webViewSocialShare"
        style={{ position: "fixed", right: "0%", top: "43.6%", zIndex: "1" }}
      >
        <SocialShare
          URIMessageTele={URIMessageTele}
          URIMessage={URIMessage}
          url={window.location.href}
          title=""
          message={message2}
        />
      </div>
      <div className="mobileViewSocialShareD tag-box">
        <SocialShare
          URIMessageTele={URIMessageTele}
          URIMessage={URIMessage}
          url={window.location.href}
          title=""
        />
      </div>
      <h3 className="job-skills-2">Job Skills</h3>
      <div className="tag-box">
        {skillNameJob.map((item, i) => {
          return (
            <button
              key={i}
              className="tags btn me-2 mb-2"
              style={{ background: `${skillColorjob[i]}` }}
            >
              <img className="pe-1" src={tag} alt="tag" />
              {item}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default JobSkill;
