import React, { useState, useEffect, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import ShareButtonWith from "../../images/sharebuttonIcon.png";
import ShareIconClose from "../../images/ShareIconClose.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
//import Image from "next/image";
import { CopyToClipboard } from "react-copy-to-clipboard";
//import Link from "next/link";
import { Link } from "react-router-dom";
import style from "../../style/SocialShare3.module.css";

const SocialShare = ({
  jobUId,
  appJobUniqueId,
  setAppJobUniqueId,
  copyMessage,
  URIMessage,
  url,
  title,
  URIMessageTele,
  styleCss,
}) => {
  const shareMessage = ``;
  const [openWhatsApp, setOpenWhatsApp] = useState(false);
  const [openLinkedIn, setOpenLinkedIn] = useState(false);
  const [openFacebook, setOpenFacebook] = useState(false);
  const [openTwitter, setopenTwitter] = useState(false);
  const [openTelegram, setOpenTelegram] = useState(false);
  const [copyLink, setCopyLink] = useState("Copy Job Detail");
  const [openCopy, setOpenCopy] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const componentRef = useRef(null);
  const handleTooltipWhatsAppClose = () => {
    setOpenWhatsApp(false);
  };

  const handleTooltipWhatsAppOpen = () => {
    setOpenWhatsApp(true);
  };

  const handleTooltipLinkedInClose = () => {
    setOpenLinkedIn(false);
  };

  const handleTooltipLinkedInOpen = () => {
    setOpenLinkedIn(true);
  };

  const handleTooltipFacebookClose = () => {
    setOpenFacebook(false);
  };

  const handleTooltipFacebookOpen = () => {
    setOpenFacebook(true);
  };

  const handleTooltipTwitterClose = () => {
    setopenTwitter(false);
  };

  const handleTooltipTwitterOpen = () => {
    setopenTwitter(true);
  };

  const handleTooltipTelegramClose = () => {
    setOpenTelegram(false);
  };

  const handleTooltipTelegramOpen = () => {
    setOpenTelegram(true);
  };

  const copiedLink = () => {
    setCopyLink("Copied");
  };

  const handleTooltipClose = () => {
    setOpenCopy(false);
    setCopyLink("Copy Job Detail");
  };

  const handleTooltipOpen = () => {
    setOpenCopy(true);
  };

  const tooltipWhatsAppContent = (
    <span style={{ whiteSpace: "nowrap" }}>Share on WhatsApp</span>
  );

  const tooltipLinkedInContent = (
    <span style={{ whiteSpace: "nowrap" }}>Share on LinkedIn</span>
  );

  const tooltipFacebookContent = (
    <span style={{ whiteSpace: "nowrap" }}>Share on Facebook</span>
  );

  const tooltipTelegramContent = (
    <span style={{ whiteSpace: "nowrap" }}>Share on Telegram</span>
  );

  const tooltipTwitterContent = (
    <span style={{ whiteSpace: "nowrap" }}>Share on Twitter</span>
  );

  const whatsappURL = `https://api.whatsapp.com/send?text=${URIMessage}`;

  const TelegramURL = `https://t.me/share/url?url=${URIMessageTele}`;

  const handleToVisible = () => {
    const value = `share_${jobUId}`;
    setAppJobUniqueId(value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (appJobUniqueId !== null) {
        const socialShareIdToClose = document.getElementById(appJobUniqueId);
        if (!socialShareIdToClose.contains(event.target)) {
          setAppJobUniqueId(null);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appJobUniqueId]);

  return (
    <div id={`share_${jobUId}`} ref={componentRef}>
      <div>
        <div
          className={`${style.ShareIcon} ShareIcon-top`}
          style={{
            display: `${
              `share_${jobUId}` === appJobUniqueId ? "flex" : "none"
            }`,
            visibility: `${`share_${jobUId}` === appJobUniqueId ? "" : "none"}`,
            // display: openShare ? "flex" : "none",
            // visibility: openShare ? "" : "none",
          }}
        >
          <div className={`${style.CopyStyle}`}>
            <span className="" title="">
              <CopyToClipboard
                text={copyMessage}
                onMouseLeave={handleTooltipClose}
                onMouseEnter={handleTooltipOpen}
              >
                <span>
                  <Tooltip
                    arrow
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={openCopy}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={`${copyLink}`}
                    placement="top"
                  >
                    <span onClick={copiedLink}>
                      <ContentCopyIcon id={style.MuiSvgIconCopy} />
                    </span>
                  </Tooltip>
                </span>
              </CopyToClipboard>
            </span>
          </div>

          <Link
            to={whatsappURL}
            target="_blank"
            className={style.WhatsappStyle}
            onMouseEnter={handleTooltipWhatsAppOpen}
            onMouseLeave={handleTooltipWhatsAppClose}
          >
            <Tooltip
              arrow
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipWhatsAppClose}
              open={openWhatsApp}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltipWhatsAppContent}
              placement="top"
            >
              <WhatsAppIcon id={style.MuiSvgIconWhatsApp} />
            </Tooltip>
          </Link>
          <LinkedinShareButton
            className={style.LinkedinStyle}
            url={url}
            title={title}
            onMouseEnter={handleTooltipLinkedInOpen}
            onMouseLeave={handleTooltipLinkedInClose}
          >
            <Tooltip
              arrow
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipLinkedInClose}
              open={openLinkedIn}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltipLinkedInContent}
              placement="top"
            >
              <LinkedInIcon id={style.MuiSvgIconLinkedin} />
            </Tooltip>
          </LinkedinShareButton>
          <Link
            to={TelegramURL}
            target="_blank"
            className={style.TwitterStyle}
            onMouseEnter={handleTooltipTelegramOpen}
            onMouseLeave={handleTooltipTelegramClose}
          >
            <Tooltip
              arrow
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipTelegramClose}
              open={openTelegram}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltipTelegramContent}
              placement="top"
            >
              <TelegramIcon id={style.MuiSvgIconTelegram} />
            </Tooltip>
          </Link>
          <TwitterShareButton
            className={style.TwitterStyle}
            url={url}
            onMouseEnter={handleTooltipTwitterOpen}
            onMouseLeave={handleTooltipTwitterClose}
          >
            <Tooltip
              arrow
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipTwitterClose}
              open={openTwitter}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltipTwitterContent}
              placement="top"
            >
              <TwitterIcon id={style.MuiSvgIconTwitter} />
            </Tooltip>
          </TwitterShareButton>
          <FacebookShareButton
            className={style.FacebookStyle}
            url={url}
            quote={title}
            onMouseEnter={handleTooltipFacebookOpen}
            onMouseLeave={handleTooltipFacebookClose}
          >
            <Tooltip
              arrow
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipFacebookClose}
              open={openFacebook}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltipFacebookContent}
              placement="top"
            >
              <FacebookOutlinedIcon id={style.MuiSvgIconFacebook} />
            </Tooltip>
          </FacebookShareButton>
        </div>

        {`share_${jobUId}` === appJobUniqueId ? (
          <div
            className={`${style.ShareButton}`}
            onClick={() => setAppJobUniqueId(null)}
            style={{ bottom: styleCss.bottom }}
          >
            <img
              height={16}
              width={16}
              src={ShareIconClose}
              className="shareButtonImg"
              alt=""
            />
          </div>
        ) : (
          <div
            className={style.ShareButton}
            style={{ bottom: styleCss.bottom }}
            onClick={() => handleToVisible()}
          >
            <img
              height={16}
              width={16}
              src={ShareButtonWith}
              className="shareButtonImg"
              alt=""
              title="Share"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SocialShare;
