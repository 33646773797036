import React, { useState, useContext, useRef } from "react";
import CreatContext from "../../context/CreatContext";
import MultiInputTags from "./MultiInputTags";
import TuneIcon from "@mui/icons-material/Tune";
import MultiInputDropDownTag from "./MultiInputDropDownTag";

// import images
import clear_filter_1 from "../../images/clear-filter-1.png";

const FilterSearchMobileView = (props) => {
  const { setPage } = props;
  const context = useContext(CreatContext);
  const {
    getJobDetail,
    locInfo,
    enterSkillDesignation,
    setEnterSkillDesignation,
    enterMaxExperience,
    setEnterMaxExperience,
    enterLocation,
    setEnterLocation,
  } = context;

  const refFilterMobileClose = useRef();

  const [activeFilterClass, setActiveFilterClass] = useState("skills");
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);

  const [minExpKey, setMinExpKey] = useState("");

  const [fullTime, setFullTime] = useState(false);
  const [temporaryTime, setTemporaryTime] = useState(false);
  const [partTime, setPartTime] = useState(false);
  const [employmentType, setEmploymentType] = useState([]);

  const [jobPostTime, setJobPostTime] = useState("All time");

  const options = locInfo.map((value) => {
    return {
      Location: value.cityName,
    };
  });

  const multiselectOnChange = (value) => {
    // getLocationDetails(value);
  };

  const handleKeyPressExp = (event) => {
    if (
      event.keyCode === 8 ||
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 46
    ) {
      return true;
    }
    event.preventDefault();
    return false;
  };

  const onChangeCheckBox = (data) => {
    if (data === "Full Time") {
      setFullTime(!fullTime);
      if (fullTime === false) {
        setEmploymentType([...employmentType, "Full Time"]);
      } else {
        setEmploymentType([
          ...employmentType.filter((item) => {
            return item !== "Full Time";
          }),
        ]);
      }
    }
    if (data === "Temporary") {
      setTemporaryTime(!temporaryTime);
      if (temporaryTime === false) {
        setEmploymentType([...employmentType, "Temporary"]);
      } else {
        setEmploymentType([
          ...employmentType.filter((item) => {
            return item !== "Temporary";
          }),
        ]);
      }
    }
    if (data === "Part-Time") {
      setPartTime(!partTime);
      if (partTime === false) {
        setEmploymentType([...employmentType, "Part-Time"]);
      } else {
        setEmploymentType([
          ...employmentType.filter((item) => {
            return item !== "Part-Time";
          }),
        ]);
      }
    }
  };

  const onChangeRadio = (e) => {
    setJobPostTime(e.target.value);
  };

  const mobileOperationFilter = (data) => {
    setActiveFilterClass(data);
  };

  const mobileFilterForOpenClose = () => {
    setMobileFilterOpen(!mobileFilterOpen);
  };

  const clearFilter = async () => {
    setEnterSkillDesignation([]);
    setEnterLocation([]);
    setMinExpKey("");
    setEnterMaxExperience("");
    setFullTime(false);
    setTemporaryTime(false);
    setPartTime(false);
    setEmploymentType([]);
    setJobPostTime("All time");

    getJobDetail("All time", "", "", 0, 0, "");
    setPage(1);
  };

  const jobSearchFilterJobBoard = async () => {
    // console.log(employmentTypeString);
    // getJobDetail('All time', '', '', 0, 0, 'Full Time');

    let keywordInputString = "";
    let locationSearchString = "";
    let minExperience = 0;
    let maxExperience = 0;

    if (enterSkillDesignation.length > 0) {
      keywordInputString = enterSkillDesignation.join(", ");
    }

    if (enterLocation.length > 0) {
      locationSearchString = enterLocation
        .map((item) => {
          return item.Location;
        })
        .join(", ");
    }

    if (minExpKey !== "") {
      minExperience = minExpKey;
    }
    if (enterMaxExperience !== "") {
      maxExperience = enterMaxExperience;
    }

    let empTypeString = employmentType.join(", ");

    getJobDetail(
      jobPostTime,
      keywordInputString,
      locationSearchString,
      minExperience,
      maxExperience,
      empTypeString
    );
    setPage(1);
    refFilterMobileClose.current.click();
  };

  return (
    <>
      <div>
        <div
          className="d-flex mb-2 filterSearchMobileViewBtn"
          onClick={mobileFilterForOpenClose}
        >
          <div className="me-2">
            <div
              style={{
                background: "#0d6efd",
                borderRadius: "8px",
                padding: "3px",
              }}
            >
              <TuneIcon
                data-bs-toggle="modal"
                data-bs-target="#filterModalOpenMobileView"
                style={{ fontSize: "32px", marginLeft: "0px", color: "#fff" }}
                onClick={() => mobileOperationFilter("skills")}
              />
            </div>
          </div>
          <div className="me-2">
            <button
              type="button"
              style={{
                whiteSpace: "nowrap",
                background: `${
                  enterSkillDesignation.length > 0 ? "#0d6efd" : "none"
                }`,
                color: `${
                  enterSkillDesignation.length > 0 ? "#fff" : "#0d6efd"
                }`,
              }}
              className="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#filterModalOpenMobileView"
              onClick={() => mobileOperationFilter("skills")}
            >
              {enterSkillDesignation.length > 0
                ? enterSkillDesignation.length
                : ""}{" "}
              {enterSkillDesignation.length > 0 ? "-" : ""} Designation & Skills
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              style={{
                whiteSpace: "nowrap",
                background: `${enterLocation.length > 0 ? "#0d6efd" : "none"}`,
                color: `${enterLocation.length > 0 ? "#fff" : "#0d6efd"}`,
              }}
              className="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#filterModalOpenMobileView"
              onClick={() => mobileOperationFilter("location")}
            >
              {enterLocation.length > 0 ? enterLocation.length : ""}{" "}
              {enterLocation.length > 0 ? "-" : ""} Location
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              style={{
                whiteSpace: "nowrap",
                background: `${employmentType.length > 0 ? "#0d6efd" : "none"}`,
                color: `${employmentType.length > 0 ? "#fff" : "#0d6efd"}`,
              }}
              className="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#filterModalOpenMobileView"
              onClick={() => mobileOperationFilter("emptype")}
            >
              {employmentType.length > 0 ? employmentType.length : ""}{" "}
              {employmentType.length > 0 ? "-" : ""}Type of employment
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              style={{ whiteSpace: "nowrap" }}
              className="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#filterModalOpenMobileView"
              onClick={() => mobileOperationFilter("dtposting")}
            >
              Date of posting
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              style={{
                whiteSpace: "nowrap",
                background: `${
                  minExpKey.length > 0 || enterMaxExperience.length > 0
                    ? "#0d6efd"
                    : "none"
                }`,
                color: `${
                  minExpKey.length > 0 || enterMaxExperience.length > 0
                    ? "#fff"
                    : "#0d6efd"
                }`,
              }}
              className="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#filterModalOpenMobileView"
              onClick={() => mobileOperationFilter("yearexp")}
            >
              Years of experience
            </button>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="filterModalOpenMobileView"
        tabIndex="-1"
        aria-labelledby="exampleModalLabelMobile"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabelMobile">
                Filters
              </h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <span className="clear-all cursorPointer" onClick={clearFilter}>
                Clear all{" "}
                <img
                  className="position-relative"
                  src={clear_filter_1}
                  alt=""
                  style={{ bottom: "1px" }}
                />
              </span>
            </div>
            <div className="modal-body">
              <div className={`d-flex` /** border-bottom-1 */}>
                <div className="">
                  <div
                    className={`filterPostMobileViewSizeLeft d-flex align-items-center ${
                      activeFilterClass === "skills"
                        ? "mobileFilterActivityDisplay"
                        : ""
                    }`}
                  >
                    <p
                      className="filter-text-mobile-view mb-0"
                      onClick={() => mobileOperationFilter("skills")}
                    >
                      Designation & Skills
                    </p>
                  </div>
                  <div
                    className={`filterPostMobileViewSizeLeft d-flex align-items-center ${
                      activeFilterClass === "location"
                        ? "mobileFilterActivityDisplay"
                        : ""
                    }`}
                  >
                    <p
                      className="filter-text-mobile-view mb-0"
                      onClick={() => mobileOperationFilter("location")}
                    >
                      Location
                    </p>
                  </div>
                  <div
                    className={`filterPostMobileViewSizeLeft d-flex align-items-center ${
                      activeFilterClass === "emptype"
                        ? "mobileFilterActivityDisplay"
                        : ""
                    }`}
                  >
                    <p
                      className="filter-text-mobile-view mb-0"
                      onClick={() => mobileOperationFilter("emptype")}
                    >
                      Type of employment
                    </p>
                  </div>
                  <div
                    className={`filterPostMobileViewSizeLeft d-flex align-items-center ${
                      activeFilterClass === "dtposting"
                        ? "mobileFilterActivityDisplay"
                        : ""
                    }`}
                  >
                    <p
                      className="filter-text-mobile-view mb-0"
                      onClick={() => mobileOperationFilter("dtposting")}
                    >
                      Date of posting
                    </p>
                  </div>
                  <div
                    className={`filterPostMobileViewSizeLeft d-flex align-items-center ${
                      activeFilterClass === "yearexp"
                        ? "mobileFilterActivityDisplay"
                        : ""
                    }`}
                  >
                    <p
                      className="filter-text-mobile-view mb-0"
                      onClick={() => mobileOperationFilter("yearexp")}
                    >
                      Years of experience
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    className={`filterPostMobileViewSizeRight ${
                      activeFilterClass === "skills" ? "" : "mobileFilterActive"
                    }`}
                  >
                    <span className="d-flex align-items-center multiSpan">
                      {/* <img src={MagnifyingGlass} onClick={() => jobSearchFilterJobBoard(jobPostTime, keywordInputString, locationKeyString, minExpKey, maxExpKey, 'Full Time')} /> */}
                      <MultiInputTags
                        className="form-control custom-input-1 NoStar jobBoardTagsInput"
                        selectedTags={(value) =>
                          setEnterSkillDesignation(value)
                        }
                        onTag={enterSkillDesignation}
                        placeholder={"Input text"}
                      />
                    </span>
                  </div>
                  <div
                    className={`filterPostMobileViewSizeRight ${
                      activeFilterClass === "location"
                        ? ""
                        : "mobileFilterActive"
                    }`}
                  >
                    <span className="d-flex align-items-center multiSelectSpam">
                      {/* <img src={MagnifyingGlass} onClick={() => jobSearchFilterJobBoard(jobPostTime, keywordInputString, locationKeyString, minExpKey, maxExpKey, 'Full Time')} /> */}
                      {/* <Multiselect className=" select2 select2-hidden-accessible heightIncMul" id="locationJobBoard" style={{ width: '100%' }} options={options} name="locationKey" onSelect={(value) => { setEnterLocation(value) }} onRemove={(list, itemoflist) => {
                                                setEnterLocation(enterLocation.filter((item) => { return item !== itemoflist }));
                                            }} selectedValues={enterLocation} onSearch={(value) => multiselectOnChange(value)} displayValue='Location' placeholder={enterLocation.length === 0 ? 'Preffered Location' : ''} /> */}
                      <MultiInputDropDownTag
                        onTag={enterLocation}
                        setOnTag={(value) => {
                          setEnterLocation(value);
                        }}
                        label="Location"
                        preSelectedTag={[]}
                        placeholder="Search by Location"
                        options={options}
                        onSearch={(value) => {
                          multiselectOnChange(value);
                        }}
                      />
                    </span>
                  </div>
                  <div
                    className={`filterPostMobileViewSizeRight  ${
                      activeFilterClass === "emptype"
                        ? ""
                        : "mobileFilterActive"
                    }`}
                    style={{ padding: "0px 10px 5px 10px", border: "none" }}
                  >
                    <span className="d-flex flex-column align-items-start">
                      {/* <img src={MagnifyingGlass} /> */}
                      <ul
                        className="	list-group"
                        style={{}}
                        id="employementType"
                      >
                        <li className="list-group-item border-0 p-0 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="custom-checkbox cursor-pointer"
                            id="checkBoxFullTime1"
                            value="Full Time"
                            onChange={() => {
                              onChangeCheckBox("Full Time");
                            }}
                            checked={fullTime}
                          />
                          <label htmlFor="checkBoxFullTime1">
                            <div className="mt-alignment-span-1 checkbox-label mx-2">
                              Full-time
                            </div>
                          </label>
                        </li>
                        <li className="list-group-item border-0 p-0 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="custom-checkbox cursor-pointer"
                            id="checkBoxTemporary2"
                            value="Temporary"
                            onChange={() => {
                              onChangeCheckBox("Temporary");
                            }}
                            checked={temporaryTime}
                          />
                          <label htmlFor="checkBoxTemporary2">
                            <div className="mt-alignment-span checkbox-label mx-2">
                              Temporary
                            </div>
                          </label>
                        </li>
                        <li className="list-group-item border-0 p-0 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="custom-checkbox cursor-pointer"
                            id="checkBoxPartTime3"
                            value="Part-Time"
                            onChange={() => {
                              onChangeCheckBox("Part-Time");
                            }}
                            checked={partTime}
                          />
                          <label htmlFor="checkBoxPartTime3">
                            <div className="mt-alignment-span checkbox-label mx-2">
                              Part-time
                            </div>
                          </label>
                        </li>
                      </ul>
                    </span>
                  </div>
                  <div
                    className={`filterPostMobileViewSizeRight  ${
                      activeFilterClass === "dtposting"
                        ? ""
                        : "mobileFilterActive"
                    }`}
                    style={{ padding: "0px 10px 5px 10px", border: "none" }}
                  >
                    <span className="d-flex flex-column align-items-start">
                      {/* <img src={MagnifyingGlass} onClick={() => jobSearchFilterJobBoard(jobPostTime, keywordInputString, locationKeyString, minExpKey, maxExpKey, 'Full Time')} /> */}

                      <div className="d-flex justify-content-center custom-control custom-radio mt_2 fw-500">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="cap-opt-11"
                          name="capacity12"
                          value="All time"
                          checked={jobPostTime === "All time"}
                          onChange={(e) => onChangeRadio(e)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="cap-opt-11"
                        >
                          <span className="cap-opt-1 mx-2">All time</span>
                        </label>
                      </div>
                      <div className="d-flex justify-content-center custom-control custom-radio fw-500">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="cap-opt-22"
                          name="capacity12"
                          value="Last 24 hours"
                          checked={jobPostTime === "Last 24 hours"}
                          onChange={(e) => onChangeRadio(e)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="cap-opt-22"
                        >
                          <span className="cap-opt-1 mx-2">Last 24 hours </span>
                        </label>
                      </div>
                      <div className="d-flex justify-content-center custom-control custom-radio fw-500">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="cap-opt-33"
                          name="capacity12"
                          value="Last 3 days"
                          checked={jobPostTime === "Last 3 days"}
                          onChange={(e) => onChangeRadio(e)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="cap-opt-33"
                        >
                          <span className="cap-opt-1 mx-2">Last 3 days</span>
                        </label>
                      </div>
                      <div className="d-flex justify-content-center custom-control custom-radio fw-500">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="cap-opt-44"
                          name="capacity12"
                          value="Last 7 days"
                          checked={jobPostTime === "Last 7 days"}
                          onChange={(e) => onChangeRadio(e)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="cap-opt-44"
                        >
                          <span className="cap-opt-1 mx-2">Last 7 days</span>
                        </label>
                      </div>
                    </span>
                  </div>

                  <div
                    className={`filterPostMobileViewSizeRight  ${
                      activeFilterClass === "yearexp"
                        ? ""
                        : "mobileFilterActive"
                    }`}
                  >
                    <span className="d-flex align-items-start">
                      {/* <img src={MagnifyingGlass} /> */}
                      <div>
                        <span>Min Experience</span>
                        <input
                          className="form-control form-control-sm mb-2"
                          style={{ height: "30px" }}
                          type="text"
                          name=""
                          id="valueMaxExp"
                          onKeyPress={handleKeyPressExp}
                          maxLength={4}
                          min={0.0}
                          step="0.1"
                          placeholder="In Years"
                          value={minExpKey}
                          onChange={(e) => {
                            if (/^\d*\.?\d{0,1}$/.test(e.target.value)) {
                              setMinExpKey(e.target.value);
                            }
                          }}
                        />
                        <span>Max Experience</span>
                        <input
                          className="form-control form-control-sm "
                          style={{ height: "30px" }}
                          onKeyPress={handleKeyPressExp}
                          maxLength={4}
                          type="text"
                          name=""
                          id="valueMaxExp"
                          min={0.0}
                          step="0.1"
                          placeholder="In Years"
                          value={enterMaxExperience}
                          onChange={(e) => {
                            if (/^\d*\.?\d{0,1}$/.test(e.target.value)) {
                              setEnterMaxExperience(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={refFilterMobileClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => jobSearchFilterJobBoard()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterSearchMobileView;
