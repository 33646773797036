import React from 'react';

const FormatDate = (props) => {
  const originalDate = new Date(props.date);
  const day = originalDate.getDate();
  const month = originalDate.toLocaleString('default', { month: 'short' });
  const fullMonth = originalDate.toLocaleString('default', { month: 'long' });
  const year = originalDate.getFullYear();

  const fullMonths = ['June', 'July'];
  const showFullMonth = fullMonths.includes(fullMonth);

  const displayDate = showFullMonth
    ? `${day} ${fullMonth} ${year}`
    : `${day} ${month} ${year}`;

  return (
    <div>
      <p style={{ fontWeight: '600' }} title={`Posted on ${displayDate}`}>{displayDate}</p>
    </div>
  );
};

export default FormatDate;
