import React, { useState } from "react";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import Tooltip from "@mui/material/Tooltip";
import SocialShare from "./SocialShare";
// import images
import clock from "../../images/Clock.png";
import brief from "../../images/brief.png";
import calendarBlank from "../../images/CalendarBlank.png";
import location_job_board from "../../images/location-job-board.png";
import settingsIcon from "../../images/SettingsIcon.png";

const JobCard = (props) => {
  const {
    item,
    jobBoardApplyNowTicketJmID,
    openJobDetail,
    storeJobUniqueIdJobBoard,
    setStoreJobUniqueIdJobBoard,
  } = props;

  const hostUI = window.location.origin;
  //const clientLogo = process.env.REACT_APP_CLIENT_LOGO;
  const [open, setOpen] = useState(false);

  const skillName = item.skill_name.split(",");
  const skillColor = item.colors.split(",");

  const encryptFunction = (jmIdValue, jobUniqueId, skillName) => {
    openJobDetail(jobUniqueId, skillName);
  };

  const handleCopy = (jm_id) => {
    // const jmIdValueStr = `hello yr My Name Is Bhavya Killer 132436${jm_id}3646`;
    // const jobEncrypt = encrypt(jmIdValueStr, secretcode);
    // navigator.clipboard.writeText(
    //   `http://localhost:3000/jobDetail${jobEncrypt}`
    // );
    // navigator.clipboard.writeText(jm_id);
    // setCopyUrl('Copied');
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const jobBoardJobTicketApplyOnJob = (
    jmIdValue,
    jobTitleStr,
    jobQuesPresent,
    item
  ) => {
    jobBoardApplyNowTicketJmID(jmIdValue, jobTitleStr, jobQuesPresent, item);
  };
  const jobTitleSection = item.job_title
    ? `Job Title: ${item.job_title} ${
        item.no_of_position > 1 ? "(" + item.no_of_position + " positions)" : ""
      }\n\n`
    : "";
  const experienceSection = item.job_exp
    ? `Experience: ${item.job_exp}\n\n`
    : "";
  const locationSection = item.preferred_location
    ? `Location(s): ${item.preferred_location}\n\n`
    : "";
  const skillsSection = item.skill_name ? `Skills: ${item.skill_name}\n\n` : "";
  const budgetSection = item.budget ? `Budget: ${item.budget}\n\n` : "";
  const applyHereSection = `${hostUI}/job-detail${item.job_unique_id}`;

  const copyMessage = `${jobTitleSection}${experienceSection}${locationSection}${skillsSection}${budgetSection}Apply here: \n${applyHereSection}`;

  const message = `\*Job Title:\* ${item.job_title}\n\*Experience:\* ${
    item.job_exp
  }\n\*Location(s):\* ${item.preferred_location}\n\*Skills:\* ${
    item.skill_name
  }\n${
    item.budget ? `\*Budget:\* ${item.budget}\n` : ""
  }\n\*Apply here\* \n${hostUI}/job-detail${item.job_unique_id}`;

  const teleMessage = `\**Job Title:\** ${item.job_title}\n\**Experience:\** ${
    item.job_exp
  }\n\**Location(s):\** ${item.preferred_location}\n\**Skills:\** ${
    item.skill_name
  }\n${
    item.budget ? `\**Budget:\** ${item.budget}\n` : ""
  }\n\**Apply here\** \n${hostUI}/job-detail${item.job_unique_id}`;

  const URIMessage = encodeURIComponent(message);
  const URIMessageTele = encodeURIComponent(teleMessage);
  return (
    <>
      <div id="ticketArea" className="row fixedScroll-jobboardticket"></div>

      <div className="col-lg-12 mb-2 pr-0">
        {" "}
        <div
          className="card-box position-relative"
          id="jobBoardjobTicket"
          style={{ height: "180px" }}
        >
          {/* <span className="d-none">`+val.jm_id+`</span> */}
          <div className="position-absolute" style={{ right: "22px" }}>
            <button
              type="button"
              className="btn applyNow btn-primary"
              style={{ width: "90px" }}
              onClick={() =>
                jobBoardJobTicketApplyOnJob(
                  item.jm_id,
                  item.job_title,
                  item.job_ques_present,
                  item
                )
              }
              data-bs-toggle="modal"
              data-bs-target="#userJobDetailModal"
            >
              Apply Now
            </button>
          </div>
          <div
            className="row cursor-pointer"
            onClick={() => {
              encryptFunction(item.jm_id, item.job_unique_id, item.skill_name);
            }}
          >
            <div className="row mb-3">
              <div
                className="col-lg-1 col-sm-1 pr-0"
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => {
                  encryptFunction(
                    item.jm_id,
                    item.job_unique_id,
                    item.skill_name
                  );
                }}
              >
                {item.client_logo !== null ? (
                  <img
                    //src={clientLogo + item.client_logo}
                    src={item.client_logo}
                    className="ticketImg"
                    alt=""
                  />
                ) : (
                  <div className="companyInitials">{item.company_initials}</div>
                )}
              </div>
              <div className="col-lg-11 col-sm-11 jobTitleSiteName">
                <div
                  className="col-lg-12 pr-0"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="row pl-3" style={{ cursor: "pointer" }}>
                    <div className="col-lg-12 d-flex align-items-baseline pl-0">
                      <h4
                        className="ats-card-heading-text jobBoard-ellipliswithCustomizeWidth mb-0"
                        title={item.job_title}
                      >
                        {item.job_title}
                      </h4>
                    </div>
                    <div
                      className="ats-company-name jobBoard-companynameWidth cursorPointer"
                      title="Company Name"
                    >
                      {item.site_name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-12 mb-3"
                style={{
                  paddingLeft: "3px",
                  paddingRight: "30px",
                  marginTop: "-5px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div className="d-flex align-items-baseline padding-overall-left-right">
                    <div>
                      <img src={clock} alt="" />
                    </div>
                    <p className="ats-text-info jobBoard-elliplis" title="">
                      {item.employment_type}
                    </p>
                  </div>
                  <div className="d-flex align-items-baseline padding-overall-left-right">
                    <div>
                      <img src={brief} alt="" />
                    </div>
                    <p className="ats-text-info">{item.job_exp}</p>
                  </div>
                  <div className="d-flex align-items-baseline padding-overall-left-right">
                    <div>
                      <img src={calendarBlank} alt="" />
                    </div>
                    <p className="ats-text-info jobBoard-elliplis">
                      {item.joining_period}
                    </p>
                  </div>
                  <div
                    className="d-flex align-items-baseline padding-overall-left-right"
                    style={{ width: "190px" }}
                  >
                    <div>
                      <img src={location_job_board} alt="" />
                    </div>
                    <p
                      className="ats-text-info jobBoard-elliplis"
                      title={item.preferred_location}
                    >
                      {item.preferred_location}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-12  mb-2"
                style={{ paddingLeft: "13px", paddingRight: "0px" }}
              >
                <div className="SkillScrollJobTicket">
                  <div>
                    <img
                      src={settingsIcon}
                      style={{ width: "16px", height: "16px" }}
                      alt=""
                    />
                  </div>
                  {skillName.map((skill, i) => {
                    return (
                      <div
                        key={i}
                        className="skillDiv ms-2"
                        style={{ background: `${skillColor[i]}` }}
                      >
                        <p className="skillName">{skill}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mt-1">
            <div
              className="d-flex align-items-baseline pl-3"
              style={{
                justifyContent: "space-between",
                paddingRight: "10px",
                position: "absolute",
                width: "96%",
                zIndex: "100",
                bottom: "5px",
                height: "31px",
              }}
            >
              <p className="PostedBy mb-0">
                Posted By :{" "}
                <span className="PostedBySub">{item.uploaded_by}</span>{" "}
                {item.job_createddt}{" "}
              </p>

              <div style={{ display: "flex", alignItems: "baseline" }}>
                <p className="ats-card-position-text mb-1" style={{}}>
                  {item.no_of_position} Position(s)
                </p>

                <div>
                  <SocialShare
                    setAppJobUniqueId={setStoreJobUniqueIdJobBoard}
                    appJobUniqueId={storeJobUniqueIdJobBoard}
                    jobUId={item.jm_id}
                    copyMessage={copyMessage}
                    URIMessageTele={URIMessageTele}
                    URIMessage={URIMessage}
                    url={`${hostUI}/job-detail${item.job_unique_id}`}
                    title=""
                    styleCss={{ bottom: "26px" }}
                  />
                </div>

                {/* <div
                  className="CopyLinkDiv"
                  onClick={() => handleCopy(item.jm_id)}
                >
                  <span
                    className="ats-text-info cursorPointer"
                    title=""
                    style={{
                      marginLeft: "4px",
                      fontWeight: "500",
                      color: "#000000",
                      display: "flex",
                    }}
                  >
                    <img
                      className="position-relative"
                      src={vector}
                      style={{
                        marginRight: "5px",
                        height: "13px",
                        top: "3px",
                      }}
                      alt=""
                    />
                    <CopyToClipboard
                      // text={`${hostUI}/jobDetail${jobEncrypt}`}
                      text={`${hostUI}/job-detail${item.job_unique_id}`}
                      onMouseLeave={handleTooltipClose}
                    >
                      <span>
                        <Tooltip
                          arrow
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleTooltipClose}
                          open={open}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Copied"
                          placement="top"
                        >
                          <span onClick={handleTooltipOpen}>Copy Job Link</span>
                        </Tooltip>
                      </span>
                    </CopyToClipboard>
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobCard;
