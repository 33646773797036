import React, { useState } from 'react'
import styled from 'styled-components';


const StyledP = styled.h4`
    font-size: 16px;
    font-weight: 400;
    margin-bottom : 16px ;
    line-height: normal;
    @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
  }
`

const StyledTableImg = styled.img`
height: 18px;
margin-right: 10px;
`

const SupplyChain = () => {

   return(
    <>
   <StyledP><StyledTableImg src={require('../../images/supply-chain-management.svg').default} alt="management" />Dynamics 365 Supply Chain Management</StyledP>
   <StyledP><StyledTableImg src={require('../../images/intelligent-order-management.svg').default} alt="intelligent" />Dynamics 365 Intelligent Order Management</StyledP>
   <StyledP><StyledTableImg src={require('../../images/supply-chain-insights.svg').default} alt="SupplyChain" />Microsoft Supply Chain Center Preview</StyledP>
   <StyledP><StyledTableImg src={require('../../images/guides.svg').default} alt="guides" />Dynamics 365 Guides</StyledP>
    </>
   )
}

export default SupplyChain   