import React from 'react'
import styled from 'styled-components';

const StyledTable = styled.table`
  width : 100% ;
`
const StyledUl = styled.ul`
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    padding-left : 15px !important;
  }
`
const StyledTablehead = styled.thead`
text-align: center;
background: aliceblue;
`
const StyledTableRow = styled.tr``
const StyledTableh = styled.th`
font-size : 14px ;
width:25%;
@media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    padding : 5px !important;
  }
`
const StyledTableData = styled.td`
font-size : 14px ;
`
const StyledTableBody = styled.tbody`
tr:nth-child(odd) {
    background-color: #fff; /* Apply background color to odd rows */
  }

  tr:nth-child(even) {
    background-color:#f2f2f2; /* Apply background color to even rows */
  }
`


const ForYouContent = () => {
  return (
    <>
        <section>
        <StyledTable className="table table-bordered " >
            <StyledTablehead>
                <StyledTableRow>
                    <StyledTableh>Microsoft 365 Personal</StyledTableh>
                    <StyledTableh>Microsoft 365 Family</StyledTableh>
                    <StyledTableh>Office Home & Student 2021</StyledTableh>
                </StyledTableRow>
            </StyledTablehead>
                <StyledTableBody>
                    <StyledTableRow>
                        <StyledTableData>
                            <StyledUl>
                                <li>For PC, Mac, iPhone, iPad and Android phones and tablets</li>
                                <li>1 TB of cloud storage</li>
                                <li>For one person</li>
                                </StyledUl>
                            </StyledTableData>
                        <StyledTableData>
                            <StyledUl>
                                <li>For PC, Mac, iPhone, iPad and Android phones and tablets</li>
                                <li>Up to 6 TB of cloud storage (1 TB per person)</li>
                                <li>For one to six people</li>
                            </StyledUl>
                        </StyledTableData>
                        <StyledTableData>
                            <StyledUl>
                                <li>For one PC or Mac</li>
                                <li>For one person</li>
                                </StyledUl>
                        </StyledTableData>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableData>
                            <StyledUl>
                                <li>For one person </li>
                                <li>Use on up to 5 devices simultaneously </li>
                                <li>Works on PC, Mac, iPhone, iPad, and Android phones and tablets </li>
                                <li>1 TB (1000 GB) of cloud storage </li>
                                <li>Word, Excel, PowerPoint, and OneNote with offline access and premium features </li>
                                <li>Microsoft Defender* advanced security for your personal data and devices </li>
                                <li>OneDrive ransomware protection for your files and photos </li>
                                <li>Outlook ad-free secure email </li>
                                <li>Microsoft Editor advanced spelling, grammar, and writing assistance </li>
                                <li>Clipchamp* video editor with premium filters and effects </li>
                                <li>Premium, customizable templates </li>
                                <li>Expanded content library of high-quality photos, icons, fonts, videos, and audio </li>
                                <li>Contact support via chat or email to get help when you need it</li>
                            </StyledUl>
                        </StyledTableData>
                        <StyledTableData>
                            <StyledUl>
                                <li>For one to six people to share </li>
                                <li>Each person can use on up to 5 devices simultaneously </li>
                                <li>Works on Windows, Mac, iPhone, iPad, and Android phones and tablets </li>
                                <li>Up to 6 TB of cloud storage (1 TB per person) </li>
                                <li>Word, Excel, PowerPoint, and OneNote with offline access and premium features </li>
                                <li>Microsoft Defender* advanced security for your personal data and devices</li>
                                <li>OneDrive ransomware protection for your files and photos </li>
                                <li>Outlook ad-free secure email </li>
                                <li>Microsoft Editor advanced spelling, grammar, and writing assistance </li>
                                <li>Clipchamp* video editor with premium filters & effects </li>
                                <li>Additional features in the Microsoft Family Safety mobile app </li>
                                <li>Premium, customizable templates </li>
                                <li>Expanded content library of high-quality photos, icons, fonts, videos, and audio </li>
                                <li>Contact support via chat or email to get help when you need it</li>
                            </StyledUl>
                        </StyledTableData>
                        <StyledTableData>
                                <StyledUl>
                                    <li>One-time purchase for 1 PC or Mac </li>
                                    <li>Classic 2021 versions of Word, Excel, and PowerPoint </li>
                                    <li>Microsoft support included for first 60 days at no extra cost </li>
                                    <li>Compatible with Windows 11, Windows 10, or macOS*</li>
                                    <li>For non-commercial use </li>
                                    <li>Works with Microsoft Teams</li>
                                </StyledUl>
                        </StyledTableData>
                    </StyledTableRow>
                   
                </StyledTableBody>
            </StyledTable>
        </section>
    </>
  )
}

export default ForYouContent;