import  { React , useEffect } from 'react'
import ContactPara from './components/ContactPara'
import ContactForm from './components/ContactForm'
import OurContactInfo from './components/OurContactInfo'
import GoogleMap from './components/GoogleMap'
import { MainBanner } from '../../Subpages/components/MainBanner';

import styled from 'styled-components'

const Container = styled.div`
margin-top : 28px ;
margin-bottom : 50px;
`
const Stylediv = styled.div``
const Coldiv7 = styled.div`
padding-left: 100px;

@media (max-width: 768px) {
  padding-left: 15px;
  margin-top: 50px;
}

@media (min-width: 769px) and (max-width: 991px) {
  padding-left: 15px;
  margin-top:70px;
}
`

const Contact = () => {
  useEffect(() => {
    document.getElementsByTagName("META")[3].content="We’d Love to Hear from You info@rarrtech.com +0129-4882321 119, 1st Floor Vardhmaan Plaza, Sector-19, Faridabad, Haryana-121002 13, IIML Noida Campus, B-1, Institutional Area, Block B, Industrial Area, Sector 62, Noida, Uttar Pradesh - 201307 Happy to answer you Send Us Your Query";
  }, []);

  return (

    <Stylediv>
       <MainBanner name="Contact Us" pageName="Contact Us"/>
    <Container className='container'>
        <div className='row'>
            <div className='col-lg-5'>
                <ContactPara/>
                <ContactForm/>
            </div>
            <Coldiv7 className='col-lg-7'>
              <OurContactInfo/>
              <GoogleMap/>
            </Coldiv7>
        </div>
    </Container>
    </Stylediv>
  )
}

export default Contact