import React from 'react'
import styled from 'styled-components';
import { MainHeading } from '../../Subpages/components/MainHeading';

// images_import
import SaasDevelopmentImg from '../../../images/SaasDevelopment.png';
import FrontEndImg from '../../../images/Front-end.png';
import BackEndImg from '../../../images/Back-end.png';
import ProgressiveImg from '../../../images/Progressive.png';
import DigitalImg from '../../../images/Digital.png';
import MobileResImg from '../../../images/Mobile-Res.png';
import AiBasedImg from '../../../images/AI-Based.png';
import ReliableImg from '../../../images/Reliable.png';
import CuttingEdgeImg from '../../../images/CuttingEdge.png';
import AgilePracticesImg from '../../../images/AgilePractices.png';
import BestCustomerImg from "../../../images/BestCustomer.png";
import WebProcessImg from "../../../images/webProcess.png";

// css_components
const Container = styled.div``
const Stylepara = styled.p`
font-size:16px;
font-weight:400;
line-height:normal;
margin-bottom:40px;
`
const Row = styled.div`
margin-top:27px;
margin-bottom:27px;
`
const COl = styled.div``
const Img = styled.img`
margin-bottom : 11px ;
`
const Heading = styled.h2`
font-size : 18px ;
color : black ;
font-weight : 700 ;
font-family : 'Mulish'
`
const Styleimg = styled.img`
width:100%;
`

const ContentDevelopment = () => {
    return (
        <Container>
            <Stylepara>Our web application development team creates web applications designed to meet specific business requirements. Leveraging our hands-on experience in the latest technologies like Postgre SQL, MongoDB, Angular, Node.js, React and Python, we simplify complex business workflows with adaptive web apps.</Stylepara>
            <MainHeading name='Our Web Application Development Services' />

            <Row className='row'>
                <COl className='col-lg-6'>
                    <Img src={SaasDevelopmentImg}  alt= "SaasDevelopment" style={{paddingTop:'17px'}}></Img>
                    <Heading>SaaS Application Development</Heading>
                    <Stylepara>Our SaaS developers build secure and multi-tenant SaaS web solutions that allow you to expand the scope of your web app as the business grows.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={FrontEndImg} alt= "FrontEnd"></Img>
                    <Heading>Front-end Development</Heading>
                    <Stylepara>Our developers can build single-page web apps with the reusable and component-driven architecture of React.js, Angular.js, Vue.js.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={BackEndImg} alt= "BackEnd"></Img>
                    <Heading>Back-end Development</Heading>
                    <Stylepara>Our backend web developers use microservices architecture to achieve scalability, services like AWS and Azure to host the backend and SQL and NoSQL databases to store and fetch data reliably.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={ProgressiveImg} alt= "Progressive"></Img>
                    <Heading>Progressive Web Apps</Heading>
                    <Stylepara>We develop mobile-first and cross-platform web apps with responsive UX and modern web APIs to decrease bounce rates and increase customer engagement.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={DigitalImg} alt= "Digital" style={{paddingTop:'16px'}}></Img>
                    <Heading>Digital Transformation</Heading>
                    <Stylepara>We adapt to the changing business environments with seamless cross-platform migration of existing applications to new technologies, ensuring zero data leakages.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={ProgressiveImg} alt= "Software"></Img>
                    <Heading>Custom Software Development </Heading>
                    <Stylepara>We are specialized in building custom web apps that ensure compatibility, scalability and usability, ensuring consistent user experiences across different devices and browsers.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={MobileResImg} alt= "MobileRes"></Img>
                    <Heading>Mobile Responsive Web Apps</Heading>
                    <Stylepara>We utilize the latest mobile technologies to develop customer-centric mobile-responsive apps that are robust and scalable. Our aim is to provide an engaging user experience to clients and their customers.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={AiBasedImg} alt= "AiBased"></Img>
                    <Heading>AI-Based Web Apps</Heading>
                    <Stylepara>We develop AI-based web apps to build recommendation engines, implement image recognition and enable intelligent automation.</Stylepara>
                </COl>
            </Row>

            <MainHeading name='Our Web Application Development Services' />
            <Row className='row'>
                <COl className='col-lg-6'>
                    <Img src={ReliableImg}  alt= "Reliable" ></Img>
                    <Heading>Reliable Software</Heading>
                    <Stylepara>Our web application development services involve rigorous testing and adherence to global standards for building smooth, efficient, and reliable custom applications.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={CuttingEdgeImg}  alt= "CuttingEdge"></Img>
                    <Heading>Cutting Edge Technology</Heading>
                    <Stylepara>With the latest technology stack and experienced web developers, we help our clients achieve faster time-to-market and adapt to the ever-evolving business environment.</Stylepara>
                </COl>
                
                <COl className='col-lg-6'>
                    <Img src={AgilePracticesImg}  alt= "AgilePractices"></Img>
                    <Heading>Agile Practices</Heading>
                    <Stylepara>Our multi-faceted and cross-functional teams deliver risk-free and sustainable solutions by implementing agile web-based application development practices.</Stylepara>
                </COl>
                <COl className='col-lg-6'>
                    <Img src={BestCustomerImg}  alt= "BestCustomer"></Img>
                    <Heading>Best Customer Experience</Heading>
                    <Stylepara>We enhance customer engagements to consolidate brand image with applications that are sleek, progressive and support advanced features such as chat, location sharing and more.</Stylepara>
                </COl>
            </Row>

            <MainHeading name='Web Application Development Process' />
            <Row>
                <COl className='col-lg-12'>
                <Styleimg src={WebProcessImg}  alt= "WebProcess"></Styleimg>
                </COl>
            </Row>
            

        </Container>
    )
}

export default ContentDevelopment